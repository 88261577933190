import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import StatsHeader from 'components/argon/Headers/StatsHeader'
import OrdersTable from 'components/buyer/OrdersTable'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import moment from 'moment'

import DetailsContext from 'config/context/DetailsContext'
import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Dashboard() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new buyerClient()

    const details = useContext(DetailsContext)
    const company = useContext(CompanyContext)
    //console.log('company: ', company)

    const [stats, setStats] = useState({})
    const [orders, setOrders] = useState([])
    const [showOrders, setShowOrders] = useState([])
    const [loading, setLoading] = useState(true)

    // empty array argument prevents endless loop
    useEffect(() => {
        getOrders()
    }, [])

    async function getOrders() {
        try {
            const result = await api.getAllOrders(company.id, details.buyerId)
            const team = await api.getTeamMembers(details.buyerId)

            let monthStart = moment().startOf('month').valueOf()
            let noOrders = 0
            let valueOrders = 0

            if (result) {
                result.sort((a, b) => {
                    return b.createdAt - a.createdAt
                })
            }

            for (var order of result) {
                if ((order.status === 'approved') && (order.createdAt >= monthStart)) {
                    noOrders += 1
                    valueOrders += order.total
                }
            }

            console.log('no orders: ', noOrders)
            console.log('value orders: ', valueOrders)

            let stats = {
                noOrders: noOrders.toString(),
                valueOrders: valueOrders.toFixed(2),
                noTeam: team.length.toString()
            }
            
            setStats(stats)
            setOrders(result)
            setShowOrders(result)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function filterOrders(filter) {
        if (filter === 'all') {
            setShowOrders(orders)
        }
        else {
            let show = []
            for (var order of orders) {
                if (order.status === filter) {
                    show.push(order)
                }
            }
            setShowOrders(show)
        }
    }
    
    return (
        <>
            {!loading ? (
                <>
                    <StatsHeader 
                        section='Default' 
                        subsection='Dashboards'
                        buyer
                        stats={stats}
                    />
                    <Container
                        maxWidth={false}
                        component={Box}
                        marginTop='-6rem'
                        classes={{ root: classes.containerRoot }}
                    >
                        <Grid container>
                            
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} xl={12}>
                                <OrdersTable 
                                    orders={showOrders}
                                    refreshOrders={() => getOrders()}
                                    filterOrders={(filter) => filterOrders(filter)}
                                    loading={loading}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </>
            ) : (
                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                    <Loader />
                </Box>
            )}
        </>
    );
}

export default Dashboard