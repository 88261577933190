import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import GuestDetailsModal from 'components/guest/GuestDetailsModal'
import buyerClient from 'config/api/BuyerClient'
import guestClient from 'config/api/GuestClient'
import { onError } from 'config/lib/errorLib'
import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function CartModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = props.type === 'guest' ? new guestClient() : new buyerClient()

	const company = useContext(CompanyContext)
	//console.log('company: ', company)

	const cart = props.cart
	
	const [subtotal, setSubtotal] = useState(0)
	const [vat, setVat] = useState(0)
	const [total, setTotal] = useState(0)

	const [stage, setStage] = useState('order')
	const [payment, setPayment] = useState(null)

	const [showGuestDetailsModal, setShowGuestDetailsModal] = useState(false)
	const [details, setDetails] = useState(props.details ? props.details : null)

	useEffect(() => {
        getTotals()
    }, [])

	function removeFromCart(item) {
		console.log('remove from cart: ', item)
		props.removeFromCart(item)
	}

	function getTotals() {
		console.log(cart)
		let subtotal = 0
		let vat = 0
		let total = 0

		for (var item of cart) {
			subtotal += item.subtotal
			vat += item.vat
			total += item.total
		}
		
		setSubtotal(subtotal)
		setVat(vat)
		setTotal(total)
	}

	function clickPlaceOrder() {
		if (props.type === 'guest') {
			setShowGuestDetailsModal(true)
		}
		else {
			placeOrder(details)
		}
	}

	async function placeOrder(details) {
		console.log('details: ', details)
        try {   
            const result = await api.addOrder(company.id, details, cart)
            console.log('new order: ', result)
			
			setDetails(details)
			if (props.type === 'guest') {
				props.updateDetails(details)
			}
            setPayment(result.payment)
            setStage('payment')

            // empty cart
            // show order placed/complete payment with options

            //setAlert(null)
            //setShowCartModal(false)
            //openSuccessAlert('Order placed!')
            props.setCart([])
        } catch(e) {
            onError(e)
        }
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Your Cart
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			<DialogContent>
				{stage === 'order' &&
					<Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                >
	                	{cart.map((item, i) => {
	                		return (
			                	<Grid item key={i}>
			                		<Grid 
					                    container
					                    spacing={1}
					                    direction='row'
					                    alignItems='center'
					                    justify='space-between'
					                >
					                	<Grid item xs={8}>
				                			<Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='600'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                    >
						                    	{item.variant ? (
						                    		<>{item.product.name} ({item.variant.name})</>
						                    	) : (
						                    		<>{item.product.name}</>
						                    	)}
						                    </Box>
						                    <Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='300'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                    >
						                        {item.campaignName}
						                    </Box>
						                    <Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='300'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                    >
						                        {item.startDate.format('DD/MM/YY')} - {item.endDate.format('DD/MM/YY')}
						                    </Box>
			                			</Grid>

				                		<Grid item xs={4}>
				                			<Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='600'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                        textAlign='right'
						                    >
						                        <>€{item.subtotal.toFixed(2)}</>
						                    </Box>
						                    <Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='300'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                        textAlign='right'
						                        color='#f5365c'
						                        className='hover-text'
						                        onClick={() => removeFromCart(item)}
						                    >
						                        Remove
						                    </Box>
				                		</Grid>
				                	</Grid>
			                	</Grid>
			                )
	                	})}

	                	<Grid item>
	                		<Grid 
			                    container
			                    spacing={1}
			                    direction='row'
			                    alignItems='center'
			                    justify='space-between'
			                >
			                	<Grid item xs={8}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Subtotal
				                    </Box>
	                			</Grid>

		                		<Grid item xs={4}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                        textAlign='right'
				                    >
				                        €{subtotal.toFixed(2)}
				                    </Box>
		                		</Grid>
		                	</Grid>
	                	</Grid>
	                	<Grid item>
	                		<Grid 
			                    container
			                    spacing={1}
			                    direction='row'
			                    alignItems='center'
			                    justify='space-between'
			                >
			                	<Grid item xs={8}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        VAT (23%)
				                    </Box>
	                			</Grid>

		                		<Grid item xs={4}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                        textAlign='right'
				                    >
				                        €{vat.toFixed(2)}
				                    </Box>
		                		</Grid>
		                	</Grid>
	                	</Grid>
	                	<Grid item>
	                		<Grid 
			                    container
			                    spacing={1}
			                    direction='row'
			                    alignItems='center'
			                    justify='space-between'
			                >
			                	<Grid item xs={8}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Total
				                    </Box>
	                			</Grid>

		                		<Grid item xs={4}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                        textAlign='right'
				                    >
				                        €{total.toFixed(2)}
				                    </Box>
		                		</Grid>
		                	</Grid>
	                	</Grid>
	                </Grid>
            	}
            	{stage === 'payment' &&
            		<Grid 
	                    container
	                    spacing={1}
	                    direction='column'
						className='step--cart--success'
	                >
	                	<Grid item>
	                		<Typography
                                component='h1'
                                variant='h1'
                                className={classes.typographyH2}
                                style={{textAlign: 'center', marginBottom: '1rem'}}
                            >
                                Order Placed!
                            </Typography>
	                	</Grid>
	                	<Grid item>
	                		<Typography
                                component='h2'
                                variant='h2'
                                className={classes.typographyH2}
                                style={{textAlign: 'center', marginBottom: '1.5rem'}}
                            >
                                Total: €{payment.total.toFixed(2)}
                            </Typography>
	                	</Grid>
						{payment.total < 5000 &&
							<Button
								component={Box}
								onClick={() => props.showStripe(payment, details)}
								color='primary'
								variant='contained'
								//marginLeft='auto!important'
								style={{marginBottom: '0.5em'}}
								className='step--cart--pay-card'
							>
								Pay with card (Stripe)
							</Button>
						}
						<Button
							component={Box}
							onClick={() => props.showEft(payment.paymentId)}
							color='primary'
							variant='contained'
							//marginLeft='auto!important'
							style={{marginBottom: '0.5em'}}
							className='step--cart--pay-eft'
						>
							Pay by EFT (Offline)
						</Button>
	                </Grid>
            	}
			</DialogContent>
		
			<DialogActions>
				{stage === 'order' &&
					<>
						<Button
							onClick={() => props.close()}
							color='primary'
							className='step--cart--back'
						>
							Continue Advertising
						</Button>
						<Button
							component={Box}
							onClick={() => clickPlaceOrder()}
							color='primary'
							variant='contained'
							marginLeft='auto!important'
							style={{marginBottom: '0.5em'}}
							className='step--cart--order'
						>
							Place order now
						</Button>
					</>
				}
			</DialogActions>

			{showGuestDetailsModal &&
                <GuestDetailsModal 
                    isOpen={showGuestDetailsModal}
                    close={() => setShowGuestDetailsModal(false)}
                    placeOrder={(details) => placeOrder(details)}
                />
            }
		</Dialog>
	)
}