import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Radio from '@material-ui/icons/Radio'
import Tv from '@material-ui/icons/Tv'
import Outdoor from '@material-ui/icons/DirectionsBus'
import Press from '@material-ui/icons/ImportContacts'
import Digital from '@material-ui/icons/Web'
import Sponsorship from '@material-ui/icons/AttachMoney'
//import Typography from '@material-ui/core/Typography'
import Logo from 'components/general/Logo'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function CategoryCard(props) {
    const classes = useStyles()
    const category = props.category
    
    return (
        <>
            <Card className={classes.cardRoot + ' ' + props.className} onClick={() => props.onClick()}>
                <CardContent className='category-card'>
                    {props.logo &&
                        <Box style={{marginBottom: '1rem'}}>
                            <Logo
                                logo={props.logo}
                                name={props.category}
                                marginRight={'0'}
                                size
                            />
                        </Box>
                    }
                    {props.icon &&
                        <Box style={{marginBottom: '1rem'}}>
                            {category === 'Radio' &&
                                <Radio className='category-icon' />
                            }
                            {category === 'Television' &&
                                <Tv className='category-icon' />
                            }
                            {category === 'Outdoor' &&
                                <Outdoor className='category-icon' />
                            }
                            {category === 'Press' &&
                                <Press className='category-icon' />
                            }
                            {category === 'Digital' &&
                                <Digital className='category-icon' />
                            }
                            {category === 'Sponsorship' &&
                                <Sponsorship className='category-icon' />
                            }
                        </Box>
                    }
                    <p>{category}</p>
                </CardContent>
            </Card>
        </>
    );
}

export default CategoryCard