import { Component } from 'react'
import { API, Auth } from 'aws-amplify'
import config from './Config'
//import { onError } from 'config/lib/errorLib'

const apiPath = config.apiGatewayBuyer.PATH

class BuyerClient extends Component {
	addUserDetails(name, email, phone, buyerName, buyerId, permissions, confirmed) {
		const data = {
			name: name,
			email: email,
			phone: phone,
			buyerName: buyerName,
			buyerId: buyerId,
			permissions: permissions,
			confirmed: confirmed
		}
		console.log(data)

		return API.post(apiPath, 'users/add', {
			body: data
		})
	}

	// once admatic accept the user, the business and root user are confirmed
	addBusinessDetails(buyerId, buyerName, rootUserId, rootUserName, email, phone) {
		const data = {
			buyerId: buyerId,
			buyerName: buyerName,
			rootUserId: rootUserId,
			rootUserName: rootUserName,
			email: email,
			phone: phone
		}
		console.log(data)

		return API.post(apiPath, 'buyers/add', {
			body: data
		})
	}

	updateBuyerDetails(buyerId, address, email, phone) {
		const data = {
			buyerId: buyerId,
			address: address,
			email: email,
			phone: phone
		}
		console.log(data)

		return API.post(apiPath, 'buyers/update', {
			body: data
		})
	}

	getUserDetails(buyerId) {
		const data = {
			buyerId: buyerId
		}
		console.log(data)

		return API.post(apiPath, 'users/get', {
			body: data
		})
	}

	getBuyerDetails(buyerId) {
		const data = {
			buyerId: buyerId
		}
		console.log(data)

		return API.post(apiPath, 'buyers/get', {
			body: data
		})
	}

	getAllPublishers(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/list', {
			body: data
		})
	}

	getAllBbPublishers() {
		return API.post(apiPath, 'publishers/list-bb', {})
	}

	getAllProducts(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/all-products', {
			body: data
		})
	}

	getAllBbProducts() {
		return API.post(apiPath, 'publishers/all-products-bb', {})
	}

	addOrder(companyId, details, cart) {
		// split cart into separate orders in api
		const data = {
			companyId,
			details,
			cart
		}
		console.log(data)

		return API.post(apiPath, 'orders/add', {
			body: data
		})
	}

	getOrder(buyerId, orderId) {
		const data = {
			buyerId: buyerId,
			orderId: orderId
		}
		console.log(data)

		return API.post(apiPath, 'orders/get', {
			body: data
		})
	}

	getAllOrders(companyId, buyerId) {
		const data = {
			companyId,
			buyerId
		}
		console.log(data)

		return API.post(apiPath, 'orders/list-buyer', {
			body: data
		})
	}

	dismissRejectedOrder(buyerId, orderId) {
		const data = {
			buyerId: buyerId,
			orderId: orderId
		}
		console.log(data)

		return API.post(apiPath, 'orders/dismiss-rejected', {
			body: data
		})
	}

	getCategoryContent() {
		return API.post(apiPath, 'config/media-catalog-content', {})
	}

	getPageIntroContent() {
		return API.post(apiPath, 'config/page-intro-content', {})
	}

	getPlanningArticles() {
		return API.post(apiPath, 'config/planning-articles', {})
	}

	getTeamMembers(buyerId) {
		const data = {
			buyerId: buyerId
		}
		console.log(data)

		return API.post(apiPath, 'users/list', {
			body: data
		})
	}

	addTeamMember(buyerId, buyerName, name, email, phone, permissions, password) {
		// Create cognito user
		const cognitoData = {
        	'username': email.toLowerCase(), 
			'password': password,
			'attributes': {
				'name': name,
				'phone_number': phone,
				'custom:account_type': 'buyer',
				'custom:businessName': buyerName,
				'custom:businessId': buyerId,
				'custom:permissions': permissions
			}
        }

        const data = {
        	email: email,
        	password: password
        }

        API.post(apiPath, 'users/new-team-member', {
			body: data
		})

        return Auth.signUp(cognitoData)

		// Add details to dynamo
		// const data = {
		// 	buyerId: buyerId,
		// 	buyerName: buyerName,
		// 	name: name,
		// 	email: email,
		// 	phone: phone,
		// 	permissions: permissions
		// }
		// console.log(data)

		// return API.post(apiPath, 'users/add-team-member', {
		// 	body: data
		// })
	}

	deactivateUser(buyerId, userId) {
		const data = {
			buyerId: buyerId,
			userId: userId
		}
		console.log(data)

		return API.post(apiPath, 'users/deactivate', {
			body: data
		})
	}

	activateUser(buyerId, userId) {
		const data = {
			buyerId: buyerId,
			userId: userId
		}
		console.log(data)

		return API.post(apiPath, 'users/activate', {
			body: data
		})
	}

	getPayment(paymentId) {
		const data = {
			paymentId: paymentId
		}
		console.log(data)

		return API.post(apiPath, 'fire/get-payment', {
			body: data
		})
	}

	// getPaymentDetails(paymentUuid) {
	// 	const data = {
	// 		paymentUuid: paymentUuid
	// 	}
	// 	console.log(data)

	// 	return API.post(apiPath, 'fire/get-fire-payment', {
	// 		body: data
	// 	})
	// }

	// updatePaymentUuid(paymentId, paymentUuid) {
	// 	const data = {
	// 		paymentId: paymentId,
	// 		paymentUuid: paymentUuid
	// 	}
	// 	console.log(data)

	// 	return API.post(apiPath, 'fire/update-payment-uuid', {
	// 		body: data
	// 	})
	// }

	submitEnquiry(companyId, type, enquiry) {
		const data = {
			companyId,
			type,
			enquiry
		}
		console.log(data)

		return API.post(apiPath, 'enquiries/submit', {
			body: data
		})
	}

	submitProductEnquiry(companyId, campaignName, message, details, publisher, product, variant) {
		const data = {
			companyId: companyId,
			campaignName: campaignName,
			message: message,
			details: details,
			publisher: publisher,
			product: product,
			variant: variant
		}
		console.log(data)

		return API.post(apiPath, 'enquiries/submit-product', {
			body: data
		})
	}

	createPaymentRequest(paymentId, total) {
		const data = {
			paymentId: paymentId,
			total: total
		}
		console.log(data)

		return API.post(apiPath, 'fire/create-payment-request', {
			body: data
		})
	}

	getPaymentRequestPayments(paymentRequestCode) {
		const data = {
			paymentRequestCode: paymentRequestCode
		}
		console.log(data)

		return API.post(apiPath, 'fire/get-payment-request-payments', {
			body: data
		})
	}

	getPaymentIntent(total, paymentId, buyerId) {
		const data = {
			total: total,
			paymentId: paymentId,
			buyerId: buyerId
		}
		console.log(data)

		return API.post(apiPath, 'stripe/create-payment-intent', {
			body: data
		})
	}

	getPaymentMethods(stripeCustomerId) {
		const data = {
			stripeCustomerId: stripeCustomerId
		}
		console.log(data)

		return API.post(apiPath, 'stripe/get-payment-methods', {
			body: data
		})
	}

	sendUnpaidInvoice(email, paymentId, companyId) {
		const data = {
			email: email,
			paymentId: paymentId,
			companyId: companyId
		}
		console.log(data)

		return API.post(apiPath, 'orders/send-unpaid-invoice', {
			body: data
		})
	}

	addBuyerCopy(buyerId, copyId, name, category) {
		const data = {
			buyerId: buyerId,
			copyId: copyId,
			name: name,
			category: category
		}
		console.log(data)

		return API.post(apiPath, 'config/add-copy', {
			body: data
		})
	}

	getBuyerCopy(buyerId) {
		const data = {
			buyerId: buyerId
		}
		console.log(data)

		return API.post(apiPath, 'config/all-copy', {
			body: data
		})
	}
} 

export default BuyerClient