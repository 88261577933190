import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
//import Add from '@material-ui/icons/Add'
//import Button from '@material-ui/core/Button'
import Loader from 'components/general/Loader'
//import buyerClient from 'config/api/BuyerClient'
//import { onError } from 'config/lib/errorLib'
//import { NotificationManager } from 'react-notifications'
import ContentCard from 'components/buyer/ContentCard'
//import UploadCopyModal from 'components/buyer/UploadCopyModal'
import BookAndBuyForm from 'components/buyer/BookAndBuyForm'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function CopyUpload() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    //const api = new buyerClient()

    const details = useContext(DetailsContext)
    console.log(details)

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >   
                <Grid container>
                    <Grid item xs={12} xl={12}>
                        <ContentCard 
                            title={'Book & Buy'}
                            body={'This is an area you can book small ads in paper publications. Select your publication, design your ad, and pay, all in the same place.'}
                        />
                    </Grid>

                    <Grid item xs={12} xl={12}>
                        <BookAndBuyForm
                            details={details}
                        />
                    </Grid>
                </Grid>
                
                {!loading ? (
                    <Grid container spacing={1}>
                        
                    </Grid>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader />
                    </Box>
                )}
            </Container>
        </>
    );
}

export default CopyUpload