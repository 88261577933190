import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import { Storage } from 'aws-amplify'

function Logo(props) {

    const [logo, setLogo] = useState(null)

    useEffect(() => {
        getLogo()
    }, [])

    async function getLogo() {
        if (props.logo) {
            let image = await Storage.get(props.logo)
            //console.log(image)
            setLogo(image)
        }
    }

    if (props.logo) {
    	return (
    		<Box
                marginRight={props.marginRight ? props.marginRight : '1rem'}
            >
                <Avatar 
                    src={logo}
                    alt={props.name}
                    style={props.size ? { height: '70px', width: '70px' } : {}}
                >
                    {props.name.slice(0,1)}
                </Avatar>
            </Box>
        )
    }
    else {
        return (
            <Box
                marginRight={props.marginRight ? props.marginRight : '1rem'}
            >
                <Avatar 
                    src={logo}
                    alt={props.name}
                    style={props.size ? { height: '70px', width: '70px' } : {}}
                >
                    {props.name.slice(0,1)}
                </Avatar>
            </Box>
        )
    }
}

export default Logo