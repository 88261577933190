import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import MoreVert from '@material-ui/icons/MoreVert'
import Add from '@material-ui/icons/Add'
// core components
import componentStyles from 'assets/theme/components/cards/alternative/card-page-visits-alternative.js'
import AddPublisherModal from 'components/admatic/AddPublisherModal'
import PublisherModal from 'components/admatic/PublisherModal'
import BbProductsModal from 'components/admatic/BbProductsModal'
import WarningModal from 'components/general/WarningModal'
import Logo from 'components/general/Logo'
import Loader from 'components/general/Loader'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'
import { useHistory } from 'react-router-dom'
import { Storage } from 'aws-amplify'

import CompanyContext from 'config/context/CompanyContext'

const useStyles = makeStyles(componentStyles)

function PublishersTable(props) {
    const classes = useStyles()
    const api = new admaticClient()
    const history = useHistory()

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    //const [showWarningModal, setShowWarningModal] = useState(false)
    const [showAddPublisherModal, setShowAddPublisherModal] = useState(false)
    const [showPublisherModal, setShowPublisherModal] = useState(false)
    const [showBbProductsModal, setShowBbProductsModal] = useState(false)
    const [publisher, setPublisher] = useState(null)
    const [alert, setAlert] = useState(false)

    function closeSuccessAlert() {
        setAlert(false)
    }
    
    function openPublisherModal(publisher) {
        setPublisher(publisher)
        setShowPublisherModal(true)
    }

    function closePublisherModal() {
        setShowPublisherModal(false)
        setPublisher(null)
    }

    function closeAddPublisherModal() {
        setShowAddPublisherModal(false)
    }

    function openBbProductsModal(publisher) {
        setPublisher(publisher)
        setShowBbProductsModal(true)
    }

    function closeBbProductsModal() {
        setShowBbProductsModal(false)
        setPublisher(null)
    }

    function openPublisherProducts(publisher) {
        if (props.type === 'bb') {
            openBbProductsModal(publisher)
        }
        else {
            history.push({
                pathname: '/publisher/' + publisher.publisherId,
                state: {publisher: publisher}
            })
        }
    }

    async function addPublisher(name, email, phone, description, logo, copy) {
        try {   
            let newPublisher = null
            if (props.type === 'bb') {
                newPublisher = await api.addBbPublisher(name, email, phone, description)
            }
            else {
                newPublisher = await api.addPublisher(company.id, name, email, phone, description, copy)
            }
            console.log('publisher: ', newPublisher)

            if (logo) {
                let path = 'publishers/' + newPublisher.publisherId
                try {
                    await Storage.put(path, logo, {
                        contentType: logo.type
                    })
                } catch(e) {
                    onError(e)
                }
            }

            closeAddPublisherModal()
            openSuccessAlert('Publisher created!')
            props.refreshPublishers()
        } catch(e) {
            onError(e)
        }
    }

    async function updatePublisher(publisherId, name, email, phone, description, logo, copy) {
        let path = null
        if (logo) {
            path = 'publishers/' + publisherId
        }
        try {   
            const newPublisher = await api.updatePublisher(company.id, publisherId, name, email, phone, description, copy, path)
            console.log('publisher: ', newPublisher)

            if (logo) {
                console.log('uploading logo: ', logo)
                try {
                    await Storage.put(path, logo, {
                        contentType: logo.type
                    })
                } catch(e) {
                    onError(e)
                }
            }

            closePublisherModal()
            openSuccessAlert('Publisher updated!')
            props.refreshPublishers()
        } catch(e) {
            onError(e)
        }
    }

    async function activatePublisher(publisher) {
        try {   
            const newPublisher = await api.activatePublisher(company.id, publisher.publisherId)
            console.log('publisher: ', newPublisher)

            openSuccessAlert('This publisher has been activated and buyers can now access their products.')
            props.refreshPublishers()
        } catch(e) {
            onError(e)
        }
    }

    async function deactivatePublisher(publisher) {
        try {   
            const newPublisher = await api.deactivatePublisher(company.id, publisher.publisherId)
            console.log('publisher: ', newPublisher)

            openSuccessAlert('This publisher has been deactivated and buyers can no longer access their products.')
            props.refreshPublishers()
        } catch(e) {
            onError(e)
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => closeSuccessAlert()}
                onCancel={() => closeSuccessAlert()}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    const tableHead = ['Name', 'Email', 'Phone', 'Status', ''];
    const publishers = props.publishers
    
    return (
    <>
        {alert}
        <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h2'
                                marginBottom='0!important'
                            >
                                <Box component='span'>{props.type === 'bb' ? 'Book & Buy Publishers' : 'Publishers'}</Box>
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box justifyContent='flex-end' display='flex' flexWrap='wrap'>
                                <Button variant='contained' size='small' color='primary' onClick={() => setShowAddPublisherModal(true)}>
                                    <Box component={Add} position='relative' top='-2px' />{' '}
                                    Add Publisher
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            >
            </CardHeader>
            
            <TableContainer>
                {!props.loading ? (
                    <Box component={Table} alignItems='center' marginBottom='0!important'>
                        {publishers.length === 0 &&
                            <caption>No publishers</caption>
                        }
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        classes={{
                                            root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {publishers.map((publisher, key) => (
                                <TableRow key={key}>
                                    <TableCell
                                        classes={{
                                            root:
                                            classes.tableCellRoot +
                                            ' ' +
                                            classes.tableCellRootBodyHead,
                                        }}
                                        component='th'
                                        variant='head'
                                        scope='row'
                                    >
                                        <Box alignItems='center' display='flex'>
                                            <Logo 
                                                logo={publisher.logo}
                                                name={publisher.name}
                                            />
                                            <Box display='flex' alignItems='flex-start'>
                                                <Box fontSize='.875rem' component='span'>
                                                    {publisher.name}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </TableCell>
                
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {publisher.email}
                                    </TableCell>

                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {publisher.phone}
                                    </TableCell>
                                    
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box paddingTop='.35rem' paddingBottom='.35rem'>
                                            <Box
                                                marginRight='10px'
                                                component='i'
                                                width='.375rem'
                                                height='.375rem'
                                                borderRadius='50%'
                                                display='inline-block'
                                                className={
                                                    classes.verticalAlignMiddle +
                                                    ' ' +
                                                    classes[publisher.confirmed ? 'bgSuccess' : 'bgWarning']
                                                }
                                            ></Box>
                                            {publisher.confirmed ? 'active' : 'inactive'}
                                        </Box>
                                    </TableCell>
                                    
                                    <TableCell
                                        classes={{ root: classes.tableCellRoot }}
                                        align='right'
                                    >
                                        <DropdownComponent 
                                            id={key} 
                                            openPublisher={() => openPublisherModal(publisher)}
                                            openPublisherProducts={() => openPublisherProducts(publisher)}
                                            activatePublisher={() => activatePublisher(publisher)}
                                            deactivatePublisher={() => deactivatePublisher(publisher)}
                                            confirmed={publisher.confirmed}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                        <Loader />
                    </Box>
                )}
            </TableContainer>

            {showAddPublisherModal && 
                <AddPublisherModal 
                    isOpen={showAddPublisherModal}
                    close={() => closeAddPublisherModal()}
                    addPublisher={(name, email, phone, description, logo, copy) => addPublisher(name, email, phone, description, logo, copy)}
                    type={'bb'}
                />
            }

            {showPublisherModal &&
                <PublisherModal
                    isOpen={showPublisherModal}
                    close={() => closePublisherModal()}
                    publisher={publisher}
                    updatePublisher={(publisherId, name, email, phone, description, logo, copy) => updatePublisher(publisherId, name, email, phone, description, logo, copy)}
                    type={'bb'}
                />
            }

            {showBbProductsModal &&
                <BbProductsModal
                    isOpen={showBbProductsModal}
                    close={() => closeBbProductsModal()}
                    publisher={publisher}
                />
            }
        </Card>
    </>
    )
}

const DropdownComponent = ({ id, openPublisher, openPublisherProducts, openBbProductsProducts, activatePublisher, deactivatePublisher, confirmed }) => {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState(null)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [warningText, setWarningText] = useState(null)
    const [warningAction, setWarningAction] = useState(null)
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    
    const handleClose = () => {
        setAnchorEl(null)
    }

    function openWarningModal(action, text) {
        setAnchorEl(null)
        setWarningAction(action)
        setWarningText(text)
        setShowWarningModal(true)
    }

    function openPublisherModal() {
        setAnchorEl(null)
        openPublisher()
    }

    function openPublisherProductPage() {
        setAnchorEl(null)
        openPublisherProducts()
        console.log('nav to products page')
    }

    function action() {
        setShowWarningModal(false)
        if (warningAction === 'activate') {
            activatePublisher()
        }
        if (warningAction === 'deactivate') {
            deactivatePublisher()
        }
    }
    
    return (
        <>
            <Box
                aria-controls={'simple-menu-' + id}
                aria-haspopup='true'
                onClick={handleClick}
                size='small'
                component={Button}
                width='2rem!important'
                height='2rem!important'
                minWidth='2rem!important'
                minHeight='2rem!important'
            >
                <Box
                    component={MoreVert}
                    width='1.25rem!important'
                    height='1.25rem!important'
                    position='relative'
                    top='2px'
                    color={theme.palette.gray[500]}
                />
            </Box>
            <Menu
                id={'simple-menu-' + id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => openPublisherModal()}>View Details</MenuItem>
                <MenuItem onClick={() => openPublisherProductPage()}>Manage Products</MenuItem>
                {confirmed ? (
                    <MenuItem onClick={() => openWarningModal('deactivate', 'Once you deactivate this account the publishers products will no longer be available for purchase.')}>Deactivate Account</MenuItem>
                ) : (
                    <MenuItem onClick={() => openWarningModal('activate', 'Once you activate this account the publishers products will become available for purchase.')}>Activate Account</MenuItem>
                )}
            </Menu>

            <WarningModal 
                isOpen={showWarningModal}
                close={() => setShowWarningModal(false)}
                primaryText={warningText}
                onContinue={() => action()}
            />
        </>
    )
}

export default PublishersTable