import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import guestClient from 'config/api/GuestClient'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

import CompanyContext from 'config/context/CompanyContext'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function RequestCallbackModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
    const api = new guestClient()

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    const [name, setName] = useState('')
  	const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')

    async function submitEnquiry() {
        const enquiry = {
            name: name,
            email: email,
            phone: phone,
            message: message
        }

        try {
            await api.submitRequestCallback(company.id, enquiry)
            props.openSuccessAlert('Your enquiry has been submitted. A member of our team will contact you shortly.')
            props.close()
        } catch(e) {
            onError(e)
        }
    }

  	function checkFields() {
  		if (name.length > 0 && email.length > 0 && phone.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
			maxWidth='sm'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Speak With An Expert
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
                <FormGroup>
                  	<FormLabel>Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='Full Name/Business Name'
                    	value={name}
                    	onChange={(e) => setName(e.target.value)}
                  	/>
                </FormGroup>
				<FormGroup>
                  	<FormLabel>Email</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='email'
                    	placeholder='Email'
                    	value={email}
                    	onChange={(e) => setEmail(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Phone</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='Phone'
                    	value={phone}
                    	onChange={(e) => setPhone(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                <FormLabel>Message</FormLabel>
                <OutlinedInput
                    fullWidth
                    multiline
                    rows='5'
                    type='text'
                    placeholder='I would like to speak with someone about...'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => submitEnquiry()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	)
}