import React, { useEffect, useRef, useContext } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/auth.js'

//import AuthNavbar from 'components/argon/Navbars/AuthNavbar'
import Login from 'scenes/guest/Login'
import Register from 'scenes/guest/Register'
import MediaCatalog from 'scenes/guest/MediaCatalog'
import GuaranteedIrish from 'scenes/guest/GuaranteedIrish'
//import GiHome from 'scenes/guest/GiHome'
import BookAndBuy from 'scenes/guest/BookAndBuy'
import PaymentCompleted from 'scenes/guest/PaymentCompleted'
import PaymentPending from 'scenes/guest/PaymentPending'
import CompanyContext from 'config/context/CompanyContext'

const useStyles = makeStyles(componentStyles)

const Main = () => {
    const classes = useStyles()
    const mainContent = useRef(null)
    const location = useLocation()
    console.log(location)

    const company = useContext(CompanyContext)
    console.log('company: ', company)
    const isAdmatic = company.id === 'ad'
    console.log('is admatic: ', isAdmatic)

    useEffect(() => {
        document.body.classList.add(classes.bgDefault)
        return () => {
            document.body.classList.remove(classes.bgDefault)
        }
    })

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContent.current.scrollTop = 0
    }, [location])

    function getBodyClass() {
        let className = 'main-content guest'
        if (location.pathname === '/guaranteed-irish' || company.id === 'gi') {
            className += ' guaranteed-irish'
        }
        return className
    }

    return (
        <>
            <div className={getBodyClass()} ref={mainContent}>
                {/*<AuthNavbar />*/}
                <Switch>
                    {/*company.id === 'gi' &&
                        <>
                            <Route exact path='/' component={GiHome} />
                        </>
                    */}
                    <Route exact path='/' component={isAdmatic ? Login : MediaCatalog} />
                    <Route path='/login' component={Login} />
                    <Route path='/register' component={Register} />
                    <Route exact path='/catalog' component={MediaCatalog} />
                    <Route exact path='/catalog/:mediaCategory' component={MediaCatalog} />
                    <Route exact path='/catalog/:mediaCategory/:publisherId' component={MediaCatalog} />
                    <Route path='/payment-completed' component={PaymentCompleted} />
                    <Route path='/payment-pending' component={PaymentPending} />
                    {isAdmatic &&
                        <>
                            <Route path='/book-and-buy' component={BookAndBuy} />
                            <Route path='/guaranteed-irish' component={GuaranteedIrish} />
                        </>
                    }
                    {/*<Route path='/payment-completed' component={PaymentCompleted} />*/}
                    <Redirect from='*' to='/' />
                </Switch>
            </div>
            {/*<AuthFooter />*/}
        </>
    )
}

export default Main