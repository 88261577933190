import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import PublishersTable from 'components/admatic/PublishersTable'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function BookAndBuy() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new admaticClient()

    const [publishers, setPublishers] = useState([])
    const [loading, setLoading] = useState(true)

    // empty array argument prevents endless loop
    useEffect(() => {
        getPublishers()
    }, [])

    async function getPublishers() {
        try {
            const result = await api.getBbPublishers()

            // list alphabetically
            if (result) {
                result.sort((a, b) => {
                    let nameA = a.name.toLowerCase()
                    let nameB = b.name.toLowerCase()
                    if(nameA < nameB) { return -1 }
                    if(nameA > nameB) { return 1 }
                    return 0
                })
            }

            setPublishers(result)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <PublishersTable 
                            publishers={publishers}
                            refreshPublishers={() => getPublishers()}
                            loading={loading}
                            type={'bb'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default BookAndBuy