import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
//import Grid from '@material-ui/core/Grid'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function CompanyDetailsModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()

  	const details = props.details

  	const [address, setAddress] = useState(details.address ? details.address : '')
  	const [email, setEmail] = useState(details.email ? details.email : '')
  	const [phone, setPhone] = useState(details.phone ? details.phone: '')

  	function updateDetails() {
  		props.updateDetails(address, email, phone)
  	}

  	function checkFields() {
  		if (email.length > 0 && phone.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
			maxWidth='sm'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Company Details
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				<FormGroup>
                  	<FormLabel>Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='RTE'
                    	value={details.name}
                    	disabled
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Address</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='123 Main St, Dublin 6, Ireland'
                    	value={address}
                    	onChange={(e) => setAddress(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Primary Email</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='email'
                    	placeholder='admatic@example.com'
                    	value={email}
                    	onChange={(e) => setEmail(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Primary Phone</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='phone'
                    	placeholder='+353872234415'
                    	value={phone}
                    	onChange={(e) => setPhone(e.target.value)}
                  	/>
                </FormGroup>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => updateDetails()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	)
}