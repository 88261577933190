import React, { useState, useContext } from 'react'
import Box from '@material-ui/core/Box'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'

import CompanyContext from 'config/context/CompanyContext'
import DetailsContext from 'config/context/DetailsContext'

function EnquiryForm(props) {
    const api = new buyerClient()

    const details = useContext(DetailsContext)
    const company = useContext(CompanyContext)
    //console.log('company: ', company)

    const [category, setCategory] = useState('Radio')
    const [campaignName, setCampaignName] = useState('')
    const [message, setMessage] = useState('')

    const [alert, setAlert] = useState(false)

    function checkFormFields() {
        if (category && message.length > 0) {
            return false
        }
        else {
            return true
        }
    }

    async function submitEnquiry() {
        const enquiry = {
            name: details.name,
            companyName: details.buyerName,
            email: details.email,
            phone: details.phone,
            category: category,
            campaignName: campaignName,
            message: message
        }

        try {
            await api.submitEnquiry(company.id, props.type, enquiry)
            openSuccessAlert('Your enquiry has been submitted. A member of our team will contact you shortly.')
        } catch(e) {
            onError(e)
        }

        setCategory('Radio')
        setCampaignName('')
        setMessage('')
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <FormGroup>
                <FormLabel>Category</FormLabel>
                <FormControl variant='outlined' fullWidth>
                    <Select
                        multiple={false}
                        defaultValue={'Radio'}
                        IconComponent={KeyboardArrowDown}
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <MenuItem value={'Radio'}>Radio</MenuItem>
                        <MenuItem value={'Television'}>Television</MenuItem>
                        <MenuItem value={'Outdoor'}>Outdoor</MenuItem>
                        <MenuItem value={'Press'}>Press</MenuItem>
                        <MenuItem value={'Digital'}>Digital</MenuItem>
                        <MenuItem value={'Sponsorship'}>Sponsorship</MenuItem>
                    </Select>
                </FormControl>
            </FormGroup>
            <FormGroup>
                <FormLabel>Campaign Name</FormLabel>
                <OutlinedInput
                    fullWidth
                    type='text'
                    placeholder='RTE Radio 1 November'
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <FormLabel>Message</FormLabel>
                <OutlinedInput
                    fullWidth
                    multiline
                    rows='5'
                    type='text'
                    placeholder='I am looking for...'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </FormGroup>
            <Button
                component={Box}
                color='primary'
                marginLeft='auto!important'
                variant='contained'
                onClick={() => submitEnquiry()}
                disabled={checkFormFields()}
            >
                Submit
            </Button>
        </>
    )
}

export default EnquiryForm