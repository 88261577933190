import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

function VariantRow(props) {
    const variant = props.variant
    
    return (
        <>
            <Card>
                <CardContent>
                    <Grid 
                        container
                        spacing={1}
                        justify='center'
                        alignItems='center'
                    >
                        <Grid item xs={8}>
                            <Grid 
                                container
                                spacing={0}
                                direction='column'
                                justify='center'
                                alignItems='flex-start'
                            >
                                <Grid item>
                                    <Box
                                        component='p'
                                        marginBottom='1rem'
                                        fontWeight='500'
                                        lineHeight='1.2'
                                        fontSize='1rem'
                                        marginTop='0'
                                    >
                                        {variant.name}
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box
                                        component='p'
                                        marginBottom='1rem'
                                        fontWeight='300'
                                        lineHeight='1.2'
                                        fontSize='1rem'
                                        marginTop='0'
                                    >
                                        {variant.frequency} ({variant.days.join(', ')})
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box
                                        component='p'
                                        marginBottom='1rem'
                                        fontWeight='300'
                                        lineHeight='1.2'
                                        fontSize='1rem'
                                        marginTop='0'
                                    >
                                        €{variant.price} (Normally €{variant.agencyPrice})
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4}> 
                            <Grid 
                                container
                                spacing={0}
                                direction='row'
                                justify='flex-end'
                                alignItems='center'
                            >
                                <Grid item>
                                    {props.buyer &&
                                        <Checkbox 
                                            color='primary' 
                                            checked={props.selectedVariant && (props.selectedVariant.id === variant.id)} 
                                            onChange={() => props.setVariant(variant)} 
                                        />
                                    }
                                    {props.admatic &&
                                        <Box
                                            component='p'
                                            fontWeight='300'
                                            lineHeight='1.2'
                                            fontSize='1rem'
                                            marginTop='0'
                                            textAlign='right'
                                            color='#f5365c'
                                            className='hover-text'
                                            onClick={() => props.removeVariant(variant)}
                                        >
                                            Remove
                                        </Box>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
}

export default VariantRow