import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import { NotificationManager } from 'react-notifications'
//import AddTextModal from 'components/general/AddTextModal'
// import ReactDropzone from 'components/general/ReactDropzone'
import AddBbProductModal from 'components/admatic/AddBbProductModal'
import BbProductModal from 'components/admatic/BbProductModal'
import Loader from 'components/general/Loader'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function BbProductsModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
    const api = new admaticClient()
    const publisher = props.publisher

    const [sectionOptions, setSectionOptions] = useState(publisher.sections || [])
    const [sectionOption, setSectionOption] = useState('')
    const [products, setProducts] = useState([])
    const [showAddProductModal, setShowAddProductModal] = useState(false)
    const [showProductModal, setShowProductModal] = useState(false)
    const [product, setProduct] = useState(null)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getPublisherProducts()
    }, [])

    async function getPublisherProducts() {
        try {
            const result = await api.getBbPublisherProducts(publisher.publisherId)
            console.log('result: ', result)

            setProducts(result)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateSections() {
        console.log('save sections')
        try {
            const result = await api.updateBbPublisherSections(publisher.publisherId, sectionOptions)
            console.log('new publisher: ', result)

            NotificationManager.success('Sections updated!')
        } catch(e) {
            onError(e)
        }
    }

    async function addProduct(section, name, description, frequency, days, price, agencyPrice) {
        try {   
            const newProduct = await api.addBbProduct(publisher.publisherId, section, name, description, frequency, days, price, agencyPrice)
            console.log('product: ', newProduct)

            setShowAddProductModal(false)
            NotificationManager.success('Product added!')
            // should just add new product to state instead of getting all again
            getPublisherProducts()
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(section, name, description, frequency, days, price, agencyPrice) {
        try {   
            const newProduct = await api.updateBbProduct(publisher.publisherId, product.productId, section, name, description, frequency, days, price, agencyPrice)
            console.log('product: ', newProduct)

            NotificationManager.success('Product updated!')
            closeProductModal()
            // should just add new product to state instead of getting all again
            getPublisherProducts()
        } catch(e) {
            onError(e)
        }
    }

    function addSectionOption() {
        setSectionOptions(sectionOptions => [...sectionOptions, sectionOption])
        setSectionOption('')
    }

    function removeSectionOption(option) {
        setSectionOptions(sectionOptions.filter(item => item !== option))
    }

    function openProductModal(product) {
        setProduct(product)
        setShowProductModal(true)
    }

    function closeProductModal() {
        setShowProductModal(false)
        setProduct(null)
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
			maxWidth='md'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Manage Products
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
                {!loading ? (
                    <Grid container>
                        <Grid item xs={12} xl={12}>
                            <Grid container direction='row' spacing={1}>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormLabel style={{marginBottom: '0.5rem'}}>Sections</FormLabel>
                                    </FormGroup>
                                    {sectionOptions.map((option) => {
                                        return (
                                            <Box className='option-box' style={{marginBottom: '0.2rem', width: '100%'}}>
                                                <Box style={{display: 'flex', flexWrap: 'wrap', wordWrap: 'anywhere'}}>{option}</Box>
                                                <Box className='hover-text payment-status' onClick={() => removeSectionOption(option)}>remove</Box>
                                            </Box>
                                        )
                                    })}
                                    <FormGroup>
                                        <OutlinedInput
                                            //fullWidth
                                            style={{marginTop: '1rem'}}
                                            type='text'
                                            placeholder='Add an option'
                                            value={sectionOption}
                                            onChange={(e) => setSectionOption(e.target.value)}
                                        />
                                        <Button
                                            //component={Box}
                                            onClick={() => addSectionOption()}
                                            color='primary'
                                            variant='contained'
                                            size='small'
                                            style={{width: '50%', marginTop: '0.5rem'}}
                                            disabled={!(sectionOption.length > 0)}
                                        >
                                            Add
                                        </Button>
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormGroup>
                                        <FormLabel style={{marginBottom: '0.5rem'}}>Products</FormLabel>
                                    </FormGroup>
                                    {products.map((product) => {
                                        return (
                                            <Box className='option-box' style={{marginBottom: '0.2rem', width: '100%'}}>
                                                <Box style={{display: 'flex', flexWrap: 'wrap', wordWrap: 'anywhere'}}>{product.name} ({product.section})</Box>
                                                <Box className='hover-text payment-status' onClick={() => openProductModal(product)}>Edit</Box>
                                            </Box>
                                        )
                                    })}
                                    <Button
                                        //component={Box}
                                        onClick={() => setShowAddProductModal(true)}
                                        color='primary'
                                        variant='contained'
                                        size='small'
                                        style={{width: '50%', marginTop: '0.5rem'}}
                                    >
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container style={{marginTop: '2rem'}}>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        onClick={() => updateSections()}
                                        color='primary'
                                        variant='contained'
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                        <Loader />
                    </Box>
                )}
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Close
				</Button>
			</DialogActions>

            {showAddProductModal && 
                <AddBbProductModal 
                    isOpen={showAddProductModal}
                    close={() => setShowAddProductModal(false)}
                    addProduct={(section, name, description, frequency, days, price, agencyPrice) => addProduct(section, name, description, frequency, days, price, agencyPrice)}
                    //dropdownOptions={dropdownOptions}
                    publisher={publisher}
                    sections={sectionOptions}
                />
            }

            {showProductModal && 
                <BbProductModal 
                    isOpen={showProductModal}
                    close={() => closeProductModal()}
                    updateProduct={(section, name, description, frequency, days, price, agencyPrice) => updateProduct(section, name, description, frequency, days, price, agencyPrice)}
                    //dropdownOptions={dropdownOptions}
                    product={product}
                    publisher={publisher}
                    sections={sectionOptions}
                />
            }
		</Dialog>
	)
}