import React, { useState } from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

function DatePicker(props) {
    const [open, setOpen] = useState(false)

    function enableMondays(date) {
        if (date.day() === 1) {
            return false
        }
        else {
            return true
        }
    }

    function enableSundays(date) {
        if (date.day() === 0 && date.isAfter(props.startDate)) {
            return false
        }
        else {
            return true
        }
    }

    const MuiDatePicker = (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
                open={open}
                onClick={() => setOpen(true)}
                onClose={() => setOpen(false)}
                disableToolbar
                variant='inline'
                format='DD/MM/YY'
                placeholder='DD/MM/YY'
                margin='none'
                id='date-picker-inline'
                value={props.value}
                onChange={(e) => props.onChange(e)}
                autoOk={true}
                disablePast={true}
                minDate={props.minDate}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                InputProps={{
                   disableUnderline: true,
                }}
                shouldDisableDate={props.frequency === 'Daily' ? false : (props.startPicker ? enableMondays : enableSundays)}
            />
        </MuiPickersUtilsProvider>
    )

    return MuiDatePicker
}

export default DatePicker