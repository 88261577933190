import { Component } from 'react'
import { API } from 'aws-amplify'
import config from './Config'
//import { onError } from 'config/lib/errorLib'

const apiPath = config.apiGatewayGuest.PATH

class GuestClient extends Component {
	sendWelcomeEmail(email) {
		const data = {
			email: email
		}
		console.log(data)

		return API.post(apiPath, 'welcome', {
			body: data
		})
	}

	getAllPublishers(companyId) {
		const data = {
			companyId
		}
		console.log(data)
		
		return API.post(apiPath, 'publishers/list', {
			body: data
		})
	}

	getAllProducts(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/all-products', {
			body: data
		})
	}

	getAllBbPublishers() {
		return API.post(apiPath, 'publishers/list-bb', {})
	}

	getAllBbProducts() {
		return API.post(apiPath, 'publishers/all-products-bb', {})
	}

	addOrder(companyId, details, cart) {
		// split cart into separate orders in api
		const data = {
			companyId,
			details,
			cart
		}
		console.log(data)

		return API.post(apiPath, 'orders/add', {
			body: data
		})
	}

	getPaymentIntent(total, paymentId, buyerId, name, email) {
		const data = {
			total: total,
			paymentId: paymentId,
			buyerId: buyerId,
			name: name,
			email: email
		}
		console.log(data)

		return API.post(apiPath, 'payment/create-payment-intent', {
			body: data
		})
	}

	submitProductEnquiry(companyId, campaignName, message, details, publisher, product, variant) {
		const data = {
			companyId,
			campaignName,
			message,
			details,
			publisher,
			product,
			variant
		}
		console.log(data)

		return API.post(apiPath, 'enquiries/submit-product', {
			body: data
		})
	}

	sendUnpaidInvoice(email, paymentId, buyerId, companyId) {
		const data = {
			email: email,
			paymentId: paymentId,
			buyerId: buyerId,
			companyId: companyId
		}
		console.log(data)

		return API.post(apiPath, 'orders/send-unpaid-invoice', {
			body: data
		})
	}

	submitRequestCallback(companyId, enquiry) {
		const data = {
			companyId,
			enquiry
		}
		console.log(data)

		return API.post(apiPath, 'enquiries/submit-request-callback', {
			body: data
		})
	}
} 

export default GuestClient