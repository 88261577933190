import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
//import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import buyerClient from 'config/api/BuyerClient'
import guestClient from 'config/api/GuestClient'
import { onError } from 'config/lib/errorLib'
//import Loader from 'components/general/Loader'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

import config from 'config/api/Config'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

const stripePromise = loadStripe(config.stripeKey);

export default function StripeModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = props.type === 'guest' ? new guestClient() : new buyerClient()

	const details = props.details
	console.log('details: ', details)
	const payment = props.payment
	console.log('payment: ', payment)

	const [clientSecret, setClientSecret] = useState('')
	const [loading, setLoading] = useState(true)

	useEffect(() => {
        getPaymentIntent()
    }, [])

    async function getPaymentIntent() {
    	try {   
    		// const buyerDetails = await api.getBuyerDetails(details.buyerId)
      		// console.log('buyerDetails: ', buyerDetails)
    		
    		// const paymentMethods = await api.getPaymentMethods(buyerDetails.stripeCustomerId)
    		// console.log('payment methods: ', paymentMethods.paymentMethods.data[0].id)

			let buyerId = details.buyerId ? details.buyerId : 'guest'
            
            const paymentIntent = await api.getPaymentIntent(payment.total, payment.paymentId, buyerId, details.name, details.email)
            console.log('payment intent: ', paymentIntent)

            setClientSecret(paymentIntent.paymentIntent)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

	const appearance = {
    	theme: 'stripe',
  	}
	const options = {
    	clientSecret,
    	appearance,
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Pay Now
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			<DialogContent>
                {/*!loading ? (
					<Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                >
	                	<Grid item>
	                		{clientSecret && (
						        <Elements options={options} stripe={stripePromise}>
						          	<CheckoutForm 
						          		loading={loading}
						          		setLoading={(status) => setLoading(status)}
						          	/>
						        </Elements>
					      	)}
	                	</Grid>
	                </Grid>
				) : (
					<Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader />	
                    </Box>
				)*/}
				<Grid 
                    container
                    spacing={1}
                    direction='column'
                >
                	<Grid item>
                		{clientSecret && (
					        <Elements options={options} stripe={stripePromise}>
					          	<CheckoutForm 
					          		loading={loading}
					          		setLoading={(status) => setLoading(status)}
					          	/>
					        </Elements>
				      	)}
                	</Grid>
                </Grid>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	)
}