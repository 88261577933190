import React, { Component } from 'react'
import GuestRouter from 'config/routing/GuestRouter'
import BuyerRouter from 'config/routing/BuyerRouter'
import AdmaticRouter from 'config/routing/AdmaticRouter'
import CompanyAdminRouter from 'config/routing/CompanyAdminRouter'
//import Sidebar from './components/tim_components/Sidebar'
//import MfaModal from './components/general/MfaModal'
import { NotificationContainer } from 'react-notifications'
import { withRouter } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import Box from '@material-ui/core/Box'
import Loader from 'components/general/Loader'
import InactiveCard from 'components/general/InactiveCard'
import buyerClient from 'config/api/BuyerClient'
import admaticClient from 'config/api/AdmaticClient'
import { DetailsProvider } from 'config/context/DetailsContext'
import { CompanyProvider } from 'config/context/CompanyContext'
import { onError } from 'config/lib/errorLib'
import ErrorBoundary from 'components/general/ErrorBoundary'
import AdFavicon from 'resources/images/ad-favicon.png'
import GiFavicon from 'resources/images/gi-favicon.png'

//import AdGtag from 'resources/scripts/admatic/gtagmanager'

let companyId = 'gi'

const isLocal = process.env.NODE_ENV === 'development'
// Turn off logs in production
if (!isLocal) {
    console.log = function() {}
}

class App extends Component {
    constructor(props) {
        super(props)
        console.log(this.props)
        this.state = {
            loading: true,
            accountType: null,
            userDetails: null,
            name: null,
            company: null
        }
        this.buyerClient = new buyerClient()
        this.admaticClient = new admaticClient()
    }

    logout() {
        Auth.signOut().then((user) => {
            //window.location.replace('/');
            //this.props.history.push('/')
            console.log(user)
        }).catch(err => 
            onError(err)
        )
    }

    async componentDidMount() {
        try {
            const info = await Auth.currentUserInfo()
            console.log('info: ', info)
            console.log(this.props.authState)

            if (this.props.authState === 'signedIn' && Object.keys(info).length === 0) {
                this.logout()
            }

            let accountType = info ? info.attributes['custom:account_type'] : null
            let name = info ? info.attributes['name'] : null
            let email = info ? info.attributes['email'] : null
            let phone = info ? info.attributes['phone_number'] : null
            let businessName = info ? info.attributes['custom:businessName'] : null
            let businessId = info ? info.attributes['custom:businessId'] : null
            let permissions = info ? info.attributes['custom:permissions'] : null

            let confirmed = true
            let details = null
            let businessDetails = null

            if (accountType === 'buyer') {
                details = await this.buyerClient.getUserDetails(info.attributes['custom:businessId'])
                console.log('user details: ', details)
                businessDetails = await this.buyerClient.getBuyerDetails(info.attributes['custom:businessId'])
                console.log('business details: ', businessDetails)
                if (!details) {
                    let existingBusiness = businessDetails ? true : false
                    // if existing business then confirm the user immediately
                    details = await this.buyerClient.addUserDetails(name, email, phone, businessName, businessId, permissions, existingBusiness)
                    console.log('new user details: ', details)

                    if (!existingBusiness) {
                        businessDetails = await this.buyerClient.addBusinessDetails(businessId, businessName, info.id, name, email, phone)
                    }
                }
                confirmed = details.confirmed && businessDetails.confirmed
                details.accountType = accountType
            }

            if (accountType === 'admatic') {
                // if admatic - redirect to admatic version of site
                companyId = 'ad' // change to redirect when live

                if (permissions === 'admin') {
                    details = await this.admaticClient.getUserDetails()

                    if (!details) {
                        details = await this.admaticClient.addUserDetails(name, email, phone, permissions)
                        console.log('new user details: ', details)
                    }

                    confirmed = details.confirmed
                }
                else {
                    details = {
                        name: name
                    }
                    confirmed = true
                }
                details.accountType = accountType
            }

            if (accountType === 'companyAdmin') {
                details = {
                    name: name,
                    accountType: accountType
                }
                confirmed = true
            }

            // conditionally update headers to include tracking scripts
            if (companyId === 'ad') {
                const favicon = document.getElementById('favicon')
                favicon.href = AdFavicon
                const siteTitle = document.getElementById('site-title')
                siteTitle.innerHTML = 'Admatic'

                if (!isLocal) {
                    // add google tag manager
                    const existingGaScript1 = document.getElementById('gaScript1')
                    if (!existingGaScript1) {
                        const gaScript1 = document.createElement('script')
                        gaScript1.id = 'gaScript1'
                        gaScript1.src = 'https://www.googletagmanager.com/gtag/js?id=UA-124183940-58'
                        document.head.appendChild(gaScript1)
                    }
                    const existingGaScript2 = document.getElementById('gaScript2')
                    if (!existingGaScript2) {
                        const gaScript2 = document.createElement('script')
                        gaScript2.id = 'gaScript2'
                        gaScript2.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-124183940-58');"
                        document.head.appendChild(gaScript2)
                    }

                    // add secure smart business analytics
                    const existingSsbScript = document.getElementById('ssbScript')
                    if (!existingSsbScript) {
                        const ssbScript = document.createElement('script')
                        ssbScript.id = 'ssbScript'
                        ssbScript.type = 'text/javascript'
                        ssbScript.src = 'https://secure.smart-business-365.com/js/265407.js'
                        document.body.insertAdjacentElement("afterend", ssbScript);
                    }
                    const existingSsbNoScript = document.getElementById('ssbNoScript')
                    if (!existingSsbNoScript) {
                        const ssbNoScript = document.createElement('noscript')
                        ssbNoScript.id = ssbNoScript
                        ssbNoScript.innerHTML = "<img alt='' src='https://secure.smart-business-365.com/265407.png?trk_user=265407&trk_tit=jsdisabled&trk_ref=jsdisabled&trk_loc=jsdisabled' height='0px' width='0px' style='display:none;' />"
                        document.body.insertAdjacentElement("afterend", ssbNoScript);
                    }
                }
            }
            if (companyId === 'gi') {
                const favicon = document.getElementById('favicon')
                favicon.href = GiFavicon
                const siteTitle = document.getElementById('site-title')
                siteTitle.innerHTML = 'Guaranteed Irish'

                if (!isLocal) {
                    // add google tag manger
                    const existingGaScript1 = document.getElementById('gaScript1')
                    if (!existingGaScript1) {
                        const gaScript1 = document.createElement('script')
                        gaScript1.id = 'gaScript1'
                        gaScript1.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-WJ9JZRV');"
                        document.head.appendChild(gaScript1)
                    }
                    const existingGaScript2 = document.getElementById('gaScript2')
                    if (!existingGaScript2) {
                        const gaScript2 = document.createElement('noscript')
                        gaScript2.id = 'gaScript2'
                        gaScript2.innerHTML = "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-WJ9JZRV' height='0' width='0' style='display:none;visibility:hidden'></iframe>"
                        // insert after first body tag
                        let s = document.body.firstChild;
                        s.parentNode.insertBefore(gaScript2, s)
                    }

                    // add linkedin
                    const existingLiScript1 = document.getElementById('liScript1')
                    if (!existingLiScript1) {
                        const liScript1 = document.createElement('script')
                        liScript1.id = 'liScript1'
                        liScript1.innerHTML = "_linkedin_partner_id = '4722074';window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push(_linkedin_partner_id);"
                        document.body.appendChild(liScript1)
                    }
                    const existingLiScript2 = document.getElementById('liScript2')
                    if (!existingLiScript2) {
                        const liScript2 = document.createElement('script')
                        liScript2.id = 'liScript2'
                        liScript2.innerHTML = "(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s = document.getElementsByTagName('script')[0];var b = document.createElement('script');b.type = 'text/javascript';b.async = true;b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';s.parentNode.insertBefore(b, s);})(window.lintrk);"
                        document.body.appendChild(liScript2)
                    }
                    const existingLiScript3 = document.getElementById('liScript3')
                    if (!existingLiScript3) {
                        const liScript3 = document.createElement('noscript')
                        liScript3.id = 'liScript3'
                        liScript3.innerHTML = "<img height='1' width='1' style='display:none;'' alt='' src='https://px.ads.linkedin.com/collect/?pid=4722074&fmt=gif' />"
                        document.body.appendChild(liScript3)
                    }

                    // add meta pixel
                    const existingFbScript1 = document.getElementById('fbScript1')
                    if (!existingFbScript1) {
                        const fbScript1 = document.createElement('script')
                        fbScript1.id = 'fbScript1'
                        fbScript1.innerHTML = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '141325066423562');fbq('track', 'PageView');"
                        document.head.appendChild(fbScript1)
                    }
                    const existingFbScript2 = document.getElementById('fbScript2')
                    if (!existingFbScript2) {
                        const fbScript2 = document.createElement('noscript')
                        fbScript2.id = 'fbScript2'
                        fbScript2.innerHTML = "<img height='1' width='1' style='display:none' src='https://www.facebook.com/tr?id=141325066423562&ev=PageView&noscript=1'/>"
                        document.head.appendChild(fbScript2)
                    }

                    // add hotjar 
                    const existingHjScript1 = document.getElementById('hjScript1')
                    if (!existingHjScript1) {
                        const hjScript1 = document.createElement('script')
                        hjScript1.id = 'hjScript1'
                        hjScript1.innerHTML = "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3229057,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
                        document.head.appendChild(hjScript1)
                    }

                    // add no index 
                    const existingNoIndex = document.getElementById('noIndex')
                    if (!existingNoIndex) {
                        const noIndex = document.createElement('meta')
                        noIndex.name = 'robots'
                        noIndex.content = 'noindex,nofollow'
                        document.head.appendChild(noIndex)
                    }
                }
            }

            this.setState({
                accountType: accountType,
                confirmed: confirmed,
                name: name,
                details: details,
                //pageIntros: pageIntros ? pageIntros.content : null,
                company: {id: companyId},
                loading: false
            })
        } catch(e) {
            onError(e)
        }
    }

    render() {
        return (
            <ErrorBoundary>
                <>
                    <NotificationContainer />
                    {!this.state.loading ? (
                        <CompanyProvider value={this.state.company}>
                            {this.props.authState === 'signedIn' ? (
                                <>
                                    {this.state.accountType === 'buyer' &&
                                        <>
                                            {this.state.confirmed ? (
                                                <DetailsProvider value={this.state.details}>
                                                    <BuyerRouter />
                                                </DetailsProvider>
                                            ) : (
                                                <Box 
                                                    display='flex' 
                                                    flexDirection='column' 
                                                    justifyContent='center' 
                                                    alignItems='center'
                                                    style={{marginTop: '50px'}}
                                                >
                                                    <InactiveCard />
                                                </Box>
                                            )}
                                        </>
                                    }
                                    {this.state.accountType === 'admatic' &&
                                        <>
                                            {this.state.confirmed ? (
                                                <DetailsProvider value={this.state.details}>
                                                    <AdmaticRouter />
                                                </DetailsProvider>
                                            ) : (
                                                <Box 
                                                    display='flex' 
                                                    flexDirection='column' 
                                                    justifyContent='center' 
                                                    alignItems='center'
                                                    style={{marginTop: '50px'}}
                                                >
                                                    <InactiveCard />
                                                </Box>
                                            )}
                                        </>
                                    }
                                    {this.state.accountType === 'companyAdmin' &&
                                        <>
                                            {this.state.confirmed ? (
                                                <DetailsProvider value={this.state.details}>
                                                    <CompanyAdminRouter />
                                                </DetailsProvider>
                                            ) : (
                                                <Box 
                                                    display='flex' 
                                                    flexDirection='column' 
                                                    justifyContent='center' 
                                                    alignItems='center'
                                                    style={{marginTop: '50px'}}
                                                >
                                                    <InactiveCard />
                                                </Box>
                                            )}
                                        </>
                                    }
                                </>
                            ) : (
                                <GuestRouter />
                            )}
                        </CompanyProvider>
                    ) : (
                        <div>
                            <Loader 
                                className='full-page-loader'
                            />
                        </div>
                    )}
                </>
            </ErrorBoundary>
        );
    }
}

export default withRouter(App)