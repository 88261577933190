import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import admaticClient from 'config/api/AdmaticClient'
import Loader from 'components/general/Loader'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Dropdowns() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new admaticClient()

    const [ageGroupOptions, setAgeGroupOptions] = useState([])
    const [ageOption, setAgeOption] = useState('')
    const [socialGroupOptions, setSocialGroupOptions] = useState([])
    const [socialOption, setSocialOption] = useState('')

    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getDropdownOptions()
    }, [])

    async function getDropdownOptions() {
        try {
            const result = await api.getDropdowns()
            console.log('dropdown options: ', result)

            setAgeGroupOptions(result.options.ageGroups || [])
            setSocialGroupOptions(result.options.socialGroups || [])

            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function saveSettings() {
        console.log('save dropdown settings')
        // should be changed to be like content - updated with single param
        try {
            let options = {
                ageGroups: ageGroupOptions,
                socialGroups: socialGroupOptions
            }

            const newOptions = await api.updateDropdowns(options)
            console.log('new options: ', newOptions)

            openSuccessAlert('Dropdowns updated!')
        } catch(e) {
            onError(e)
        }
    }

    function addAgeOption() {
        setAgeGroupOptions(ageGroupOptions => [...ageGroupOptions, ageOption])
        setAgeOption('')
    }

    function removeAgeOption(option) {
        setAgeGroupOptions(ageGroupOptions.filter(item => item !== option))
    }

    function addSocialOption() {
        setSocialGroupOptions(socialGroupOptions => [...socialGroupOptions, socialOption])
        setSocialOption('')
    }

    function removeSocialOption(option) {
        setSocialGroupOptions(socialGroupOptions.filter(item => item !== option))
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12} xl={12}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title={'Manage Dropdowns'}
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: '0!important',
                                    variant: 'h2',
                                }}
                            >
                            </CardHeader>
                            {!loading ? (
                                <CardContent>
                                    <Grid container direction='row' spacing={1}>
                                        <Grid item xs={6}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '0.5rem'}}>Age Groups</FormLabel>
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Add an option'
                                                    value={ageOption}
                                                    onChange={(e) => setAgeOption(e.target.value)}
                                                />
                                                <Button
                                                    //component={Box}
                                                    onClick={() => addAgeOption()}
                                                    color='primary'
                                                    variant='contained'
                                                    size='small'
                                                    style={{width: '50%', marginTop: '0.5rem'}}
                                                    disabled={!(ageOption.length > 0)}
                                                >
                                                    Add
                                                </Button>
                                            </FormGroup>
                                            {ageGroupOptions.map((option) => {
                                                return (
                                                    <Box className='option-box' style={{marginBottom: '0.2rem', width: '100%'}}>
                                                        <Box style={{display: 'flex', flexWrap: 'wrap', wordWrap: 'anywhere'}}>{option}</Box>
                                                        <Box className='hover-text payment-status' onClick={() => removeAgeOption(option)}>remove</Box>
                                                    </Box>
                                                )
                                            })}
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '0.5rem'}}>Social Groups</FormLabel>
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Add an option'
                                                    value={socialOption}
                                                    onChange={(e) => setSocialOption(e.target.value)}
                                                />
                                                <Button
                                                    //component={Box}
                                                    onClick={() => addSocialOption()}
                                                    color='primary'
                                                    variant='contained'
                                                    size='small'
                                                    style={{width: '50%', marginTop: '0.5rem'}}
                                                    disabled={!(socialOption.length > 0)}
                                                >
                                                    Add
                                                </Button>
                                            </FormGroup>
                                            {socialGroupOptions.map((option) => {
                                                return (
                                                    <Box className='option-box' style={{marginBottom: '0.2rem', width: '100%'}}>
                                                        <Box style={{display: 'flex', flexWrap: 'wrap', wordWrap: 'anywhere'}}>{option}</Box>
                                                        <Box className='hover-text payment-status' onClick={() => removeSocialOption(option)}>remove</Box>
                                                    </Box>
                                                )
                                            })}
                                        </Grid>
                                    </Grid>
                                    <Grid container style={{marginTop: '2rem'}}>
                                        <Grid item>
                                            <Button
                                                component={Box}
                                                onClick={() => saveSettings()}
                                                color='primary'
                                                variant='contained'
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            ) : (
                                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                                    <Loader />
                                </Box>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Dropdowns