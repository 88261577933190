import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import StatsHeader from 'components/argon/Headers/StatsHeader'
import CardTotalOrders from 'components/argon/Cards/Charts/CardTotalOrders'
import OrdersByPublisherCard from 'components/general/OrdersByPublisherCard'
import TeamMembersCard from 'components/general/TeamMembersCard'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

import DetailsContext from 'config/context/DetailsContext'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Dashboard() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }

    const details = useContext(DetailsContext)

    return (
        <>
            <StatsHeader 
                section='Default' 
                subsection='Dashboards'
                admatic
            />
            <Container
                maxWidth={false}
                component={Box}
                marginTop='-6rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12} xl={6}>
                        <OrdersByPublisherCard
                            details={details}
                        />
                    </Grid>
                    <Grid item xs={12} xl={6}>
                        <TeamMembersCard 
                            details={details}
                            admatic
                        />
                    </Grid>
                    <Grid item xs={12} xl={12} classes={{ root: classes.gridItemRoot }}>
                        <CardTotalOrders />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Dashboard