import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Switch from '@material-ui/core/Switch'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import admaticClient from 'config/api/AdmaticClient'
import Loader from 'components/general/Loader'
import { onError } from 'config/lib/errorLib'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Notifications() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const details = useContext(DetailsContext)
    const api = new admaticClient()

    const [buyerRegistrationEnabled, setBuyerRegistrationEnabled] = useState(true)
    const [buyerRegistrationEmails, setBuyerRegistrationEmails] = useState(true)
    
    const [orderPlacedEnabled, setOrderPlacedEnabled] = useState(true)
    const [orderPlacedEmails, setOrderPlacedEmails] = useState(true)
    
    const [orderEnquiryEnabled, setOrderEnquiryEnabled] = useState(true)
    const [orderEnquiryEmails, setOrderEnquiryEmails] = useState(true)
    
    const [copyEnquiryEnabled, setCopyEnquiryEnabled] = useState(true)
    const [copyEnquiryEmails, setCopyEnquiryEmails] = useState(true)
    
    const [createAdEnquiryEnabled, setCreateAdEnquiryEnabled] = useState(true)
    const [createAdEnquiryEmails, setCreateAdEnquiryEmails] = useState(true)

    const [generalEnquiryEnabled, setGeneralEnquiryEnabled] = useState(true)
    const [generalEnquiryEmails, setGeneralEnquiryEmails] = useState(true)

    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getNotificationSettings()
    }, [])

    async function getNotificationSettings() {
        try {
            const notifications = await api.getNotificationSettings()
            console.log('notification settings: ', notifications)

            setBuyerRegistrationEnabled(notifications.buyerRegistration.enabled)
            setBuyerRegistrationEmails(notifications.buyerRegistration.emails)

            setOrderPlacedEnabled(notifications.orderPlaced.enabled)
            setOrderPlacedEmails(notifications.orderPlaced.emails)

            setOrderEnquiryEnabled(notifications.orderEnquiry.enabled)
            setOrderEnquiryEmails(notifications.orderEnquiry.emails)

            setCopyEnquiryEnabled(notifications.copyEnquiry.enabled)
            setCopyEnquiryEmails(notifications.copyEnquiry.emails)

            setCreateAdEnquiryEnabled(notifications.createAdEnquiry.enabled)
            setCreateAdEnquiryEmails(notifications.createAdEnquiry.emails)

            setGeneralEnquiryEnabled(notifications.generalEnquiry.enabled)
            setGeneralEnquiryEmails(notifications.generalEnquiry.emails)

            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function saveSettings() {
        console.log('save notification settings')
        // should be changed to be like content - updated with single param
        try {
            let buyerRegistration = {
                emails: buyerRegistrationEmails,
                enabled: buyerRegistrationEnabled
            }

            let orderPlaced = {
                emails: orderPlacedEmails,
                enabled: orderPlacedEnabled
            }

            let orderEnquiry = {
                emails: orderEnquiryEmails,
                enabled: orderEnquiryEnabled
            }

            let copyEnquiry = {
                emails: copyEnquiryEmails,
                enabled: copyEnquiryEnabled
            }

            let createAdEnquiry = {
                emails: createAdEnquiryEmails,
                enabled: createAdEnquiryEnabled
            }

            let generalEnquiry = {
                emails: generalEnquiryEmails,
                enabled: generalEnquiryEnabled
            }

            const newNotifications = await api.updateNotificationSettings(buyerRegistration, orderPlaced, orderEnquiry, copyEnquiry, createAdEnquiry, generalEnquiry)
            console.log('new notifications: ', newNotifications)

            openSuccessAlert('Notification settings updated!')
        } catch(e) {
            onError(e)
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12} xl={12}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardHeader
                                className={classes.cardHeader}
                                title={'Manage Notification Emails'}
                                titleTypographyProps={{
                                    component: Box,
                                    marginBottom: '0!important',
                                    variant: 'h2',
                                }}
                            >
                            </CardHeader>
                            {!loading ? (
                                <CardContent>
                                    <Grid container direction='column' spacing={1}>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '1rem'}}>New buyer sign up</FormLabel>
                                                <Switch name='buyerRegistration' color='primary' checked={buyerRegistrationEnabled} onChange={() => setBuyerRegistrationEnabled(!buyerRegistrationEnabled)} />
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Emails (comma separated)'
                                                    value={buyerRegistrationEmails}
                                                    onChange={(e) => setBuyerRegistrationEmails(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '1rem'}}>New order placed</FormLabel>
                                                <Switch name='orderPlaced' color='primary' checked={orderPlacedEnabled} onChange={() => setOrderPlacedEnabled(!orderPlacedEnabled)} />
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Emails (comma separated)'
                                                    value={orderPlacedEmails}
                                                    onChange={(e) => setOrderPlacedEmails(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '1rem'}}>New order enquiry</FormLabel>
                                                <Switch name='orderEnquiry' color='primary' checked={orderEnquiryEnabled} onChange={() => setOrderEnquiryEnabled(!orderEnquiryEnabled)} />
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Emails (comma separated)'
                                                    value={orderEnquiryEmails}
                                                    onChange={(e) => setOrderEnquiryEmails(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        {/*
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '1rem'}}>Copy clearance enquiry</FormLabel>
                                                <Switch name='copyEnquiry' color='primary' checked={copyEnquiryEnabled} onChange={() => setCopyEnquiryEnabled(!copyEnquiryEnabled)} />
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Emails (comma separated)'
                                                    value={copyEnquiryEmails}
                                                    onChange={(e) => setCopyEnquiryEmails(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '1rem'}}>Create advertising enquiry</FormLabel>
                                                <Switch name='createAdEnquiry' color='primary' checked={createAdEnquiryEnabled} onChange={() => setCreateAdEnquiryEnabled(!createAdEnquiryEnabled)} />
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Emails (comma separated)'
                                                    value={createAdEnquiryEmails}
                                                    onChange={(e) => setCreateAdEnquiryEmails(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        */}
                                        <Grid item xs={4}>
                                            <FormGroup>
                                                <FormLabel style={{marginBottom: '1rem'}}>General enquiry</FormLabel>
                                                <Switch name='generalEnquiry' color='primary' checked={generalEnquiryEnabled} onChange={() => setGeneralEnquiryEnabled(!generalEnquiryEnabled)} />
                                                <OutlinedInput
                                                    //fullWidth
                                                    style={{marginTop: '1rem'}}
                                                    type='text'
                                                    placeholder='Emails (comma separated)'
                                                    value={generalEnquiryEmails}
                                                    onChange={(e) => setGeneralEnquiryEmails(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Grid>

                                        <Grid item>
                                            <Button
                                                component={Box}
                                                onClick={() => saveSettings()}
                                                color='primary'
                                                variant='contained'
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            ) : (
                                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                                    <Loader />
                                </Box>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Notifications