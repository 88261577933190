import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Email from '@material-ui/icons/Email'
import Lock from '@material-ui/icons/Lock'
import Phone from '@material-ui/icons/Phone'
import Person from '@material-ui/icons/Person'
import Business from '@material-ui/icons/Business'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import { onError } from 'config/lib/errorLib'
import { useHistory } from 'react-router-dom'
import CompanyContext from 'config/context/CompanyContext'
import guestClient from 'config/api/GuestClient'

// core components
import AuthHeader from 'components/argon/Headers/AuthHeader.js'
import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'

import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles(componentStyles)
const useStylesButtons = makeStyles(componentStylesButtons)

function Register() {
    const classes = { ...useStyles(), ...useStylesButtons() }
    const theme = useTheme()
    const history = useHistory()
    const api = new guestClient()

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    let initialEmail = ''
    let initialStage = 'details'

    if (history.location.state && history.location.state.email) {
        initialEmail = history.location.state.email
        initialStage = 'confirmation'
    }

    const [name, setName] = useState('')
    const [business, setBusiness] = useState('')
    const [email, setEmail] = useState(initialEmail)
    const [countryCode, setCountryCode] = useState('+353')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [termsConditions, setTermsConditions] = useState(false)
    
    const [stage, setStage] = useState(initialStage)
    const [confirmationCode, setConfirmationCode] = useState('')

    async function register() {
        let formattedEmail = email.toLowerCase()

        let formattedPhone = countryCode + phone.replace(/^0+/, '')
        console.log('formatted phone: ', formattedPhone)

        let cognitoData = {
        	'username': formattedEmail, 
			'password': password,
			'attributes': {
				'name': name,
				'phone_number': formattedPhone,
				'custom:account_type': 'buyer',
				'custom:businessName': business,
				'custom:businessId': uuidv4(),
                'custom:permissions': 'admin'
			}
        }

        try {
        	console.log('register: ', cognitoData)
            
            Auth.signUp(cognitoData).then((data) => { 
				console.log(data)
				setStage('confirmation')
			}).catch((err) => {
				if (err.code === 'UsernameExistsException') {
					NotificationManager.error('An account has already been registered with the email address provided')
				}
				if (err.code === 'InvalidParameterException') {
	                NotificationManager.error('Please ensure you have entered a valid email address (example@example.com) and phone number (+353861234567)')
	            }
                onError(err)
			})
        } catch(e) {
            onError(e)
        }
    }

    async function confirmAccount() {
        if (confirmationCode.length > 0) {
            try {
                await Auth.confirmSignUp(email, confirmationCode)
                //await api.sendWelcomeEmail(email)
                setStage('confirmed')
            } catch(e) {
                console.log(e.code)
                if (e.code === 'CodeMismatchException') {
                    NotificationManager.error('Invalid code, please ensure you have entered the correct code')
                }
                onError(e)
            }
        }
        else {
            NotificationManager.error('Please enter the code that was sent to ' + email)
        }
    }

    async function resendCode() {
        let formattedPhone = countryCode + phone.replace(/^0+/, '')
        console.log('formatted phone: ', formattedPhone)
        try {
            await Auth.resendSignUp(email)
            NotificationManager.success('SMS sent')
        } catch(e) {
            console.log(e.code)
            onError(e)
        }
    }

    function checkFields() {
        if (name.length > 0 && email.length > 0 && phone.length > 0 && password.length > 0 && termsConditions) {
            return false
        }
        else {
            return true
        }
    }

    function getCompanyName() {
        if (company.id === 'ad') {
            return 'Admatic'
        }
        if (company.id === 'gi') {
            return 'Guaranteed Irish'
        }
    }
    
    return (
        <>
            {stage === 'details' &&
                <AuthHeader
                    title='Create an account'
                    description={'This is for companies that are not yet registered with ' + getCompanyName() + '. If your company already has an account please ask an administrator for an invite.'}
                />
            }

            {stage === 'confirmation' &&
                <AuthHeader
                    title='Check your phone'
                    description={'We just sent a confirmation code via SMS to ' + phone}
                />
            }

            {stage === 'confirmed' &&
                <AuthHeader
                    title='Account confirmed'
                    description=''
                />
            }
          
            <Container
                component={Box}
                maxWidth='xl'
                marginTop='-12rem'
                paddingBottom='3rem'
                position='relative'
                zIndex='101'
            >
                {stage === 'details' &&
                    <Box component={Grid} container justifyContent='center'>
                        <Grid item xs={12} lg={6} md={8}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Box
                                        color={theme.palette.gray[600]}
                                        textAlign='center'
                                        marginBottom='1rem'
                                        marginTop='.5rem'
                                        fontSize='1rem'
                                    >
                                        <Box fontSize='80%' fontWeight='400' component='small'>
                                            Enter your details below
                                        </Box>
                                    </Box>

                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <FilledInput
                                            autoComplete='off'
                                            type='text'
                                            placeholder='Name'
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            startAdornment={
                                                <InputAdornment position='start'>
                                                    <Person />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <FilledInput
                                            autoComplete='off'
                                            type='text'
                                            placeholder='Company Name (Optional)'
                                            value={business}
                                            onChange={(e) => setBusiness(e.target.value)}
                                            startAdornment={
                                                <InputAdornment position='start'>
                                                    <Business />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                            
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <FilledInput
                                            autoComplete='off'
                                            type='email'
                                            placeholder='Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            startAdornment={
                                                <InputAdornment position='start'>
                                                    <Email />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <FilledInput
                                                    autoComplete='off'
                                                    type='text'
                                                    placeholder='Country'
                                                    value={countryCode}
                                                    disabled={true}
                                                    onChange={(e) => setCountryCode(e.target.value)}
                                                    startAdornment={
                                                        <InputAdornment position='start'>
                                                            <Phone />
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                            <FilledInput
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Phone'
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                    <Box
                                        fontStyle='italic'
                                        fontSize='1rem'
                                        color={theme.palette.gray[600]}
                                        marginBottom='1rem'
                                    >
                                        <Box component='small' fontSize='80%'>
                                            Please ensure this number is correct as we will send you an SMS to verify your account
                                        </Box>
                                    </Box>
                            
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <FilledInput
                                            autoComplete='off'
                                            type='password'
                                            placeholder='Password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            startAdornment={
                                                <InputAdornment position='start'>
                                                    <Lock />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <Box
                                        fontStyle='italic'
                                        fontSize='1rem'
                                        color={theme.palette.gray[600]}
                                        marginBottom='.5rem'
                                    >
                                        <Box component='small' fontSize='80%'>
                                            Password must have at least 8 characters with an uppercase letter, lowercase letter, and a number
                                        </Box>
                                    </Box>
                                    
                                    <FormControlLabel
    						          	value='end'
    						          	control={
                                            <Checkbox 
                                                color='primary'  
                                                checked={termsConditions} 
                                                onChange={() => setTermsConditions(!termsConditions)} 
                                            />
                                        }
    						          	label={
    						            	<>
    						              		I agree with the{' '}
    						              		<Box
    						                		color={theme.palette.primary.main}
    						                		component='a'
    						                		textDecoration='none'
    						              		>
    						                		Privacy Policy
    						              		</Box>
    						              		{' '}and{' '}
    						              		<Box
    						                		color={theme.palette.primary.main}
    						                		component='a'
    						                		textDecoration='none'
    						              		>
    						                		Terms & Conditions
    						              		</Box>
    						            	</>
    						          	}
    						          	labelPlacement='end'
    						          	classes={{
    						            	root: classes.formControlLabelRoot,
    						            	label: classes.formControlLabelLabel,
    						          	}}
    						        />
                                    
                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            onClick={() => register()}
                                            disabled={checkFields()}
                                            className='step--login--register--success'
                                        >
                                            Create account
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>

                            <Grid container component={Box} marginTop='1rem'>
                                <Grid item xs={6} component={Box} textAlign='left'>
                                    <a
                                        href='/login'
                                        //onClick={(e) => e.preventDefault()}
                                        className={classes.footerLinks}
                                    >
                                        Already have an account? Sign In
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                }
                {stage === 'confirmation' &&
                    <Box component={Grid} container justifyContent='center'>
                        <Grid item xs={12} lg={6} md={8}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Box
                                        color={theme.palette.gray[600]}
                                        textAlign='center'
                                        marginBottom='1rem'
                                        marginTop='.5rem'
                                        fontSize='1rem'
                                    >
                                        <Box fontSize='80%' fontWeight='400' component='small'>
                                            Enter your confirmation code below
                                        </Box>
                                    </Box>

                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <FilledInput
                                            autoComplete='off'
                                            type='text'
                                            placeholder='Confirmation Code'
                                            value={confirmationCode}
                                            onChange={(e) => setConfirmationCode(e.target.value)}
                                        />
                                    </FormControl>
                                    
                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            onClick={() => resendCode()}
                                            style={{marginRight: '1rem'}}
                                        >
                                            Resend Code
                                        </Button>
                                        <Button
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            onClick={() => confirmAccount()}
                                        >
                                            Confirm Account
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                }
                {stage === 'confirmed' &&
                    <Box component={Grid} container justifyContent='center'>
                        <Grid item xs={12} lg={6} md={8}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Box textAlign='center'>
                                        <CheckCircle className='register-icon' />
                                    </Box>

                                    <Box
                                        color={theme.palette.gray[600]}
                                        textAlign='center'
                                        marginBottom='1rem'
                                        marginTop='1.5rem'
                                        fontSize='1rem'
                                    >
                                        <Box fontSize='80%' fontWeight='400' component='small'>
                                            You can now log in and start advertising direct!
                                        </Box>
                                    </Box>
                                    
                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            onClick={() => window.location.replace('/login')}
                                        >
                                            Go to login
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                }
                {company.id === 'gi' &&
                    <Grid container style={{paddingBottom: '20px', paddingTop: '20px'}}>
                        <Grid item xs={12} component={Box} textAlign='center'>
                            <a
                                href='https://admatic.ie'
                                //onClick={(e) => e.preventDefault()}
                                className={classes.footerLinks + ' powered-by'}
                                style={{color: 'white'}}
                            >
                                Powered by admatic.ie
                            </a>
                        </Grid>
                    </Grid>
                }
            </Container>
        </>
    )
}

export default Register