import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Add from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import AddProductModal from 'components/admatic/AddProductModal'
import ProductCard from 'components/admatic/ProductCard'
import ProductModal from 'components/admatic/ProductModal'
import WarningModal from 'components/general/WarningModal'
import Logo from 'components/general/Logo'
import Loader from 'components/general/Loader'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'
import { useHistory } from 'react-router-dom'
import { Storage } from 'aws-amplify'

import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function PublisherProducts() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new admaticClient()
    const history = useHistory()
    let publisher = history.location.state ? history.location.state.publisher : null
    //console.log('publisher details: ', publisher)

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    const [products, setProducts] = useState([])
    const [showProducts, setShowProducts] = useState([])
    const [showAddProductModal, setShowAddProductModal] = useState(false)
    const [showProductModal, setShowProductModal] = useState(false)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [product, setProduct] = useState(null)
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(true)
    const [dropdownOptions, setDropdownOptions] = useState(null)

    // empty array argument prevents endless loop
    useEffect(() => {
        getPublisherProducts()
    }, [])

    async function getPublisherProducts() {
        if (!publisher) {
            console.log('need to get publisher details from api')
            window.location.replace('/publishers')
        }
        else {
            try {
                const result = await api.getPublisherProducts(company.id, publisher.publisherId)
                console.log('result: ', result)
                const options = await api.getDropdowns()
                if (result) {
                    setProducts(result)
                    setShowProducts(result)
                }
                setDropdownOptions(options)
                setLoading(false)
            } catch(e) {
                onError(e)
            }
        }
    }

    async function addProduct(category, name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images) {
        let imageKeys = null
        if (images) {
            imageKeys = images.map((img) => {return img.id})
        }
        console.log('image keys: ', imageKeys)
        try {   
            const newProduct = await api.addProduct(company.id, publisher.publisherId, category, name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, imageKeys)
            console.log('product: ', newProduct)

            if (images) {
                for (var image of images) {
                    let path = 'publishers/' + publisher.publisherId + '/products/' + newProduct.productId + '/' + image.id
                    try {
                        await Storage.put(path, image.image, {
                            contentType: image.image.type
                        })
                    } catch(e) {
                        onError(e)
                    }
                }
            }

            setShowAddProductModal(false)
            openSuccessAlert('Product added!')
            // should just add new product to state instead of getting all again
            getPublisherProducts()
        } catch(e) {
            onError(e)
        }
    }

    async function updateProduct(name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images) {
        let imageKeys = null
        if (images) {
            imageKeys = images.map((img) => {return img.id})
        }
        console.log(product.images)
        console.log(imageKeys)
        let updateKeys = (JSON.stringify(product.images) !== JSON.stringify(imageKeys)) ? imageKeys : product.images
        try {   
            const newProduct = await api.updateProduct(company.id, publisher.publisherId, product.productId, name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, updateKeys)
            console.log('product: ', newProduct)

            // only update images if imageKeys has changed
            if (images && (JSON.stringify(product.images) !== JSON.stringify(imageKeys))) {
                for (var image of images) {
                    let path = 'publishers/' + publisher.publisherId + '/products/' + product.productId + '/' + image.id
                    console.log(path)
                    try {
                        await Storage.put(path, image.image, {
                            contentType: image.image.type
                        })
                    } catch(e) {
                        onError(e)
                    }
                }
            }
            else {
                console.log('no change in images')
            }

            setShowProductModal(false)
            openSuccessAlert('Product updated!')
            // should just update product to state instead of getting all again
            getPublisherProducts()
        } catch(e) {
            onError(e)
        }
    }

    async function deleteProduct() {
        try {   
            const result = await api.deleteProduct(company.id, publisher.publisherId, product.productId)
            console.log('deleted: ', result)

            setShowWarningModal(false)
            openSuccessAlert('Product deleted!')
            // should just update product to state instead of getting all again
            getPublisherProducts()
        } catch(e) {
            onError(e)
        }
    }

    function filterProducts(category) {
        if (category === 'All') {
            setShowProducts(products)
        }
        else {
            let show = []
            for (var product of products) {
                if (product.category === category) {
                    show.push(product)
                }
            }
            setShowProducts(show)
        }
    }

    function openProductModal(product) {
        setProduct(product)
        setShowProductModal(true)
    }

    function closeProductModal() {
        setShowProductModal(false)
        setProduct(null)
    }

    function openWarningModal(product) {
        setProduct(product)
        setShowWarningModal(true)
    }

    function closeWarningModal() {
        setShowWarningModal(false)
        setProduct(null)
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}

            {!loading ? (
                <Container
                    maxWidth={false}
                    component={Box}
                    marginTop='2rem'
                    classes={{ root: classes.containerRoot }}
                >
                    <Grid 
                        container
                        spacing={2}
                        direction='column'
                        alignItems='center'
                        justify='center'
                    >
                        <Grid item>
                            <Logo 
                                logo={publisher.logo}
                                name={publisher.name}
                                size='70px'
                                marginRight={'0'}
                            />
                        </Grid>
                        <Grid item>
                            <Box
                                component={Typography}
                                variant='h1'
                            >
                                <Box component='span'>{publisher.name}</Box>
                            </Box>
                        </Grid>
                        <Grid item xs='auto'>
                            <Box justifyContent='flex-end' display='flex' flexWrap='wrap'>
                                <Button variant='contained' size='medium' color='primary' onClick={() => setShowAddProductModal(true)}>
                                    <Box component={Add} position='relative' top='-2px' />{' '}
                                    Add Product
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid 
                        container
                        spacing={2}
                        direction='row'
                        alignItems='center'
                        justify='flex-start'
                    >
                        <Grid item xs={3}>
                            <FormGroup>
                                <FormLabel>Category</FormLabel>
                                <FormControl variant='outlined' fullWidth>
                                    <Select
                                        multiple={false}
                                        defaultValue={'All'}
                                        IconComponent={KeyboardArrowDown}
                                        onChange={(e) => filterProducts(e.target.value)}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        <MenuItem value={'Radio'}>Radio</MenuItem>
                                        <MenuItem value={'Television'}>Television</MenuItem>
                                        <MenuItem value={'Outdoor'}>Outdoor</MenuItem>
                                        <MenuItem value={'Press'}>Press</MenuItem>
                                        <MenuItem value={'Digital'}>Digital</MenuItem>
                                        <MenuItem value={'Sponsorship'}>Sponsorship</MenuItem>
                                    </Select>
                                </FormControl>
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <Grid 
                        container
                        spacing={2}
                        direction='row'
                        alignItems='center'
                        justify='flex-start'
                    >
                        {showProducts.map((product, i) => {
                            return (
                                <Grid item xs={3} key={i}>
                                    <ProductCard 
                                        product={product}
                                        openProductModal={() => openProductModal(product)}
                                        openWarningModal={() => openWarningModal(product)}
                                    />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Container>
            ) : (
                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                    <Loader />
                </Box>
            )}

            {showAddProductModal && 
                <AddProductModal 
                    isOpen={showAddProductModal}
                    close={() => setShowAddProductModal(false)}
                    addProduct={(category, name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images) => addProduct(category, name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images)}
                    dropdownOptions={dropdownOptions}
                    publisher={publisher}
                />
            }

            {showProductModal &&
                <ProductModal
                    isOpen={showProductModal}
                    close={() => closeProductModal()}
                    product={product}
                    updateProduct={(name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images) => updateProduct(name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images)}
                    dropdownOptions={dropdownOptions}
                />
            }

            <WarningModal 
                isOpen={showWarningModal}
                close={() => closeWarningModal()}
                primaryText={'Once this product is deleted it will no longer be availble to buyers and can not be recovered.'}
                onContinue={() => deleteProduct()}
            />
        </>
    );
}

export default PublisherProducts