import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Add from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import { v4 as uuidv4 } from 'uuid'
import { Storage } from 'aws-amplify'
import ReactDropzone from 'components/general/ReactDropzone'
import VariantRow from 'components/general/VariantRow'
import RichEditor from 'components/general/RichEditor'
import { counties } from 'config/constants/Constants'

import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ProductModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const product = props.product
  	//console.log('product: ', product)

	const company = useContext(CompanyContext)
	console.log('company: ', company)

  	//const [category, setCategory] = useState('Radio')
  	const [name, setName] = useState(product.name)
  	const [tagline, setTagline] = useState(product.tagline || '')
  	const [description, setDescription] = useState(product.description)

	const [ageGroup, setAgeGroup] = useState(product.ageGroup || '')
	const [socialGroup, setSocialGroup] = useState(product.socialGroup || '')
	const [county, setCounty] = useState(product.county || '')
  	
  	const [submitEnquiry, setSubmitEnquiry] = useState(product.submitEnquiry)
  	const [bookNow, setBookNow] = useState(product.bookNow)

  	const [frequency, setFrequency] = useState(product.frequency)
  	const [days, setDays] = useState(product.days)
  	const [price, setPrice] = useState(product.price)
  	const [agencyPrice, setAgencyPrice] = useState(product.agencyPrice)
  	const [hasVariants, setHasVariants] = useState(product.variants && product.variants.length > 0)
  	const [variants, setVariants] = useState(product.variants || [])
  	const [stage, setStage] = useState('details')

  	const [images, setImages] = useState([])
  	
  	const [variantName, setVariantName] = useState('')
  	const [variantFrequency, setVariantFrequency] = useState(null)
  	const [variantDays, setVariantDays] = useState([])
  	const [variantPrice, setVariantPrice] = useState('')
  	const [variantAgencyPrice, setVariantAgencyPrice] = useState('')

  	useEffect(() => {
        getImages()
    }, [])

    async function getImages() {
        if (product.images.length > 0) {
        	for (var key of product.images) {
        		let image = await Storage.get('publishers/' + product.publisherId + '/products/' + product.productId + '/' + key)
        		console.log(image)
        		let img = {
		  			id: key,
		  			image: image
		  		}
		  		console.log(img)
		  		setImages(images => [...images, img])
        	}
        }
    }

  	function updateProduct() {
  		// check all fields are vaild
  		// should api call be here?
  		props.updateProduct(name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images)
  	}

  	function updateImages(image) {
  		let img = {
  			id: uuidv4(),
  			image: image
  		}
  		setImages([img])
  	}

  	function addVariant() {
  		const variant = {
  			id: uuidv4(),
  			name: variantName,
  			frequency: variantFrequency,
  			days: variantDays,
  			price: variantPrice,
  			agencyPrice: variantAgencyPrice
  		}

  		setVariants(variants => [...variants, variant])
  		setVariantName('')
  		setVariantFrequency(null)
  		setVariantDays([])
  		setVariantPrice('')
  		setVariantAgencyPrice('')
  	}

  	function removeVariant(removeVariant) {
  		const index = variants.findIndex(variant => variant.id === removeVariant.id)
        console.log('index in variants: ', index)

        let newVariants = [...variants]

        newVariants.splice(index, 1)
        setVariants(newVariants)
  	}

  	function checkProductFields() {
  		// single price product
  		if (stage === 'details') {
	  		if (name.length > 0 && description.length > 0 && frequency && days.length > 0) {
	  			if (bookNow) {
	  				if(price > 0 && agencyPrice > 0) {
	  					return false
	  				}
	  				else {
	  					return true
	  				}
	  			}
	  			else {
	  				return false 
	  			}
	  		}
	  		else {
	  			return true
	  		}
	  	}
	  	// product with variants
	  	if (stage === 'variants') {
	  		if (name.length > 0 && description.length > 0 && variants.length > 0) {
	  			return false
	  		}
	  		else {
	  			return true
	  		}
	  	}
  	}

  	function checkVariantFields() {
  		if (variantName.length > 0 && variantFrequency && variantDays.length > 0) {
  			if (bookNow) {
  				if(variantPrice > 0 && variantAgencyPrice > 0) {
  					return false
  				}
  				else {
  					return true
  				}
  			}
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{stage === 'details' &&
						<>Update Product</>
					}
					{stage === 'variants' &&
						<>Update Variants</>
					}
					{stage === 'images' &&
						<>Update Image</>
					}
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

			{stage === 'details' &&
				<DialogContent>
					<FormGroup>
	                  	<FormLabel>Category</FormLabel>
	                  	<FormControl variant='outlined' fullWidth>
	                    	<Select
	                      		multiple={false}
	                      		defaultValue={'Radio'}
	                      		IconComponent={KeyboardArrowDown}
	                      		value={product.category}
	                      		//onChange={(e) => setCategory(e.target.value)}
	                      		disabled={true}
	                    	>
	                      		<MenuItem value={'Radio'}>Radio</MenuItem>
	                      		<MenuItem value={'Television'}>Television</MenuItem>
	                      		<MenuItem value={'Outdoor'}>Outdoor</MenuItem>
	                      		<MenuItem value={'Press'}>Press</MenuItem>
	                      		<MenuItem value={'Digital'}>Digital</MenuItem>
	                      		<MenuItem value={'Sponsorship'}>Sponsorship</MenuItem>
	                    	</Select>
	                  	</FormControl>
	                </FormGroup>
					<FormGroup>
	                  	<FormLabel>Name</FormLabel>
	                  	<OutlinedInput
	                    	fullWidth
	                    	type='text'
	                    	placeholder='10 Spot Package'
	                    	value={name}
	                    	onChange={(e) => setName(e.target.value)}
	                  	/>
	                </FormGroup>
	                {/*
	                <FormGroup>
	                  	<FormLabel>Tagline (appears below product name in media catalog, keep short with consistent font size!)</FormLabel>
                  		<RichEditor 
                  			value={tagline} 
                  			onChange={setTagline} 
                  			placeholder='Recommended by Admatic this week'
                  			product
                  		/>
	                </FormGroup>
	            	*/}
	                <FormGroup>
	                  	<FormLabel>Description</FormLabel>
                  		<RichEditor
                  			value={description} 
                  			onChange={setDescription} 
                  			placeholder='10 advertisements at various times Mon-Fri'
                  			product
                  		/>
	                </FormGroup>

					<FormGroup>
		                <FormLabel>Age Group</FormLabel>
						<FormControl variant='outlined' fullWidth>
							<Select
								multiple={false}
								IconComponent={KeyboardArrowDown}
								value={ageGroup}
								onChange={(e) => setAgeGroup(e.target.value)}
							>
								{props.dropdownOptions.options.ageGroups.map((option, i) => {
									return (<MenuItem value={option}>{option}</MenuItem>)
								})}
							</Select>
						</FormControl>
	                </FormGroup>

					<FormGroup>
		                <FormLabel>Social Group</FormLabel>
						<FormControl variant='outlined' fullWidth>
							<Select
								multiple={false}
								IconComponent={KeyboardArrowDown}
								value={socialGroup}
								onChange={(e) => setSocialGroup(e.target.value)}
							>
								{props.dropdownOptions.options.socialGroups.map((option, i) => {
									return (<MenuItem value={option}>{option}</MenuItem>)
								})}
							</Select>
						</FormControl>
	                </FormGroup>

					<FormGroup>
		                <FormLabel>Location</FormLabel>
						<FormControl variant='outlined' fullWidth>
							<Select
								multiple={false}
								IconComponent={KeyboardArrowDown}
								value={county}
								onChange={(e) => setCounty(e.target.value)}
							>
								{counties.map((county, i) => {
									return (<MenuItem value={county}>{county}</MenuItem>)
								})}
							</Select>
						</FormControl>
	                </FormGroup>

	                <FormGroup>
	                  	<FormLabel>Allow buyers to submit an enquiry form?</FormLabel>
	                  	<Switch name='submitEnquiry' color='primary' checked={submitEnquiry} onChange={() => setSubmitEnquiry(!submitEnquiry)} />
                	</FormGroup>

                	<FormGroup>
	                  	<FormLabel>Allow buyers to book this item immediately for the price shown?</FormLabel>
	                  	<Switch name='bookNow' color='primary' checked={bookNow} onChange={() => setBookNow(!bookNow)} />
                	</FormGroup>
	                	
	                <FormGroup>
	                  	<FormLabel>Does this product have variants?</FormLabel>
	                  	<Switch name='hasVariants' color='primary' checked={hasVariants} onChange={() => setHasVariants(!hasVariants)} disabled />
	                </FormGroup>

	                {hasVariants ? (
	                	<Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.7'
	                        fontSize='1rem'
	                        //marginTop="1.5rem"
	                    >
	                        Click 'Next' to add product variants
	                    </Box>
	                ) : (
	                	<Grid container>
	                		<Grid item xs={6}>
				                <FormGroup>
				                  	<FormLabel>Frequency (Charged Per)</FormLabel>
				                  	<FormControl variant='outlined' fullWidth>
				                    	<Select
				                      		multiple={false}
				                      		value={frequency}
				                      		IconComponent={KeyboardArrowDown}
				                      		onChange={(e) => setFrequency(e.target.value)}
				                    	>
				                      		<MenuItem value={'Daily'}>Daily</MenuItem>
				                      		<MenuItem value={'Weekly'}>Weekly</MenuItem>
				                      		<MenuItem value={'Monthly'}>Monthly</MenuItem>
				                    	</Select>
				                  	</FormControl>
				                </FormGroup>
				            </Grid>
				            <Grid item xs={6}>
				                <FormGroup>
				                  	<FormLabel>Days ads run</FormLabel>
				                  	<FormControl variant='outlined' fullWidth>
				                    	<Select
				                      		multiple={true}
				                      		defaultValue={days}
				                      		IconComponent={KeyboardArrowDown}
				                      		onChange={(e) => setDays(e.target.value)}
				                    	>
				                      		<MenuItem value={'Monday'}>Monday</MenuItem>
				                      		<MenuItem value={'Tuesday'}>Tuesday</MenuItem>
				                      		<MenuItem value={'Wednesday'}>Wednesday</MenuItem>
				                      		<MenuItem value={'Thursday'}>Thursday</MenuItem>
				                      		<MenuItem value={'Friday'}>Friday</MenuItem>
				                      		<MenuItem value={'Saturday'}>Saturday</MenuItem>
				                      		<MenuItem value={'Sunday'}>Sunday</MenuItem>
				                    	</Select>
				                  	</FormControl>
				                </FormGroup>
				            </Grid>
		                	<Grid item xs={6}>
				                <FormGroup>
									{company.id === 'ad' &&
				                  		<FormLabel>Admatic Price</FormLabel>
									}
									{company.id === 'gi' &&
				                  		<FormLabel>Guaranteed Irish Price</FormLabel>
									}
				                  	<OutlinedInput
				                    	fullWidth
				                    	type='number'
				                    	placeholder='8500'
				                    	value={price}
				                    	onChange={(e) => setPrice(e.target.value)}
				                  	/>
				                </FormGroup>
				            </Grid>
				            <Grid item xs={6}>
				                <FormGroup>
				                  	<FormLabel>Competitor Price</FormLabel>
				                  	<OutlinedInput
				                    	fullWidth
				                    	type='number'
				                    	placeholder='11000'
				                    	value={agencyPrice}
				                    	onChange={(e) => setAgencyPrice(e.target.value)}
				                  	/>
				                </FormGroup>
				            </Grid>
			            </Grid>
	                )}
				</DialogContent>
			}
			{stage === 'variants' &&
				<DialogContent>
					<FormGroup>
	                  	<FormLabel>Variant Name</FormLabel>
	                  	<OutlinedInput
	                    	fullWidth
	                    	type='text'
	                    	placeholder='10 Spot Package'
	                    	value={variantName}
	                    	onChange={(e) => setVariantName(e.target.value)}
	                  	/>
	                </FormGroup>
					<Grid container>
						<Grid item xs={6}>
			                <FormGroup>
			                  	<FormLabel>Frequency (Charged Per)</FormLabel>
			                  	<FormControl variant='outlined' fullWidth>
			                    	<Select
			                      		multiple={false}
			                      		//defaultValue={null}
			                      		IconComponent={KeyboardArrowDown}
			                      		value={variantFrequency}
			                      		onChange={(e) => setVariantFrequency(e.target.value)}
			                    	>
			                      		<MenuItem value={'Daily'}>Daily</MenuItem>
			                      		<MenuItem value={'Weekly'}>Weekly</MenuItem>
			                      		<MenuItem value={'Monthly'}>Monthly</MenuItem>
			                    	</Select>
			                  	</FormControl>
			                </FormGroup>
			            </Grid>
			            <Grid item xs={6}>
			                <FormGroup>
			                  	<FormLabel>Days ads run</FormLabel>
			                  	<FormControl variant='outlined' fullWidth>
			                    	<Select
			                      		multiple={true}
			                      		//defaultValue={[]}
			                      		IconComponent={KeyboardArrowDown}
			                      		value={variantDays}
			                      		onChange={(e) => setVariantDays(e.target.value)}
			                    	>
			                      		<MenuItem value={'Monday'}>Monday</MenuItem>
			                      		<MenuItem value={'Tuesday'}>Tuesday</MenuItem>
			                      		<MenuItem value={'Wednesday'}>Wednesday</MenuItem>
			                      		<MenuItem value={'Thursday'}>Thursday</MenuItem>
			                      		<MenuItem value={'Friday'}>Friday</MenuItem>
			                      		<MenuItem value={'Saturday'}>Saturday</MenuItem>
			                      		<MenuItem value={'Sunday'}>Sunday</MenuItem>
			                    	</Select>
			                  	</FormControl>
			                </FormGroup>
			            </Grid>
	                	<Grid item xs={6}>
			                <FormGroup>
								{company.id === 'ad' &&
									<FormLabel>Admatic Price</FormLabel>
								}
								{company.id === 'gi' &&
									<FormLabel>Guaranteed Irish Price</FormLabel>
								}
			                  	<OutlinedInput
			                    	fullWidth
			                    	type='number'
			                    	placeholder='8500'
			                    	value={variantPrice}
			                    	onChange={(e) => setVariantPrice(e.target.value)}
			                  	/>
			                </FormGroup>
			            </Grid>
			            <Grid item xs={6}>
			                <FormGroup>
			                  	<FormLabel>Agency Price</FormLabel>
			                  	<OutlinedInput
			                    	fullWidth
			                    	type='number'
			                    	placeholder='11000'
			                    	value={variantAgencyPrice}
			                    	onChange={(e) => setVariantAgencyPrice(e.target.value)}
			                  	/>
			                </FormGroup>
			            </Grid>
		            </Grid>
		            <Button 
		            	variant='contained' 
		            	size='small' 
		            	color='primary' 
		            	onClick={() => addVariant()}
		            	disabled={checkVariantFields()}
		            >
                        <Box component={Add} position='relative' top='-2px' />{' '}
                        Add Variant
                    </Button>

                    <Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                    style={{marginTop: '1rem'}}
	                >
	                	<Grid item>
		                	{variants.map((variant, i) => {
		                		return (
				                	<VariantRow
				                		key={i}
				                		variant={variant}
				                		removeVariant={() => removeVariant(variant)}
				                		admatic
				                	/>
				                )
		                	})}
	                	</Grid>
	                </Grid>
				</DialogContent>
			}
			{stage === 'images' &&
				<DialogContent>
					<FormGroup>
						<div className='add-logo-dropzone'>
							<ReactDropzone 
								logo={images.length > 0 ? images[0].image : null}
								updateLogo={(image) => updateImages(image)}
								flexible
							/>
						</div>
					</FormGroup>
				</DialogContent>
			}
			
			<DialogActions>
				{stage === 'details' &&
					<Button
						onClick={() => props.close()}
						color='primary'
					>
						Cancel
					</Button>
				}
				{stage === 'variants' &&
					<Button
						onClick={() => setStage('details')}
						color='primary'
					>
						Back
					</Button>
				}
				{stage === 'images' &&
					<Button
						onClick={hasVariants ? () => setStage('variants') : () => setStage('details')}
						color='primary'
					>
						Back
					</Button>
				}

				{(stage === 'details' && hasVariants) &&
					<Button
						component={Box}
						onClick={() => setStage('variants')}
						color='primary'
						marginLeft='auto!important'
						variant='contained'
					>
						Next
					</Button>
				}
				{(stage === 'details' && !hasVariants) &&
					<Button
						component={Box}
						color='primary'
						marginLeft='auto!important'
						variant='contained'
						onClick={() => setStage('images')}
						disabled={checkProductFields()}
					>
						Next
					</Button>
				}
				{stage === 'variants' &&
					<Button
						component={Box}
						color='primary'
						marginLeft='auto!important'
						variant='contained'
						onClick={() => setStage('images')}
						disabled={checkProductFields()}
					>
						Next
					</Button>
				}
				{stage === 'images' &&
					<Button
						component={Box}
						color='primary'
						marginLeft='auto!important'
						variant='contained'
						onClick={() => updateProduct()}
						//disabled={checkProductFields()}
					>
						Update Product
					</Button>
				}
			</DialogActions>
		</Dialog>
	)
}