import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Grid from '@material-ui/core/Grid'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AddBbProductModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()

  	const [section, setSection] = useState(props.sections[0] || null)
  	const [name, setName] = useState('')
  	const [description, setDescription] = useState('')
  	const [frequency, setFrequency] = useState(null)
  	const [days, setDays] = useState([])
  	const [price, setPrice] = useState('')
  	const [agencyPrice, setAgencyPrice] = useState('')

  	function checkProductFields() {
  		// single price product
        if (section && name.length > 0 && frequency && days.length > 0) {
            if(price > 0) {
                return false
            }
            else {
                return true
            }
        }
        else {
            return true
        }
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Add Product
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>

            <DialogContent>
                <FormGroup>
                    <FormLabel>Section</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            //defaultValue={section}
                            IconComponent={KeyboardArrowDown}
                            value={section}
                            onChange={(e) => setSection(e.target.value)}
                        >
                            {props.sections.map((option, i) => {
                                return (<MenuItem value={option}>{option}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Name</FormLabel>
                    <OutlinedInput
                        fullWidth
                        type='text'
                        placeholder='10 Spot Package'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormGroup>

                {/*
                <FormGroup>
                    <FormLabel>Description</FormLabel>
                    <RichEditor 
                        value={description} 
                        onChange={setDescription} 
                        placeholder='10 advertisements at various times Mon-Fri'
                        product
                    />
                </FormGroup>
                */}

                <Grid container>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormLabel>Frequency (Charged Per)</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={false}
                                    defaultValue={null}
                                    IconComponent={KeyboardArrowDown}
                                    value={frequency}
                                    onChange={(e) => setFrequency(e.target.value)}
                                >
                                    <MenuItem value={'Daily'}>Daily</MenuItem>
                                    <MenuItem value={'Weekly'}>Weekly</MenuItem>
                                    <MenuItem value={'Monthly'}>Monthly</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormLabel>Days ads run</FormLabel>
                            <FormControl variant='outlined' fullWidth>
                                <Select
                                    multiple={true}
                                    defaultValue={[]}
                                    IconComponent={KeyboardArrowDown}
                                    value={days}
                                    onChange={(e) => setDays(e.target.value)}
                                >
                                    <MenuItem value={'Monday'}>Monday</MenuItem>
                                    <MenuItem value={'Tuesday'}>Tuesday</MenuItem>
                                    <MenuItem value={'Wednesday'}>Wednesday</MenuItem>
                                    <MenuItem value={'Thursday'}>Thursday</MenuItem>
                                    <MenuItem value={'Friday'}>Friday</MenuItem>
                                    <MenuItem value={'Saturday'}>Saturday</MenuItem>
                                    <MenuItem value={'Sunday'}>Sunday</MenuItem>
                                </Select>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormLabel>Price per word</FormLabel>
                            <OutlinedInput
                                fullWidth
                                type='number'
                                placeholder='5'
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </FormGroup>
                    </Grid>
                    {/*
                    <Grid item xs={6}>
                        <FormGroup>
                            <FormLabel>Agency Price</FormLabel>
                            <OutlinedInput
                                fullWidth
                                type='number'
                                placeholder='11000'
                                value={agencyPrice}
                                onChange={(e) => setAgencyPrice(e.target.value)}
                            />
                        </FormGroup>
                    </Grid>
                    */}
                </Grid>
            </DialogContent>
			
			<DialogActions>
                <Button
                    onClick={() => props.close()}
                    color='primary'
                >
                    Cancel
                </Button>
                <Button
                    component={Box}
                    color='primary'
                    marginLeft='auto!important'
                    variant='contained'
                    onClick={() => props.addProduct(section, name, description, frequency, days, price, agencyPrice)}
                    disabled={checkProductFields()}
                >
                    Add Product
                </Button>
			</DialogActions>
		</Dialog>
	)
}