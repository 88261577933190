import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import MoreVert from '@material-ui/icons/MoreVert'
// core components
import componentStyles from 'assets/theme/components/cards/alternative/card-page-visits-alternative.js'
import WarningModal from 'components/general/WarningModal'
import BuyerModal from 'components/admatic/BuyerModal'
import ViewCopyModal from 'components/admatic/ViewCopyModal'
import Logo from 'components/general/Logo'
import Loader from 'components/general/Loader'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'

const useStyles = makeStyles(componentStyles)

export default function BuyersTable(props) {
    const classes = useStyles()
    const api = new admaticClient()

    const buyers = props.buyers || []

    const [showBuyerModal, setShowBuyerModal] = useState(false)
    const [showViewCopyModal, setShowViewCopyModal] = useState(false)
    const [buyer, setBuyer] = useState(null)
    const [alert, setAlert] = useState(false)

    function closeSuccessAlert() {
        setAlert(false)
    }

    function openBuyerModal(buyer) {
        setBuyer(buyer)
        setShowBuyerModal(true)
    }

    function closeBuyerModal() {
        setShowBuyerModal(false)
        setBuyer(null)
    }

    function openViewCopyModal(buyer) {
        setBuyer(buyer)
        setShowViewCopyModal(true)
    }

    function closeViewCopyModal() {
        setShowViewCopyModal(false)
        setBuyer(null)
    }

    async function activateBuyer(buyer) {
        try {   
            const newBuyer = await api.activateBuyer(buyer.buyerId, buyer.rootUserId, buyer.email)
            console.log('buyer: ', newBuyer)

            //closeWarningModal()
            openSuccessAlert('This buyer has been activated and can now place orders.')
            props.refreshBuyers()
        } catch(e) {
            onError(e)
        }
    }

    async function deactivateBuyer(buyer) {
        try {   
            const newBuyer = await api.deactivateBuyer(buyer.buyerId)
            console.log('buyer: ', newBuyer)

            //closeWarningModal()
            openSuccessAlert('This buyer has been deactivated and can no longer place orders.')
            props.refreshBuyers()
        } catch(e) {
            onError(e)
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => closeSuccessAlert()}
                onCancel={() => closeSuccessAlert()}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    const tableHead = ['Name', 'Primary User', 'Email', 'Phone', 'Status', '']
    
    return (
    <>
        {alert}
        <Card classes={{ root: classes.cardRoot }}>
            <CardHeader
                subheader={
                    <Grid
                        container
                        component={Box}
                        alignItems='center'
                        justifyContent='space-between'
                    >
                        <Grid item xs='auto'>
                            <Box
                                component={Typography}
                                variant='h2'
                                marginBottom='0!important'
                            >
                                <Box component='span'>All Buyers</Box>
                            </Box>
                        </Grid>
                    </Grid>
                }
                classes={{ root: classes.cardHeaderRoot }}
            >
            </CardHeader>
            
            <TableContainer>
                {!props.loading ? (
                    <Box component={Table} alignItems='center' marginBottom='0!important'>
                        {buyers.length === 0 &&
                            <caption>No buyers</caption>
                        }
                        <TableHead>
                            <TableRow>
                                {tableHead.map((prop, key) => (
                                    <TableCell
                                        key={key}
                                        classes={{
                                            root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
                                        }}
                                    >
                                        {prop}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            {buyers.map((buyer, key) => (
                                <TableRow key={key}>
                                    <TableCell
                                        classes={{
                                            root:
                                            classes.tableCellRoot +
                                            ' ' +
                                            classes.tableCellRootBodyHead,
                                        }}
                                        component='th'
                                        variant='head'
                                        scope='row'
                                    >
                                        <Box alignItems='center' display='flex'>
                                            <Logo 
                                                logo={buyer.logo}
                                                name={buyer.name ? buyer.name : buyer.rootUserName}
                                            />
                                            <Box display='flex' alignItems='flex-start'>
                                                <Box fontSize='.875rem' component='span'>
                                                    {buyer.name ? buyer.name : buyer.rootUserName}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </TableCell>
                
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {buyer.rootUserName}
                                    </TableCell>
                
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {buyer.email}
                                    </TableCell>

                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        {buyer.phone}
                                    </TableCell>
                                    
                                    <TableCell classes={{ root: classes.tableCellRoot }}>
                                        <Box paddingTop='.35rem' paddingBottom='.35rem'>
                                            <Box
                                                marginRight='10px'
                                                component='i'
                                                width='.375rem'
                                                height='.375rem'
                                                borderRadius='50%'
                                                display='inline-block'
                                                className={
                                                    classes.verticalAlignMiddle +
                                                    ' ' +
                                                    classes[buyer.confirmed ? 'bgSuccess' : 'bgWarning']
                                                }
                                            ></Box>
                                            {buyer.confirmed ? 'active' : 'inactive'}
                                        </Box>
                                    </TableCell>
                                    
                                    <TableCell
                                        classes={{ root: classes.tableCellRoot }}
                                        align='right'
                                    >
                                        <DropdownComponent 
                                            id={key} 
                                            openCopy={() => openViewCopyModal(buyer)}
                                            openBuyer={() => openBuyerModal(buyer)}
                                            activateBuyer={() => activateBuyer(buyer)}
                                            deactivateBuyer={() => deactivateBuyer(buyer)}
                                            confirmed={buyer.confirmed}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Box>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                        <Loader />
                    </Box>
                )}
            </TableContainer>

            {showViewCopyModal &&
                <ViewCopyModal
                    isOpen={showViewCopyModal}
                    close={() => closeViewCopyModal()}
                    buyer={buyer}
                />
            }

            {showBuyerModal &&
                <BuyerModal
                    isOpen={showBuyerModal}
                    close={() => closeBuyerModal()}
                    buyer={buyer}
                />
            }
        </Card>
    </>
    )
}

const DropdownComponent = ({ id, openCopy, openBuyer, activateBuyer, deactivateBuyer, confirmed }) => {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState(null)
    const [showWarningModal, setShowWarningModal] = useState(false)
    const [warningText, setWarningText] = useState(null)
    const [warningAction, setWarningAction] = useState(null)
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    
    const handleClose = () => {
        setAnchorEl(null)
    }

    function openWarningModal(action, text) {
        setAnchorEl(null)
        setWarningAction(action)
        setWarningText(text)
        setShowWarningModal(true)
    }

    function openViewCopyModal() {
        setAnchorEl(null)
        openCopy()
    }

    function openBuyerModal() {
        setAnchorEl(null)
        openBuyer()
    }

    function action() {
        setShowWarningModal(false)
        if (warningAction === 'activate') {
            activateBuyer()
        }
        if (warningAction === 'deactivate') {
            deactivateBuyer()
        }
    }
    
    return (
        <>
            <Box
                aria-controls={'simple-menu-' + id}
                aria-haspopup='true'
                onClick={handleClick}
                size='small'
                component={Button}
                width='2rem!important'
                height='2rem!important'
                minWidth='2rem!important'
                minHeight='2rem!important'
            >
                <Box
                    component={MoreVert}
                    width='1.25rem!important'
                    height='1.25rem!important'
                    position='relative'
                    top='2px'
                    color={theme.palette.gray[500]}
                />
            </Box>
            <Menu
                id={'simple-menu-' + id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {/*<MenuItem onClick={() => console.log('view buyer orders')}>View Orders</MenuItem>*/}
                <MenuItem onClick={() => openViewCopyModal()}>View Copy Upload</MenuItem>
                {confirmed ? (
                    <MenuItem onClick={() => openWarningModal('deactivate', 'Once you deactivate this account the buyer will be notified and will no longer be able to place orders.')}>Deactivate Account</MenuItem>
                ) : (
                    <MenuItem onClick={() => openWarningModal('activate', 'Once you activate this account the buyer will be notified and will be able to log in and place orders.')}>Activate Account</MenuItem>
                )}
            </Menu>

            <WarningModal 
                isOpen={showWarningModal}
                close={() => setShowWarningModal(false)}
                primaryText={warningText}
                onContinue={() => action()}
            />
        </>
    )
}
