import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import ReactDropzone from 'components/general/ReactDropzone'
import { Storage } from 'aws-amplify'
import RichEditor from 'components/general/RichEditor'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />
})

export default function PublisherModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()

	const publisher = props.publisher
	console.log(publisher)

	const [name, setName] = useState(publisher.name)
  	const [email, setEmail] = useState(publisher.email)
  	const [phone, setPhone] = useState(publisher.phone)
  	
  	const [logo, setLogo] = useState(null)
  	const [logoUpdated, setLogoUpdated] = useState(false)

  	const [description, setDescription] = useState(publisher.description || '')
  	const [radioCopy, setRadioCopy] = useState(publisher.copy ? publisher.copy.radio : '')
  	const [televisionCopy, setTelevisionCopy] = useState(publisher.copy ? publisher.copy.television : '')
  	const [outdoorCopy, setOutdoorCopy] = useState(publisher.copy ? publisher.copy.outdoor : '')
  	const [pressCopy, setPressCopy] = useState(publisher.copy ? publisher.copy.press : '')
  	const [digitalCopy, setDigitalCopy] = useState(publisher.copy ? publisher.copy.digital : '')
  	const [sponsorshipCopy, setSponsorshipCopy] = useState(publisher.copy ? publisher.copy.sponsorship : '')

  	const [loading, setLoading] = useState(true)

  	useEffect(() => {
        getLogo()
    }, [])

    async function getLogo() {
        let src = await Storage.get(publisher.logo)
        console.log(src)
        setLogo(src)
        setLoading(false)
    }

    function updateLogo(logo) {
    	setLogo(logo)
    	setLogoUpdated(true)
    }

  	function updatePublisher() {
  		// check all fields are vaild
  		let copy = {
  			radio: radioCopy,
  			television: televisionCopy,
  			outdoor: outdoorCopy,
  			press: pressCopy,
  			digital: digitalCopy,
  			sponsorship: sponsorshipCopy
  		}
  		if (logoUpdated) {
  			props.updatePublisher(publisher.publisherId, name, email, phone, description, logo, copy)
  		}
  		else {
  			props.updatePublisher(publisher.publisherId, name, email, phone, description, null, copy)
  		}
  	}

  	function checkFields() {
  		if (name.length > 0 && email.length > 0 && phone.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
			maxWidth='md'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{publisher.name}
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				<FormLabel>Logo</FormLabel>
				<div className='add-logo-dropzone'>
					{!loading &&
						<ReactDropzone 
							logo={logo}
							updateLogo={(logo) => updateLogo(logo)}
						/>
					}
				</div>

				<FormGroup>
                  	<FormLabel>Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='RTE'
                    	value={name}
                    	onChange={(e) => setName(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Primary Email</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='email'
                    	placeholder='admatic@example.com'
                    	value={email}
                    	onChange={(e) => setEmail(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Primary Phone</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='phone'
                    	placeholder='+353872234415'
                    	value={phone}
                    	onChange={(e) => setPhone(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                  	<FormLabel>Description (Visible on Media Catalog page)</FormLabel>
                  	<RichEditor
                  		value={description}
                  		onChange={setDescription}
                  		placeholder='This publisher offers...'
                  		product
                  	/>
                </FormGroup>
				{props.type === 'bb' ? (
					<>
					
					</>
				) : (
					<>
						<FormGroup>
							<FormLabel>Radio Copy (Visible on Media Catalog page)</FormLabel>
							<RichEditor
								value={radioCopy} 
								onChange={setRadioCopy} 
								placeholder='In terms of radio this publisher offers...'
								product
							/>
						</FormGroup>
						<FormGroup>
							<FormLabel>Television Copy (Visible on Media Catalog page)</FormLabel>
							<RichEditor
								value={televisionCopy} 
								onChange={setTelevisionCopy} 
								placeholder='In terms of television this publisher offers...'
								product
							/>
						</FormGroup>
						<FormGroup>
							<FormLabel>Outdoor Copy (Visible on Media Catalog page)</FormLabel>
							<RichEditor
								value={outdoorCopy} 
								onChange={setOutdoorCopy} 
								placeholder='In terms of outdoor this publisher offers...'
								product
							/>
						</FormGroup>
						<FormGroup>
							<FormLabel>Press Copy (Visible on Media Catalog page)</FormLabel>
							<RichEditor
								value={pressCopy} 
								onChange={setPressCopy} 
								placeholder='In terms of press this publisher offers...'
								product
							/>
						</FormGroup>
						<FormGroup>
							<FormLabel>Digital Copy (Visible on Media Catalog page)</FormLabel>
							<RichEditor
								value={digitalCopy} 
								onChange={setDigitalCopy} 
								placeholder='In terms of digital this publisher offers...'
								product
							/>
						</FormGroup>
						<FormGroup>
							<FormLabel>Sponsorship Copy (Visible on Media Catalog page)</FormLabel>
							<RichEditor
								value={sponsorshipCopy} 
								onChange={setSponsorshipCopy}
								placeholder='In terms of sponsorship this publisher offers...'
								product
							/>
						</FormGroup>	
					</>
				)}
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Close
				</Button>
			
				<Button
					component={Box}
					onClick={() => updatePublisher()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Save Changes
				</Button>
			</DialogActions>
		</Dialog>
	)
}