import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
//import Card from '@material-ui/core/Card'
//import CardContent from '@material-ui/core/CardContent'
import Checkbox from '@material-ui/core/Checkbox'
//import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Email from '@material-ui/icons/Email'
import Lock from '@material-ui/icons/Lock'
import Phone from '@material-ui/icons/Phone'
import Person from '@material-ui/icons/Person'
import Business from '@material-ui/icons/Business'
//import CheckCircle from '@material-ui/icons/CheckCircle'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'
//import { useHistory } from 'react-router-dom'
//import guestClient from 'config/api/GuestClient'

// core components
//import AuthHeader from 'components/argon/Headers/AuthHeader.js'
import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'

import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles(componentStyles)
const useStylesButtons = makeStyles(componentStylesButtons)

function RegisterForm(props) {
    const classes = { ...useStyles(), ...useStylesButtons() }
    const theme = useTheme()
    const buyerApi = new buyerClient()

    const [stage, setStage] = useState('register')
    const [name, setName] = useState('')
    const [business, setBusiness] = useState('')
    const [email, setEmail] = useState('')
    const [countryCode, setCountryCode] = useState('+353')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [termsConditions, setTermsConditions] = useState(false)
    const [confirmationCode, setConfirmationCode] = useState('')

    async function register() {
        let formattedEmail = email.toLowerCase()
        console.log('formatted email: ', formattedEmail)
        let formattedPhone = countryCode + phone.replace(/^0+/, '')
        console.log('formatted phone: ', formattedPhone)

        let cognitoData = {
        	'username': formattedEmail, 
			'password': password,
			'attributes': {
				'name': name,
				'phone_number': formattedPhone,
				'custom:account_type': 'buyer',
				'custom:businessName': business.length > 0 ? business : name,
				'custom:businessId': uuidv4(),
                'custom:permissions': 'admin'
			}
        }

        try {
        	console.log('register: ', cognitoData)
            let user = await Auth.signUp(cognitoData)
            console.log('user: ', user)
            setStage('confirm')
        } catch(e) {
            onError(e)
        }
    }

    function checkFields() {
        if (name.length > 0 && email.length > 0 && phone.length > 0 && password.length > 0 && termsConditions) {
            return false
        }
        else {
            return true
        }
    }

    async function confirmAccount() {
        let formattedEmail = email.toLowerCase()
        if (confirmationCode.length > 0) {
            try {
                await Auth.confirmSignUp(formattedEmail, confirmationCode)
                //await api.sendWelcomeEmail(email)
                // go to logged in version of B&B at payment stage with all order info from URL param?
                await signIn()
                const info = await Auth.currentUserInfo()
                console.log('info: ', info)
                //let accountType = info ? info.attributes['custom:account_type'] : null
                let name = info ? info.attributes['name'] : null
                //let email = info ? info.attributes['email'] : null
                let phone = info ? info.attributes['phone_number'] : null
                let businessName = info ? info.attributes['custom:businessName'] : null
                let businessId = info ? info.attributes['custom:businessId'] : null
                let permissions = info ? info.attributes['custom:permissions'] : null
                let details = await buyerApi.addUserDetails(name, formattedEmail, phone, businessName, businessId, permissions, false)
                await buyerApi.addBusinessDetails(businessId, businessName, info.id, name, formattedEmail, phone)
                props.placeOrder(details)
            } catch(e) {
                console.log(e)
                if (e.code === 'CodeMismatchException') {
                    NotificationManager.error('Invalid code, please ensure you have entered the correct code')
                }
                onError(e)
            }
        }
        else {
            NotificationManager.error('Please enter the code that was sent to ' + phone)
        }
    }

    async function resendCode() {
        try {
            await Auth.resendSignUp(props.email)
            NotificationManager.success('SMS sent')
        } catch(e) {
            console.log(e.code)
            onError(e)
        }
    }

    async function logIn() {
        try {
            await signIn()
            const info = await Auth.currentUserInfo()
            console.log('info: ', info)
            let details = await buyerApi.getUserDetails(info.attributes['custom:businessId'])
            props.placeOrder(details)
        } catch(e) {
            onError(e)
        }
    }

    async function signIn() {
        let formattedEmail = email.toLowerCase()
        try {
            const user = await Auth.signIn(formattedEmail, password)
            console.log('user: ', user)
            return true
        } catch(e) {
            onError(e)
            return false
        }
    }

    function showLogin(e) {
        e.preventDefault()
        setStage('login')
    }
    
    return (
        <>
        {stage === 'register' &&
            <Box>
                <Box
                    //color={theme.palette.gray[600]}
                    textAlign='center'
                    marginBottom='1rem'
                    marginTop='.5rem'
                    fontSize='1rem'
                >
                    <Box fontSize='80%' fontWeight='400' component='small'>
                        Enter your details below
                    </Box>
                </Box>

                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <FilledInput
                        autoComplete='off'
                        type='text'
                        placeholder='Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        startAdornment={
                            <InputAdornment position='start'>
                                <Person />
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <FilledInput
                        autoComplete='off'
                        type='text'
                        placeholder='Company Name (Optional)'
                        value={business}
                        onChange={(e) => setBusiness(e.target.value)}
                        startAdornment={
                            <InputAdornment position='start'>
                                <Business />
                            </InputAdornment>
                        }
                    />
                </FormControl>
        
                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <FilledInput
                        autoComplete='off'
                        type='email'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        startAdornment={
                            <InputAdornment position='start'>
                                <Email />
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <Grid container>
                        <Grid item xs={4}>
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Country'
                                value={countryCode}
                                disabled={true}
                                onChange={(e) => setCountryCode(e.target.value)}
                                startAdornment={
                                    <InputAdornment position='start'>
                                        <Phone />
                                    </InputAdornment>
                                }
                            />
                        </Grid>
                        <Grid item xs={8}>
                        <FilledInput
                            autoComplete='off'
                            type='text'
                            placeholder='Phone'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        </Grid>
                    </Grid>
                </FormControl>
                <Box
                    fontStyle='italic'
                    fontSize='1rem'
                    color={theme.palette.gray[600]}
                    marginBottom='1rem'
                >
                    <Box component='small' fontSize='80%'>
                        Please ensure this number is correct as we will send you an SMS to verify your account
                    </Box>
                </Box>
        
                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <FilledInput
                        autoComplete='off'
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        startAdornment={
                            <InputAdornment position='start'>
                                <Lock />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Box
                    fontStyle='italic'
                    fontSize='1rem'
                    color={theme.palette.gray[600]}
                    marginBottom='1rem'
                >
                    <Box component='small' fontSize='80%'>
                        Password must have at least 8 characters with an uppercase letter, lowercase letter, and a number
                    </Box>
                </Box>
                
                <FormControlLabel
                    value='end'
                    control={
                        <Checkbox 
                            color='primary'  
                            checked={termsConditions} 
                            onChange={() => setTermsConditions(!termsConditions)} 
                        />
                    }
                    label={
                        <>
                            I agree with the{' '}
                            <Box
                                color={theme.palette.primary.main}
                                component='a'
                                textDecoration='none'
                            >
                                Privacy Policy
                            </Box>
                            {' '}and{' '}
                            <Box
                                color={theme.palette.primary.main}
                                component='a'
                                textDecoration='none'
                            >
                                Terms & Conditions
                            </Box>
                        </>
                    }
                    labelPlacement='end'
                    classes={{
                        root: classes.formControlLabelRoot,
                        label: classes.formControlLabelLabel,
                    }}
                />
                
                <Box
                    textAlign='center'
                    marginTop='1.5rem'
                    marginBottom='1.5rem'
                >
                    <Button
                        variant='contained'
                        classes={{ root: classes.buttonContainedInfo }}
                        onClick={() => register()}
                        disabled={checkFields()}
                    >
                        Next Step
                    </Button>
                </Box>

                <Grid container component={Box} marginTop='1rem'>
                    <Grid item xs={12} component={Box} textAlign='center'>
                        <a
                            href='#'
                            onClick={(e) => showLogin(e)}
                            className='hover-text payment-status'
                            style={{fontSize: '80%', opacity: '0.8'}}
                        >
                            Already have an account? Sign In
                        </a>
                    </Grid>
                </Grid>
                
                {/*
                <Button
                    variant='contained'
                    classes={{ root: classes.buttonContainedInfo }}
                    onClick={() => testProps()}
                >
                    Test props
                </Button>
                */}
            </Box>
        }
        {stage === 'confirm' &&
            <Box>
                <Box
                    //color={theme.palette.gray[600]}
                    textAlign='center'
                    marginBottom='1rem'
                    marginTop='.5rem'
                    fontSize='1rem'
                >
                    <Box fontSize='80%' fontWeight='400' component='small'>
                        Enter your confirmation code (sent to {phone}) below
                    </Box>
                </Box>

                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <FilledInput
                        autoComplete='off'
                        type='text'
                        placeholder='Confirmation Code'
                        value={confirmationCode}
                        onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                </FormControl>
                
                <Box
                    textAlign='center'
                    marginTop='1.5rem'
                    marginBottom='1.5rem'
                >
                    <Button
                        variant='contained'
                        classes={{ root: classes.buttonContainedInfo }}
                        onClick={() => resendCode()}
                        style={{marginRight: '1rem'}}
                    >
                        Resend Code
                    </Button>
                    <Button
                        variant='contained'
                        classes={{ root: classes.buttonContainedInfo }}
                        onClick={() => confirmAccount()}
                    >
                        Confirm Account
                    </Button>
                </Box>
            </Box>
        }
        {stage === 'login' &&
            <Box>
                <Box
                    //color={theme.palette.gray[600]}
                    textAlign='center'
                    marginBottom='1rem'
                    marginTop='.5rem'
                    fontSize='1rem'
                >
                    <Box fontSize='80%' fontWeight='400' component='small'>
                        Sign in with email & password
                    </Box>
                </Box>

                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <FilledInput
                        autoComplete='off'
                        type='email'
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        startAdornment={
                            <InputAdornment position='start'>
                                <Email />
                            </InputAdornment>
                        }
                    />
                </FormControl>
        
                <FormControl
                    variant='filled'
                    component={Box}
                    width='100%'
                    marginBottom='1rem!important'
                >
                    <FilledInput
                        autoComplete='off'
                        type='password'
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        startAdornment={
                            <InputAdornment position='start'>
                                <Lock />
                            </InputAdornment>
                        }
                    />
                </FormControl>
                
                <Box
                    textAlign='center'
                    marginTop='1.5rem'
                    marginBottom='1.5rem'
                >
                    <Button
                        variant='contained'
                        classes={{ root: classes.buttonContainedInfo }}
                        onClick={() => logIn()}
                    >
                        Sign in
                    </Button>
                </Box>
            </Box>
        }
        </>
    )
}

export default RegisterForm