import React, { useState, useEffect } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'
import AdminNavbarAlternative from 'components/argon/Navbars/AdminNavbarAlternative.js'
import Sidebar from 'components/argon/Sidebar/Sidebar.js'
import Box from '@material-ui/core/Box'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PublishersIcon from '@material-ui/icons/LiveTv'
import OrdersIcon from '@material-ui/icons/ListAlt'
import BuyersIcon from '@material-ui/icons/Business'
//import ContentIcon from '@material-ui/icons/Web'
//import MediaIcon from '@material-ui/icons/CloudUpload'
import NotificationsIcon from '@material-ui/icons/Notifications'
import NewspaperIcon from '@material-ui/icons/CreateNewFolder'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/admin.js'

import Dashboard from 'scenes/admatic/Dashboard'
import Orders from 'scenes/admatic/Orders'
import Publishers from 'scenes/admatic/Publishers'
import PublisherProducts from 'scenes/admatic/PublisherProducts'
import BookAndBuy from 'scenes/admatic/BookAndBuy'
import Buyers from 'scenes/admatic/Buyers'
//import Content from 'scenes/admatic/Content'
//import Media from 'scenes/admatic/Media'
import Notifications from 'scenes/admatic/Notifications'
import Dropdowns from 'scenes/admatic/Dropdowns'

const useStyles = makeStyles(componentStyles)

const Main = () => {
    const classes = useStyles()
    const location = useLocation()
    const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false)

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        // mainContent.current.scrollTop = 0
    }, [location])

    const routes = [
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: DashboardIcon,
            iconColor: 'Success',
            component: Dashboard,
        },
        {
            path: '/orders',
            name: 'Orders',
            icon: OrdersIcon,
            iconColor: 'Success',
            component: Orders,
        },
        {
            path: '/book-and-buy',
            name: 'Book & Buy',
            icon: NewspaperIcon,
            iconColor: 'Success',
            component: BookAndBuy,
        },
        {
            path: '/publishers',
            name: 'Publishers',
            icon: PublishersIcon,
            iconColor: 'Success',
            component: Publishers,
        },
        {
            path: '/buyers',
            name: 'Buyers',
            icon: BuyersIcon,
            iconColor: 'Success',
            component: Buyers,
        },
        // {
        //     path: '/content',
        //     name: 'Content',
        //     icon: ContentIcon,
        //     iconColor: 'Success',
        //     component: Content,
        // },
        // {
        //     path: '/media',
        //     name: 'Media',
        //     icon: MediaIcon,
        //     iconColor: 'Success',
        //     component: Media,
        // },
        {
            path: '/notifications',
            name: 'Notifications',
            icon: NotificationsIcon,
            iconColor: 'Success',
            component: Notifications,
        },
        {
            path: '/dropdowns',
            name: 'Dropdowns',
            icon: BuyersIcon,
            iconColor: 'Success',
            component: Dropdowns,
        },
    ]

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views)
            } 
            else {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                )
            }
        });
    };

    return (
        <>
            <Box display='flex'>
                <Sidebar
                    routes={routes}
                    openResponsive={sidebarOpenResponsive}
                    closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
                    logo={{
                        innerLink: '/index',
                        imgSrc: require('../../resources/images/logo-black-800.png').default,
                        imgAlt: '...',
                        className: 'sidebar-logo',
                    }}
                />
                <Box position='relative' flex='1' className={classes.mainContent}>
                    <AdminNavbarAlternative
                      openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                    />
                    
                    <Switch>
                        {getRoutes(routes)}
                        <Route path='/publisher/:publisherId' component={PublisherProducts} />
                        <Redirect from='*' to='/dashboard' />
                    </Switch>
                </Box>
            </Box>
        </>
    )
}

export default Main