import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import moment from 'moment'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesButton from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesButton = makeStyles(componentStylesButton)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function OrderModal(props) {
	const classes = {...useStyles(), ...useStylesDialog(), ...useStylesButton()}
	const api = new admaticClient()

	const [payments, setPayments] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
        getPaymentRequestPayments()
    }, [])

	async function getPaymentRequestPayments() {
    	// if payment code but no payment uuid then show complete payment button
    	console.log('payment request code: ', props.paymentRequestCode)
    	if (props.paymentRequestCode) {
	    	try {
	    		const result = await api.getPaymentRequestPayments(props.paymentRequestCode)
	    		console.log('payments: ', result)

	    		setPayments(result.payments.pisPaymentRequestPayments)
	    		setLoading(false)
	    	} catch(e) {
	    		onError(e)
	    	}
    	}
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Payment Attempts
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				{!loading ? (
					<Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                    alignItems='flex-start'
	                    justify='flex-start'
	                    style={{marginTop: '30px'}}
	                >
	                	{payments.map((payment) => {
	                		return (
	                			<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Status: {payment.status}
				                    </Box>
				                    <Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Date Created: {moment(payment.dateCreated).format('DD/MM/YY HH:mm')}
				                    </Box>
			                	</Grid>
	                		)
	                	})}
	                </Grid>
				) : (
					<Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader />
                    </Box>
				)}
			</DialogContent>
			
			<DialogActions style={{justifyContent: 'space-between'}}>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}