//import 'react-app-polyfill/ie11'
//import 'react-app-polyfill/stable'
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Authenticator } from 'aws-amplify-react'
import Amplify from 'aws-amplify'
import config from './config/api/Config'
//import ErrorBoundary from 'components/general/ErrorBoundary'
import { initSentry } from './config/lib/errorLib'

// maybe move to router
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from 'assets/theme/theme.js'

// plugins styles from node_modules
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@fullcalendar/common/main.min.css'
import '@fullcalendar/daygrid/main.min.css'
import 'quill/dist/quill.core.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
// plugins styles downloaded
import 'assets/plugins/nucleo/css/nucleo.css'
// core styles
import 'assets/scss/argon-dashboard-pro-material-ui.scss?v1.0.0'

// notifications css
import 'react-notifications/lib/notifications.css'

// custom css
import 'css/Custom.css'

Amplify.configure({
    Auth: {
        mandatorySignIn: false,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID
    },
    API: {
        endpoints: [
            {
                name: config.apiGatewayGuest.PATH,
                endpoint: config.apiGatewayGuest.URL,
                region: config.apiGatewayGuest.REGION
            },
            {
                name: config.apiGatewayBuyer.PATH,
                endpoint: config.apiGatewayBuyer.URL,
                region: config.apiGatewayBuyer.REGION
            },
            {
                name: config.apiGatewayAdmatic.PATH,
                endpoint: config.apiGatewayAdmatic.URL,
                region: config.apiGatewayAdmatic.REGION
            },
        ]
    },
    Storage: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
    }
})

// initialize sentry in production
initSentry()

class AppWithAuth extends Component {
    render() {
        return (
            // Put the App component inside the authenticator component and only render once correct authState is reached
            <Authenticator hideDefault={true}>
                <App />
            </Authenticator>
        );
    }
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
            <AppWithAuth />
        </BrowserRouter>
    </ThemeProvider>, document.getElementById('root')
);