const isLocal = process.env.NODE_ENV === 'development'

const config =  {
    apiGatewayGuest: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://lrvkjsyle6.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://zove5i5ixl.execute-api.eu-west-1.amazonaws.com/prod/',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'guest-api',
    },
    apiGatewayBuyer: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://qyyxwmy3z1.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://abhc3dbho8.execute-api.eu-west-1.amazonaws.com/prod/',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'buyer-api',
    },
    apiGatewayAdmatic: {
        REGION: 'eu-west-1',
        URL: isLocal ? 'https://7xxogj17ef.execute-api.eu-west-1.amazonaws.com/dev/' : 'https://3hxps220na.execute-api.eu-west-1.amazonaws.com/prod/',
        // PATH is just used to refer to api within the app when initialising and using amplify API
        PATH: 'admatic-api',
    },
    cognito: {
        REGION: 'eu-west-1',
        USER_POOL_ID: 'eu-west-1_eakoiJpPQ',
        APP_CLIENT_ID: '6hq34i34qv6m3cutqj4npka93j',
        IDENTITY_POOL_ID: 'eu-west-1:6f70a231-7ae6-4208-9500-728ebfcd4726'
    },
    s3: {
        REGION: 'eu-west-1',
        BUCKET: 'admatic-user-upload'
    },
    SENTRY_DSN: 'https://20b66f34b98940988a30828e43611732@o290751.ingest.sentry.io/5958977',
    stripeKey: isLocal ? 'pk_test_51JwoA0G6tjJmVuRmIJNr3ozrN8GCUMD9jxBU4jKERyaK0Jn9ZeJVxclRPEYkC67gMMtUPgS3785azeVUFG3z5x4k00y1hsO6Bi' : 'pk_live_51JwoA0G6tjJmVuRmlBWoXO7KgFibuFniRMuGLiAPnARA2eyrJtumFbhwZNZl7oO9c3sJw1ZG0fmhleB2uzfnAUV300Nc2lyX6C'
};
export default config