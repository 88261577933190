import moment from 'moment'

export function getTotalPrice(startDate, endDate, frequency, days, price, agencyPrice) {
	let quantity = 0
	let dayCount = 0

	for (var m = moment(startDate); m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
	  	console.log(m.format('dddd'))
	  	if (days.indexOf(m.format('dddd')) > -1) {
	  		quantity++
	  	}
	  	dayCount++
	}
	console.log('daily quantity: ', quantity)
	console.log('weekly quantity: ', parseInt((dayCount/7).toFixed()))
	console.log('monthly quantity: ', parseInt((dayCount/28).toFixed()))

	if (frequency === 'Weekly') {
		quantity = parseInt((dayCount/7).toFixed())
	}
	if (frequency === 'Monthly') {
		quantity = parseInt((dayCount/28).toFixed())
	}

	let total = quantity * price
	let agencyTotal = quantity * agencyPrice

	return {
		total: total,
		agencyTotal: agencyTotal
	}
}

export function getSite(companyId) {
	let site = 'Admatic'
	if (companyId === 'gi') {
		site = 'Guaranteed Irish'
	}
	return site
}