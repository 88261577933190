import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
//import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
// @material-ui/icons components

// core components

import componentStyles from "assets/theme/components/cards/dashboard/card-social-traffic.js";

const useStyles = makeStyles(componentStyles);

function OrdersByPublisherCard() {
  const classes = useStyles();
  return (
    <>
      <Card classes={{ root: classes.cardRoot }}>
        <CardHeader
          subheader={
            <Grid
              container
              component={Box}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs="auto">
                <Box
                  component={Typography}
                  variant="h3"
                  marginBottom="0!important"
                >
                  Orders by publisher
                </Box>
              </Grid>
              <Grid item xs="auto">
                <Box justifyContent="flex-end" display="flex" flexWrap="wrap">
                  <Button variant="contained" color="primary" size="small">
                    See all
                  </Button>
                </Box>
              </Grid>
            </Grid>
          }
          classes={{ root: classes.cardHeaderRoot }}
        ></CardHeader>
        <TableContainer>
          <Box component={Table} alignItems="center" marginBottom="0!important">
            <TableHead>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  Orders
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.tableCellRootHead,
                  }}
                >
                  Value
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  RTE
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  48
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  €87,000
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  Virgin Media
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  37
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  €56,450
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  The Irish Times
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  32
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  €55,900
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  Dublin's 98FM
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  21
                </TableCell>
                <TableCell classes={{ root: classes.tableCellRoot }}>
                  €28,100
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot +
                      " " +
                      classes.tableCellRootBodyHead +
                      " " +
                      classes.borderBottomUnset,
                  }}
                  component="th"
                  variant="head"
                  scope="row"
                >
                  The Irish Sun
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.borderBottomUnset,
                  }}
                >
                  20
                </TableCell>
                <TableCell
                  classes={{
                    root:
                      classes.tableCellRoot + " " + classes.borderBottomUnset,
                  }}
                >
                  €16,920
                </TableCell>
              </TableRow>
            </TableBody>
          </Box>
        </TableContainer>
      </Card>
    </>
  );
}

export default OrdersByPublisherCard