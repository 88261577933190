import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
    toolbar: [
        //[{ 'header': [1, 2, false] }],
        //['bold', 'italic', 'underline'],
        //[{'list': 'ordered'}, {'list': 'bullet'}],
        //[{ align: '' }, { align: 'center' }, { align: 'right' }],
        //['link', 'image'],
        ['link'],
        ['clean']
    ],
    //ImageResize: {}
}

const productModules = {
    toolbar: [
        //[{ 'header': [1, 2, false] }],
        //['bold', 'italic', 'underline'],
        //[{'list': 'ordered'}, {'list': 'bullet'}],
        ['clean']
    ],
    //ImageResize: {}
}

function RichEditor(props) {
	return (
		<ReactQuill 
            theme='snow' 
            modules={props.product ? productModules : modules}
            value={props.value} 
            onChange={props.onChange} 
            placeholder={props.placeholder}
        />
    )
}

export default RichEditor