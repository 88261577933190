import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import BuyersTable from 'components/admatic/BuyersTable'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'


import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Buyers() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new admaticClient()

    const [buyers, setBuyers] = useState([])
    const [loading, setLoading] = useState(true)

    // empty array argument prevents endless loop
    useEffect(() => {
        getBuyers()
    }, [])

    async function getBuyers() {
        try {
            const result = await api.getBuyers()

            if (result) {
                result.sort((a, b) => {
                    return b.createdAt - a.createdAt
                })
            }

            setBuyers(result)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }
    
    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <BuyersTable
                            buyers={buyers}
                            refreshBuyers={() => getBuyers()}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Buyers