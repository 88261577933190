import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Logo from 'components/general/Logo'
import DatePicker from 'components/general/DatePicker'
import PaymentAttemptsModal from 'components/admatic/PaymentAttemptsModal'
import moment from 'moment'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import MarkPaidModal from 'components/admatic/MarkPaidModal'
import { getSite } from 'config/helpers/Helpers'

import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import componentStylesButton from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)
const useStylesButton = makeStyles(componentStylesButton)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function OrderModal(props) {
	const classes = {...useStyles(), ...useStylesDialog(), ...useStylesButton()}
	const api = new admaticClient()

	const company = useContext(CompanyContext)
    console.log('company: ', company)

	const order = props.order
	console.log(order)

	const minDate = moment().add(3, 'days')

	const [stage, setStage] = useState('details')
	const [startDate, setStartDate] = useState(moment.unix(order.startDate))
	const [endDate, setEndDate] = useState(moment.unix(order.endDate))
	const [total, setTotal] = useState(order.total)
	const [rejectNote, setRejectNote] = useState('')

	const [showPaymentAttempts, setShowPaymentAttempts] = useState(false)
	const [showMarkPaid, setShowMarkPaid] = useState(false)

	const [paymentDetails, setPaymentDetails] = useState(null)
	const [loading, setLoading] = useState(true)
	const [alert, setAlert] = useState(false)

	useEffect(() => {
        getPaymentDetails()
    }, [])

	async function getPaymentDetails() {
    	if (order.paymentId) {
	    	try {
	    		const result = await api.getPayment(order.paymentId)
	    		console.log('payment details: ', result)

	    		if (result) {
	    			setPaymentDetails(result.payment)
	    		}
	    		setLoading(false)
	    	} catch(e) {
	    		onError(e)
	    	}
    	}
    }

	function rejectOrder() {
		console.log('reject order...')
		props.rejectOrder(rejectNote)
	}

	function approveOrder() {
		console.log('approve order...')
		props.approveOrder()
	}

	function approveUpdateOrder() {
		console.log('approve order...')
		props.approveUpdateOrder(startDate, endDate, total)
	}

	function checkPaymentType() {
		if (paymentDetails.paymentUuid) {
			setShowPaymentAttempts(true)
		}
	}

	function showApproveWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Warning'
                onConfirm={() => approveOrder()}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle='success'
                confirmBtnText='Approve Order'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                Approving this order will notify the buyer.
            </ReactBSAlert>
        )
    }

    function showRejectWarning() {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Warning'
                onConfirm={() => rejectOrder()}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle='warning'
                confirmBtnText='Reject Order'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                Rejecting this order will notify the buyer.
            </ReactBSAlert>
        )
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			{alert}
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{stage === 'details' &&
						<>Order Details</>
					}
					{stage === 'reject' &&
						<>Reject Order</>
					}
					{stage === 'edit' &&
						<>Edit Order</>
					}
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			{stage === 'details' &&
				<DialogContent>
					<Grid 
	                    container
	                    spacing={2}
	                    direction='row'
	                    alignItems='center'
	                    justify='space-evenly'
	                >
	                    <Grid item>
	                    	<Grid 
			                    container
			                    spacing={1}
			                    direction='column'
			                    alignItems='center'
			                    justify='center'
			                >
			                	<Grid item>
			                        <Logo 
			                            logo={order.user.buyerLogo}
			                            name={order.user.buyerName || order.user.email}
			                            size='70px'
			                            marginRight={'0'}
			                        />
		                        </Grid>
		                        <Grid item>
			                        <Box
			                            component={Typography}
			                            variant='h2'
			                        >
			                            <Box component='span'>{order.user.buyerName}</Box>
			                        </Box>
		                        </Grid>
		                        <Grid item>
			                        <Box
			                            component={Typography}
			                            variant='body2'
			                        >
			                            <Box component='span'>{order.user.email}</Box>
			                        </Box>
		                        </Grid>
		                        <Grid item>
			                        <Box
			                            component={Typography}
			                            variant='body2'
			                        >
			                            <Box component='span'>{order.user.phone}</Box>
			                        </Box>
		                        </Grid>
		                    </Grid>
	                    </Grid>
	                    <Grid item>
	                    	<Grid 
			                    container
			                    spacing={1}
			                    direction='column'
			                    alignItems='center'
			                    justify='center'
			                >
			                	<Grid item>
			                        <Logo 
			                            logo={order.publisher.logo}
			                            name={order.publisher.name}
			                            size='70px'
			                            marginRight={'0'}
			                        />
		                        </Grid>
		                        <Grid item>
			                        <Box
			                            component={Typography}
			                            variant='h2'
			                        >
			                            <Box component='span'>{order.publisher.name}</Box>
			                        </Box>
			                    </Grid>
			                    <Grid item>
			                        <Box
			                            component={Typography}
			                            variant='body2'
			                        >
			                            <Box component='span'>{order.publisher.email}</Box>
			                        </Box>
		                        </Grid>
		                        <Grid item>
			                        <Box
			                            component={Typography}
			                            variant='body2'
			                        >
			                            <Box component='span'>{order.publisher.phone}</Box>
			                        </Box>
		                        </Grid>
		                    </Grid>
	                    </Grid>
	                </Grid>

	                <Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                    alignItems='flex-start'
	                    justify='flex-start'
	                    style={{marginTop: '30px'}}
	                >
						<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Site: {getSite(order.companyId)}
		                    </Box>
	                	</Grid>
	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Booked By: {order.user.name}
		                    </Box>
	                	</Grid>
	                	
	                	<Grid item>
		                    <Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Category/Section: {order.product.category ? order.product.category : order.product.section}
		                    </Box>
	                	</Grid>

	                	<Grid item>
		                    <Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Product: {order.product.name}
		                    </Box>
	                	</Grid>

						{order.copy &&
							<Grid item>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='300'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									Title: {order.copy.title}
								</Box>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='300'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									Body: {order.copy.text}
								</Box>
							</Grid>
						}

	                	{order.variant &&
		                	<>
			                	<Grid item>
				                    <Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Variant: {order.variant.name}
				                    </Box>
			                	</Grid>
		                	</>
	                	}

	                	{/*
	                	<Grid item>
		                    <Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Description: {order.product.description}
		                    </Box>
	                	</Grid>
						*/}	                	

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                    	{order.variant ? (
		                    		<>Frequency: {order.variant.frequency} ({order.variant.days.join(', ')})</>
		                    	) : (
		                    		<>Frequency: {order.product.frequency} ({order.product.days.join(', ')})</>
		                    	)}
		                    </Box>
	                	</Grid>

	                	<Grid item>
		                    <Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Dates: {moment.unix(order.startDate).format('DD/MM/YY')} - {moment.unix(order.endDate).format('DD/MM/YY')}
		                    </Box>
	                	</Grid>

	                	{order.note &&
		                	<Grid item>
			                    <Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='300'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                    >
			                        Note: {order.note}
			                    </Box>
		                	</Grid>
		                }

	                	{order.subtotal &&
		                	<>
			                	<Grid item>
				                    <Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Subtotal: €{order.subtotal.toFixed(2)}
				                    </Box>
			                	</Grid>

			                	<Grid item>
				                    <Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        VAT (23%): €{order.vat.toFixed(2)}
				                    </Box>
			                	</Grid>
		                	</>
	                	}

	                	<Grid item>
		                    <Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Total: €{order.total.toFixed(2)}
		                    </Box>
	                	</Grid>

	                	{paymentDetails &&
		                	<Grid item>
			                    <Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='300'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                    >
			                        {!loading &&
			                        	<Box onClick={() => checkPaymentType()} className='payment-status hover-text'>Payment Status: {paymentDetails.status}</Box>
			                    	}
			                    </Box>
		                	</Grid>
	                	}

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Order Status: {order.status}
		                    </Box>
	                	</Grid>

	                	{order.rejectNote &&
		                	<Grid item>
			                    <Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='300'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                        color='#f5365c'
			                    >
			                        Rejection Reason: {order.rejectNote}
			                    </Box>
		                	</Grid>
		                }

		                {order.admaticUser &&
		                	<Grid item>
			                    <Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='300'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                    >
			                        Approved/Reject By: {order.admaticUser.name}
			                    </Box>
		                	</Grid>
		                }
	                </Grid>
				</DialogContent>
			}

			{stage === 'edit' &&
				<DialogContent>
					<Box
                        component='p'
                        marginBottom='1rem'
                        fontWeight='300'
                        lineHeight='1.7'
                        fontSize='1rem'
                        //marginTop="1.5rem"
                        color={'#f5365c'}
                    >
                        The buyer must be contacted before any updates are made.
                    </Box>
                    <Box
                        component='p'
                        marginBottom='2rem'
                        fontWeight='300'
                        lineHeight='1.7'
                        fontSize='1rem'
                        //marginTop="1.5rem"
                        color={'#f5365c'}
                    >
                        Phone: {order.publisher.phone} | Email: {order.publisher.email}
                    </Box>

					<FormGroup>
	                  	<FormLabel>Start Date</FormLabel>
	                  	<DatePicker
	                  		placeholder='Campaign Start'
	                  		value={startDate}
	                  		onChange={(e) => setStartDate(e)}
	                  		minDate={minDate}
	                  		frequency={order.product.frequency ? order.product.frequency : order.variant.frequency}
	                  		startPicker
	                  	/>
	                </FormGroup>

	                <FormGroup>
	                  	<FormLabel>End Date</FormLabel>
	                  	<DatePicker
	                  		placeholder='Campaign End'
	                  		value={endDate}
	                  		onChange={(e) => setEndDate(e)}
	                  		minDate={minDate}
	                  		frequency={order.product.frequency ? order.product.frequency : order.variant.frequency}
	                  		endPicker
	                  		startDate={startDate}
	                  	/>
	                </FormGroup>

	                <FormGroup>
	                  	<FormLabel>Order Total</FormLabel>
	                  	<OutlinedInput
	                    	fullWidth
	                    	type='number'
	                    	placeholder='Order Total'
	                    	value={total}
	                    	onChange={(e) => setTotal(e.target.value)}
	                  	/>
	                </FormGroup>
				</DialogContent>
			}

			{stage === 'reject' &&
				<DialogContent>
					<FormGroup>
	                  	<FormLabel>Note</FormLabel>
	                  	<OutlinedInput
	                    	fullWidth
	                    	multiline
	                    	rows='5'
	                    	type='text'
	                    	placeholder='Your order was rejected due to...'
	                    	onChange={(e) => setRejectNote(e.target.value)}
	                  	/>
	                </FormGroup>
				</DialogContent>
			}
			
			<DialogActions style={{justifyContent: 'space-between'}}>
				{stage === 'details' &&
					<Button
						onClick={() => props.close()}
						color='primary'
					>
						Close
					</Button>
				}
				{stage === 'reject' &&
					<Button
						onClick={() => setStage('details')}
						color='primary'
					>
						Back
					</Button>
				}
				{stage === 'edit' &&
					<Button
						onClick={() => setStage('details')}
						color='primary'
					>
						Back
					</Button>
				}

				{(company.id === 'ad' && stage === 'details' && order.status === 'pending' && !loading && paymentDetails.status === 'pending') &&
					<Button
						component={Box}
						color='primary'
						variant='contained'
						onClick={() => setShowMarkPaid(true)}
						//marginLeft='auto!important'
					>
						Mark as paid
					</Button>
				}
				
				{(company.id === 'ad' && stage === 'details' && order.status === 'pending' && !loading && paymentDetails.status === 'paid') &&
 					<Box>
						<Button
							component={Box}
							variant='contained'
							classes={{
		                      	root: classes.buttonContainedError,
		                    }}
							style={{ marginRight: '0.5rem'}}
							onClick={() => setStage('reject')}
						>
							Reject
						</Button>
						{/*
						<Button
							component={Box}
							color='primary'
							variant='contained'
							style={{ marginRight: '0.5rem'}}
							onClick={() => setStage('edit')}
							//marginLeft='auto!important'
						>
							Edit
						</Button>
						 */}
						<Button
							component={Box}
							color='primary'
							variant='contained'
							onClick={() => showApproveWarning()}
							//marginLeft='auto!important'
						>
							Approve Now
						</Button>
					</Box>
				}
				{company.id === 'ad' && stage === 'reject' &&
					<Button
						component={Box}
						variant='contained'
						classes={{
	                      	root: classes.buttonContainedError,
	                    }}
						style={{ marginRight: '0.5rem'}}
						onClick={() => showRejectWarning()}
					>
						Reject
					</Button>
				}
				{company.id === 'ad' && stage === 'edit' &&
					<Button
						component={Box}
						color='primary'
						variant='contained'
						//marginLeft='auto!important'
						style={{ marginRight: '0.5rem'}}
						onClick={() => approveUpdateOrder()}
					>
						Approve with updates
					</Button>
				}
			</DialogActions>

			{showPaymentAttempts &&
				<PaymentAttemptsModal
                    isOpen={showPaymentAttempts}
                    close={() => setShowPaymentAttempts(false)}
                    paymentRequestCode={paymentDetails.paymentRequestCode}
                />
			}

			{showMarkPaid &&
				<MarkPaidModal
					isOpen={showMarkPaid}
                    close={() => setShowMarkPaid(false)}
                    openSuccessAlert={(text) => openSuccessAlert(text)}
                    payment={paymentDetails}
                    updatePaymentDetails={(payment) => setPaymentDetails(payment)}
					order={order}
				/>
			}
		</Dialog>
	)
}