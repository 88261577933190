import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
//import Checkbox from '@material-ui/core/Checkbox'
import Container from '@material-ui/core/Container'
import FilledInput from '@material-ui/core/FilledInput'
import FormControl from '@material-ui/core/FormControl'
//import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import Email from '@material-ui/icons/Email'
import Lock from '@material-ui/icons/Lock'
import ForgotPasswordModal from 'components/general/ForgotPasswordModal'
import { NotificationManager } from 'react-notifications'
import { Auth } from 'aws-amplify'
import { onError } from 'config/lib/errorLib'
import { useHistory } from 'react-router-dom'
import Loader from 'components/general/Loader'
import CompanyContext from 'config/context/CompanyContext'
import AdmaticLogo from 'resources/images/admatic-logo-updated.png'
import GiLogo from 'resources/images/gi-logo-full-bg.png'

// core components
import AuthHeader from 'components/argon/Headers/AuthHeader.js'
import componentStyles from 'assets/theme/views/auth/login.js'
import componentStylesButtons from 'assets/theme/components/button.js'

const useStyles = makeStyles(componentStyles)
const useStylesButtons = makeStyles(componentStylesButtons)

function Login() {
    const classes = { ...useStyles(), ...useStylesButtons() }
    const theme = useTheme()
    const history = useHistory()

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [loading, setLoading] = useState(false)

    async function signIn() {
        setLoading(true)
        let formattedEmail = email.toLowerCase()

        try {
            const user = await Auth.signIn(formattedEmail, password)
            console.log('user: ', user)

            window.location.replace('/')
        } catch(e) {
            if (e.code === 'UserNotConfirmedException') {
                //NotificationManager.error('You must confirm your account in order to log in. Please check your email for a confirmation link')
                history.push({
                    pathname: '/register',
                    state: {email: email}
                })
            }
            else if (e.code === 'UserNotFoundException') {
                NotificationManager.error('An account with this email address has not yet been registered.')
            }
            else if (e.code === 'NotAuthorizedException') {
                NotificationManager.error('The email address or password you have entered is incorrect')
            }
            else {
                onError(e)
            }
        }
        setLoading(false)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
		signIn()
    }

    function openForgotPasswordModal(e) {
        e.preventDefault()
        setShowForgotPassword(true)
    }

    function getLogo() {
        let logo = {
            img: AdmaticLogo,
            className: 'admatic-logo',
            alt: 'admatic-logo'
        }
        if(company.id === 'gi') {
            logo = {
                img: GiLogo,
                className: 'gi-logo',
                alt: 'guaranteed-irish-logo'
            }
        }  
        return logo
    }
  
    return (
        <>  
            <Box 
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{paddingTop: '5rem'}}
            >
                <img className={getLogo().className} src={getLogo().img} alt={getLogo().alt} />
            </Box>
            
            <AuthHeader
                title='Welcome!'
                description='Sign in below to browse publishers and start advertising.'
            />

            {/* Page content */}
          
            <Container
                component={Box}
                maxWidth='xl'
                marginTop='-12rem'
                paddingBottom='3rem'
                position='relative'
                zIndex='101'
            >
                <form onSubmit={handleSubmit}>
                    <Box component={Grid} container justifyContent='center'>
                        <Grid item xs={12} lg={5} md={7}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Box
                                        color={theme.palette.gray[600]}
                                        textAlign='center'
                                        marginBottom='1rem'
                                        marginTop='.5rem'
                                        fontSize='1rem'
                                    >
                                        <Box fontSize='80%' fontWeight='400' component='small'>
                                            Sign in with email and password
                                        </Box>
                                    </Box>
                            
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <FilledInput
                                            autoComplete='off'
                                            type='email'
                                            placeholder='Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            startAdornment={
                                                <InputAdornment position='start'>
                                                    <Email />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                            
                                    <FormControl
                                        variant='filled'
                                        component={Box}
                                        width='100%'
                                        marginBottom='1rem!important'
                                    >
                                        <FilledInput
                                            autoComplete='off'
                                            type='password'
                                            placeholder='Password'
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            startAdornment={
                                                <InputAdornment position='start'>
                                                    <Lock />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    
                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            //onClick={() => signIn()}
                                            type='submit'
                                            disabled={loading}
                                            className='step--login--success'
                                        >
                                            {!loading ? (
                                                <>Sign in</>
                                            ) : (
                                                <Loader height={12} color='white' />
                                            )}
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                            
                            <Grid container component={Box} marginTop='1rem'>
                                <Grid item xs={6} component={Box} textAlign='left'>
                                    <a
                                        href='#admui'
                                        onClick={(e) => openForgotPasswordModal(e)}
                                        className={classes.footerLinks + ' step--login-forgot'}
                                    >
                                        Forgot password
                                    </a>
                                </Grid>
                                
                                <Grid item xs={6} component={Box} textAlign='right'>
                                    <a
                                        href='/register'
                                        //onClick={(e) => e.preventDefault()}
                                        className={classes.footerLinks + ' step--login--register'}
                                    >
                                        Create new account
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
                {company.id === 'gi' &&
                    <Grid container style={{paddingBottom: '20px', paddingTop: '20px'}}>
                        <Grid item xs={12} component={Box} textAlign='center'>
                            <a
                                href='https://admatic.ie'
                                //onClick={(e) => e.preventDefault()}
                                className={classes.footerLinks + ' powered-by'}
                                style={{color: 'white'}}
                            >
                                Powered by admatic.ie
                            </a>
                        </Grid>
                    </Grid>
                }
            </Container>

            {showForgotPassword &&
                <ForgotPasswordModal 
                    isOpen={showForgotPassword}
                    close={() => setShowForgotPassword(false)}
                />
            }
        </>
    )
}

export default Login