import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
//import OutlinedInput from '@material-ui/core/OutlinedInput'
//import FormLabel from '@material-ui/core/FormLabel'
//import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import Parser from 'html-react-parser'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function AboutModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()

  	const publisher = props.publisher

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
			maxWidth='md'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					About
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				<Grid 
                    container
                    direction='column'
                >
                	<Grid item>
                        <Box
                            component={Typography}
                            variant='h2'
                            style={{marginBottom: '1rem'}}
                        >
                            {publisher.name}
                        </Box>
                    </Grid>

                    <Grid item>
                        <Box
                            marginBottom='1rem'
                            fontWeight='300'
                            lineHeight='1.7'
                            fontSize='1rem'
                            //marginTop="1.5rem"
                        >
                            {typeof(publisher.description) === 'string' ? Parser(publisher.description) : publisher.description}
                        </Box>
                    </Grid>

                    {publisher.copy[props.category.toLowerCase()].length > 0 &&
                    	<>
		                    <Grid item>
		                        <Box
		                            component={Typography}
		                            variant='h2'
		                            style={{marginBottom: '1rem'}}
		                        >
		                            {publisher.name + ' ' + props.category}
		                        </Box>
		                    </Grid>

		                    <Grid item>
		                        <Box
		                            marginBottom='1rem'
		                            fontWeight='300'
		                            lineHeight='1.7'
		                            fontSize='1rem'
		                            //marginTop="1.5rem"
		                        >
		                            {typeof(publisher.copy[props.category.toLowerCase()]) === 'string' ? Parser(publisher.copy[props.category.toLowerCase()]) : publisher.copy[props.category.toLowerCase()]}
		                        </Box>
		                    </Grid>
	                    </>
                	}
                </Grid>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}