import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import PublishersTable from 'components/admatic/PublishersTable'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'

import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Publishers() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new admaticClient()

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    const [publishers, setPublishers] = useState([])
    const [showPublishers, setShowPublishers] = useState([])
    const [status, setStatus] = useState('all')
    const [loading, setLoading] = useState(true)

    // empty array argument prevents endless loop
    useEffect(() => {
        getPublishers()
    }, [])

    async function getPublishers() {
        try {
            const result = await api.getPublishers(company.id)

            // list alphabetically
            if (result) {
                result.sort((a, b) => {
                    let nameA = a.name.toLowerCase()
                    let nameB = b.name.toLowerCase()
                    if(nameA < nameB) { return -1 }
                    if(nameA > nameB) { return 1 }
                    return 0
                })
            }

            setPublishers(result)
            setShowPublishers(result)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function updateStatus(status) {
        let newPublishers = []
        if (status === 'all') {
            newPublishers = publishers
        }
        if (status === 'active') {
            newPublishers = publishers.filter((publisher) => publisher.confirmed === true)
        }
        if (status === 'inactive') {
            newPublishers = publishers.filter((publisher) => publisher.confirmed === false)
        }
        setStatus(status)
        setShowPublishers(newPublishers)
    }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Grid
                            container
                            component={Box}
                            alignItems='center'
                            justifyContent='flex-start'
                            style={{marginBottom: '2rem'}}
                            direction='row'
                        >
                            <Grid item xs={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined' fullWidth>
                                        <Select
                                            multiple={false}
                                            IconComponent={KeyboardArrowDown}
                                            value={status}
                                            onChange={(e) => updateStatus(e.target.value)}
                                        >
                                            <MenuItem value='all'>All Publishers</MenuItem>
                                            <MenuItem value='active'>Active Publishers</MenuItem>
                                            <MenuItem value='inactive'>Inactive Publishers</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <PublishersTable 
                            publishers={showPublishers}
                            refreshPublishers={() => getPublishers()}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Publishers