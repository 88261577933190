import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import ContentCard from 'components/buyer/ContentCard'
import EnquiryForm from 'components/general/EnquiryForm'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Help() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={6} xl={6}>
                        <ContentCard
                            title={'Submit Enquiry'}
                            body={
                                <EnquiryForm type='general' />
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} xl={12}>
                        
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Help