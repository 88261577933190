import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import Container from '@material-ui/core/Container'
//import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import OutlinedInput from '@material-ui/core/OutlinedInput'
//import InputBase from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import UserHeader from 'components/argon/Headers/UserHeader.js'
//import TeamMembersCard from 'components/general/TeamMembersCard'
//import PaymentMethodsCard from 'components/general/PaymentMethodsCard'
import ChangePasswordModal from 'components/general/ChangePasswordModal'
import CompanyDetailsModal from 'components/buyer/CompanyDetailsModal'
import ReactDropzone from 'components/general/ReactDropzone'
import Loader from 'components/general/Loader'
import { Storage } from 'aws-amplify'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/profile.js'
import componentStylesCardImg from 'assets/theme/components/card-img.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardImg = makeStyles(componentStylesCardImg)

function Profile() {
    const classes = { ...useStyles(), ...useStylesCardImg() }
    const theme = useTheme()
    const api = new buyerClient()

    const details = useContext(DetailsContext)
    console.log(details)

    const [buyerDetails, setBuyerDetails] = useState({})
    const [email, setEmail] = useState(details.email || '')
    const [phone, setPhone] = useState(details.phone || '')
    const [logo, setLogo] = useState(null)
    const [loading, setLoading] = useState(true)
    const [showChangePassword, setShowChangePassword] = useState(false)
    const [showUpdateCompanyDetails, setShowUpdateCompanyDetails] = useState(false)
    const [alert, setAlert] = useState(false)

    useEffect(() => {
        getBuyerDetails()
    }, [])

    async function getBuyerDetails() {
        try {
            const buyerDetails = await api.getBuyerDetails(details.buyerId)
            console.log('buyerDetails: ', buyerDetails)

            const paymentMethods = await api.getPaymentMethods(buyerDetails.stripeCustomerId)
            console.log('payment methods: ', paymentMethods)

            let image = null

            try {
                image = await Storage.get(buyerDetails.logo)
                console.log(image)
            } catch(e) {
                onError(e)
            }
            
            setLogo(image)
            setBuyerDetails(buyerDetails)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    async function updateLogo(logo) {
        try {   
            if (logo) {
                let path = 'buyers/' + buyerDetails.buyerId
                try {
                    await Storage.put(path, logo, {
                        contentType: logo.type
                    })
                    setLogo(logo)
                } catch(e) {
                    onError(e)
                }
            }
        } catch(e) {
            onError(e)
        }
    }

    async function updateCompanyDetails(address, email, phone) {
        console.log('update')
        try {
            const buyerDetails = await api.updateBuyerDetails(details.buyerId, address, email, phone)
            console.log('buyerDetails: ', buyerDetails)
            
            setShowUpdateCompanyDetails(false)
            setBuyerDetails(buyerDetails.details)
            openSuccessAlert('Company details updated!')
        } catch(e) {
            onError(e)
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <UserHeader 
                details={details}
            />
            <Container
                maxWidth={false}
                component={Box}
                marginTop='-4.5rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        xl={7}
                        component={Box}
                        marginBottom='3rem'
                        classes={{ root: classes.gridItemRoot + ' ' + classes.order2 }}
                    >   
                        <Card
                            classes={{
                                root: classes.cardRoot,
                            }}
                        >
                            <CardHeader
                                subheader={
                                    <Grid
                                        container
                                        component={Box}
                                        alignItems='center'
                                        justifyContent='space-between'
                                    >
                                        <Grid item xs='auto'>
                                            <Box
                                                component={Typography}
                                                variant='h3'
                                                marginBottom='0!important'
                                            >
                                                My Account
                                            </Box>
                                        </Grid>
                                        
                                        <Grid item xs='auto'>
                                            <Box
                                                justifyContent='flex-end'
                                                display='flex'
                                                flexWrap='wrap'
                                            >
                                                <Button
                                                    variant='contained'
                                                    color='secondary'
                                                    size='small'
                                                    style={{marginRight: '0.5rem'}}
                                                    onClick={() => setShowChangePassword(true)}
                                                >
                                                    Change Password
                                                </Button>
                                                {details.permissions === 'admin' &&
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        size='small'
                                                        onClick={() => setShowUpdateCompanyDetails(true)}
                                                    >
                                                        Update Company Details
                                                    </Button>
                                                }
                                            </Box>
                                        </Grid>
                                    </Grid>
                                }
                                classes={{ root: classes.cardHeaderRoot }}
                            >
                            </CardHeader>
                            
                            <CardContent>
                                <Box
                                    component={Typography}
                                    variant='h6'
                                    color={theme.palette.gray[600] + '!important'}
                                    paddingTop='.25rem'
                                    paddingBottom='.25rem'
                                    fontSize='.75rem!important'
                                    letterSpacing='.04em'
                                    marginBottom='1.5rem!important'
                                    classes={{ root: classes.typographyRootH6 }}
                                >
                                    Company Information
                                </Box>
                                {!loading ? (
                                    <div>
                                        <Grid container>
                                            <Grid item xs={12} lg={12}>
                                                <FormGroup>
                                                    <FormLabel>Logo</FormLabel>
                                                    {details.permissions === 'admin' ? (
                                                        <div className='add-logo-dropzone'>
                                                            <ReactDropzone 
                                                                logo={logo}
                                                                updateLogo={(logo) => updateLogo(logo)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <img src={logo} className='add-logo-dropzone' />
                                                    )}
                                                </FormGroup>
                                            </Grid>

                                            <Grid item xs={12} lg={6}>
                                                <FormGroup>
                                                    <FormLabel>Company Name</FormLabel>
                                                    <FormControl
                                                        variant='filled'
                                                        component={Box}
                                                        width='100%'
                                                        marginBottom='1rem!important'
                                                    >
                                                        <OutlinedInput
                                                            fullWidth
                                                            type='text'
                                                            value={buyerDetails.name}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>

                                            <Grid item xs={12} lg={6}>
                                                <FormGroup>
                                                    <FormLabel>Company Address</FormLabel>
                                                    <FormControl
                                                        variant='filled'
                                                        component={Box}
                                                        width='100%'
                                                        marginBottom='1rem!important'
                                                    >
                                                        <OutlinedInput
                                                            fullWidth
                                                            type='text'
                                                            value={buyerDetails.address || ''}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>
                            
                                            <Grid item xs={12} lg={6}>
                                                <FormGroup>
                                                    <FormLabel>Primary Email</FormLabel>
                                                    <FormControl
                                                        variant='filled'
                                                        component={Box}
                                                        width='100%'
                                                        marginBottom='1rem!important'
                                                    >
                                                        <OutlinedInput
                                                            fullWidth
                                                            type='email'
                                                            value={buyerDetails.email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>

                                            <Grid item xs={12} lg={6}>
                                                <FormGroup>
                                                    <FormLabel>Primary Phone</FormLabel>
                                                    <FormControl
                                                        variant='filled'
                                                        component={Box}
                                                        width='100%'
                                                        marginBottom='1rem!important'
                                                    >
                                                        <OutlinedInput
                                                            fullWidth
                                                            type='text'
                                                            value={buyerDetails.phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                            disabled={true}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                    </div>
                                ) : (
                                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                                        <Loader />
                                    </Box>
                                )}

                                <Box
                                    component={Typography}
                                    variant='h6'
                                    color={theme.palette.gray[600] + '!important'}
                                    paddingTop='.25rem'
                                    paddingBottom='.25rem'
                                    fontSize='.75rem!important'
                                    letterSpacing='.04em'
                                    marginBottom='1.5rem!important'
                                    classes={{ root: classes.typographyRootH6 }}
                                >
                                    Personal Information
                                </Box>
                                <div>
                                    <Grid container>
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Name</FormLabel>
                                                <FormControl
                                                    variant='filled'
                                                    component={Box}
                                                    width='100%'
                                                    marginBottom='1rem!important'
                                                >
                                                    <OutlinedInput
                                                        fullWidth
                                                        type='text'
                                                        value={details.name}
                                                        disabled={true}
                                                    />
                                                </FormControl>
                                            </FormGroup>
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Role</FormLabel>
                                                <FormControl
                                                    variant='filled'
                                                    component={Box}
                                                    width='100%'
                                                    marginBottom='1rem!important'
                                                >
                                                    <OutlinedInput
                                                        fullWidth
                                                        type='text'
                                                        value={details.permissions === 'admin' ? 'Administrator' : 'Buyer'}
                                                        disabled={true}
                                                    />
                                                </FormControl>
                                            </FormGroup>
                                        </Grid>
                        
                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Email</FormLabel>
                                                <FormControl
                                                    variant='filled'
                                                    component={Box}
                                                    width='100%'
                                                    marginBottom='1rem!important'
                                                >
                                                    <OutlinedInput
                                                        fullWidth
                                                        type='email'
                                                        value={details.email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        disabled={true}
                                                    />
                                                </FormControl>
                                            </FormGroup>
                                        </Grid>

                                        <Grid item xs={12} lg={6}>
                                            <FormGroup>
                                                <FormLabel>Phone</FormLabel>
                                                <FormControl
                                                    variant='filled'
                                                    component={Box}
                                                    width='100%'
                                                    marginBottom='1rem!important'
                                                >
                                                    <OutlinedInput
                                                        fullWidth
                                                        type='text'
                                                        value={details.phone}
                                                        onChange={(e) => setPhone(e.target.value)}
                                                        disabled={true}
                                                    />
                                                </FormControl>
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/*(!loading && details.permissions === 'admin') &&
                        <Grid
                            item
                            xs={12}
                            xl={5}
                            component={Box}
                            marginBottom='3rem'
                            classes={{ root: classes.gridItemRoot + ' ' + classes.order2 }}
                        >
                            <Grid container>
                                
                                <Grid item xs={12}> 
                                    <TeamMembersCard 
                                        details={details}
                                        buyerDetails={buyerDetails}
                                    />
                                </Grid>
                                

                                {
                                <Grid item xs={12}>
                                    <PaymentMethodsCard 
                                        buyerDetails={buyerDetails}
                                    />
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                    */}
                </Grid>
            </Container>

            {showChangePassword &&
                <ChangePasswordModal
                    isOpen={showChangePassword}
                    close={() => setShowChangePassword(false)}
                />
            }

            {showUpdateCompanyDetails &&
                <CompanyDetailsModal
                    isOpen={showUpdateCompanyDetails}
                    close={() => setShowUpdateCompanyDetails(false)}
                    details={buyerDetails}
                    updateDetails={(address, email, phone) => updateCompanyDetails(address, email, phone)}
                />
            }
        </>
    );
}

export default Profile