import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function ProductCard(props) {
    const classes = useStyles()
    const product = props.product
    
    return (
        <>
            <Card className={classes.cardRoot}>
                <CardContent>
                    <Box
                        component={Typography}
                        variant='h3'
                        marginBottom='1rem!important'
                    >
                        {product.name}
                    </Box>

                    <Box
                        component='p'
                        marginBottom='1rem'
                        fontWeight='300'
                        lineHeight='1.7'
                        fontSize='1rem'
                        marginTop='0'
                        color='#2dce89'
                    >
                        {product.category}
                    </Box>

                    <Box
                        component='p'
                        marginBottom='1rem'
                        fontWeight='300'
                        lineHeight='1.7'
                        fontSize='1rem'
                        marginTop='0'
                    >
                        {(product.variants && product.variants.length > 0) ? (
                            <>From €{product.fromVariant.price}</>
                        ) : (
                            <>€{product.price}</>
                        )}
                    </Box>
                    
                    <Button variant='contained' color='primary' style={{ marginRight: '0.5rem'}} onClick={() => props.openProductModal()}>
                        Edit
                    </Button>
                    <Button variant='contained' color='secondary' onClick={() => props.openWarningModal()}>
                        Delete
                    </Button>
                </CardContent>
            </Card>
        </>
    );
}

export default ProductCard