import { Component } from 'react'
import { API, Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import config from './Config'

const apiPath = config.apiGatewayAdmatic.PATH

class AdmaticClient extends Component {
	addUserDetails(name, email, phone, permissions) {
		const data = {
			name: name,
			email: email,
			phone: phone,
			permissions: permissions,
		}
		console.log(data)

		return API.post(apiPath, 'users/add', {
			body: data
		})
	}

	getUserDetails() {
		return API.post(apiPath, 'users/get', {})
	}

	getBuyers() {
		return API.post(apiPath, 'buyers/list', {})
	}

	activateBuyer(buyerId, rootUserId, buyerEmail) {
		const data = {
			buyerId: buyerId,
			rootUserId: rootUserId,
			buyerEmail: buyerEmail
		}
		console.log(data)

		return API.post(apiPath, 'buyers/activate', {
			body: data
		})
	}

	deactivateBuyer(buyerId) {
		const data = {
			buyerId: buyerId
		}
		console.log(data)

		return API.post(apiPath, 'buyers/deactivate', {
			body: data
		})
	}

	addPublisher(companyId, name, email, phone, description, copy) {
		const data = {
			companyId: companyId,
			publisherId: uuidv4(),
			name: name,
			email: email,
			phone: phone,
			description: description,
			copy: copy
		}
		console.log(data)

		return API.post(apiPath, 'publishers/add', {
			body: data
		})
	}

	addBbPublisher(name, email, phone, description) {
		const data = {
			publisherId: uuidv4(),
			name: name,
			email: email,
			phone: phone,
			description: description
		}
		console.log(data)

		return API.post(apiPath, 'publishers/add-bb', {
			body: data
		})
	}

	activatePublisher(companyId, publisherId) {
		const data = {
			companyId,
			publisherId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/activate', {
			body: data
		})
	}

	deactivatePublisher(companyId, publisherId) {
		const data = {
			companyId,
			publisherId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/deactivate', {
			body: data
		})
	}

	updatePublisher(companyId, publisherId, name, email, phone, description, copy, logo) {
		console.log(copy)
		console.log(typeof copy)
		for (const [key, value] of Object.entries(copy)) {
			console.log(key)
			console.log(value)

			if (value === '<p><br></p>') {
				copy[key] = ''
			}
		}

		const data = {
			companyId: companyId,
			publisherId: publisherId,
			name: name,
			email: email,
			phone: phone,
			description: description,
			copy: copy,
			logo: logo
		}
		console.log(data)

		return API.post(apiPath, 'publishers/update', {
			body: data
		})
	}

	updateBbPublisherSections(publisherId, sections) {
		const data = {
			publisherId: publisherId,
			sections: sections
		}
		console.log(data)

		return API.post(apiPath, 'publishers/update-sections-bb', {
			body: data
		})
	}

	getPublishers(companyId) {
		const data = {
			companyId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/list', {
			body: data
		})
	}

	getBbPublishers() {
		return API.post(apiPath, 'publishers/list-bb', {})
	}

	getPublisher(publisherId) {
		const data = {
			publisherId: publisherId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/get', {
			body: data
		})
	}

	getPublisherProducts(companyId, publisherId) {
		const data = {
			companyId,
			publisherId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/products', {
			body: data
		})
	}

	getBbPublisherProducts(publisherId) {
		const data = {
			publisherId: publisherId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/products-bb', {
			body: data
		})
	}

	addProduct(companyId, publisherId, category, name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images) {
		const data = {
			companyId: companyId,
			publisherId: publisherId,
			category: category,
			name: name,
			description: description,
			tagline: tagline,
			ageGroup: ageGroup, 
			socialGroup: socialGroup,
			county: county,
			submitEnquiry: submitEnquiry,
			bookNow: bookNow,
			frequency: frequency,
			days: days,
			price: price,
			agencyPrice: agencyPrice,
			variants: variants,
			images: images
		}
		console.log(data)

		return API.post(apiPath, 'publishers/add-product', {
			body: data
		})
	}

	addBbProduct(publisherId, section, name, description, frequency, days, price, agencyPrice) {
		const data = {
			publisherId,
			section,
			name,
			description,
			frequency,
			days,
			price,
			agencyPrice
		}
		console.log(data)

		return API.post(apiPath, 'publishers/add-product-bb', {
			body: data
		})
	}

	updateProduct(companyId, publisherId, productId, name, description, tagline, ageGroup, socialGroup, county, submitEnquiry, bookNow, frequency, days, price, agencyPrice, variants, images) {
		const data = {
			companyId: companyId,
			publisherId: publisherId,
			productId: productId,
			name: name,
			description: description,
			tagline: tagline,
			ageGroup: ageGroup, 
			socialGroup: socialGroup,
			county: county,
			submitEnquiry: submitEnquiry,
			bookNow: bookNow,
			frequency: frequency,
			days: days,
			price: price,
			agencyPrice: agencyPrice,
			variants: variants,
			images: images
		}
		console.log(data)

		return API.post(apiPath, 'publishers/update-product', {
			body: data
		})
	}

	updateBbProduct(publisherId, productId, section, name, description, frequency, days, price, agencyPrice) {
		const data = {
			publisherId,
			productId,
			section,
			name,
			description,
			frequency,
			days,
			price,
			agencyPrice
		}
		console.log(data)

		return API.post(apiPath, 'publishers/update-product-bb', {
			body: data
		})
	}

	deleteProduct(companyId, publisherId, productId) {
		const data = {
			companyId: companyId,
			publisherId: publisherId,
			productId: productId
		}
		console.log(data)

		return API.post(apiPath, 'publishers/delete-product', {
			body: data
		})
	}

	getAllOrders(companyId) {
		const data = {
			companyId
		}
		console.log(data)
	
		return API.post(apiPath, 'orders/list-all', {
			body: data
		})
	}

	approveOrder(buyerId, orderId, admaticUser, buyerEmail) {
		const data = {
			buyerId: buyerId,
			orderId: orderId,
			admaticUser: admaticUser,
			buyerEmail: buyerEmail
		}
		console.log(data)

		return API.post(apiPath, 'orders/approve', {
			body: data
		})
	}

	approveUpdateOrder(buyerId, orderId, startDate, endDate, total, admaticUser, buyerEmail) {
		const data = {
			buyerId: buyerId,
			orderId: orderId,
			startDate: startDate,
			endDate: endDate,
			total: total,
			admaticUser: admaticUser,
			buyerEmail: buyerEmail
		}
		console.log(data)

		return API.post(apiPath, 'orders/approve-update', {
			body: data
		})
	}

	rejectOrder(buyerId, orderId, rejectNote, admaticUser, buyerEmail) {
		const data = {
			buyerId: buyerId,
			orderId: orderId,
			rejectNote: rejectNote,
			admaticUser: admaticUser,
			buyerEmail: buyerEmail
		}
		console.log(data)

		return API.post(apiPath, 'orders/reject', {
			body: data
		})
	}

	getCategoryContent() {
		return API.post(apiPath, 'config/media-catalog-content', {})
	}

	updateCategoryContent(category, content) {
		const data = {
			category: category,
			content: content
		}
		console.log(data)

		return API.post(apiPath, 'config/update-media-catalog', {
			body: data
		})
	}

	getPageIntroContent() {
		return API.post(apiPath, 'config/page-intro-content', {})
	}

	updatePageIntroContent(page, content) {
		const data = {
			page: page,
			content: content
		}
		console.log(data)

		return API.post(apiPath, 'config/update-page-intro', {
			body: data
		})
	}

	getPlanningArticles() {
		return API.post(apiPath, 'config/planning-articles', {})
	}

	updatePlanningArticle(key, content) {
		const data = {
			key: key,
			content: content
		}
		console.log(data)

		return API.post(apiPath, 'config/update-planning-article', {
			body: data
		})
	}

	deletePlanningArticle(key) {
		const data = {
			key: key
		}
		console.log(data)

		return API.post(apiPath, 'config/delete-planning-article', {
			body: data
		})
	}

	getTeamMembers() {
		return API.post(apiPath, 'users/list', {})
	}

	addTeamMember(name, email, phone, permissions, password) {
		// Create cognito user
		const cognitoData = {
        	'username': email.toLowerCase(), 
			'password': password,
			'attributes': {
				'name': name,
				'phone_number': phone,
				'custom:account_type': 'admatic',
				'custom:permissions': permissions
			}
        }

        return Auth.signUp(cognitoData)

		// Add details to dynamo
		// const data = {
		// 	buyerId: buyerId,
		// 	buyerName: buyerName,
		// 	name: name,
		// 	email: email,
		// 	phone: phone,
		// 	permissions: permissions
		// }
		// console.log(data)

		// return API.post(apiPath, 'users/add-team-member', {
		// 	body: data
		// })
	}

	deactivateUser(userId) {
		const data = {
			userId: userId
		}
		console.log(data)

		return API.post(apiPath, 'users/deactivate', {
			body: data
		})
	}

	activateUser(userId) {
		const data = {
			userId: userId
		}
		console.log(data)

		return API.post(apiPath, 'users/activate', {
			body: data
		})
	}

	getNotificationSettings() {
		return API.post(apiPath, 'config/notifications', {})
	}

	updateNotificationSettings(buyerRegistration, orderPlaced, orderEnquiry, copyEnquiry, createAdEnquiry, generalEnquiry) {
		const data = {
			buyerRegistration: buyerRegistration,
			orderPlaced: orderPlaced,
			orderEnquiry: orderEnquiry,
			copyEnquiry: copyEnquiry,
			createAdEnquiry: createAdEnquiry,
			generalEnquiry: generalEnquiry
		}
		console.log(data)

		return API.post(apiPath, 'config/update-notifications', {
			body: data
		})
	}

	getDropdowns() {
		return API.post(apiPath, 'config/dropdowns', {})
	}

	updateDropdowns(options) {
		const data = {
			options: options
		}
		console.log(data)

		return API.post(apiPath, 'config/update-dropdowns', {
			body: data
		})
	}

	getPayment(paymentId) {
		const data = {
			paymentId: paymentId
		}
		console.log(data)

		return API.post(apiPath, 'fire/get-payment', {
			body: data
		})
	}

	getPaymentRequestPayments(paymentRequestCode) {
		const data = {
			paymentRequestCode: paymentRequestCode
		}
		console.log(data)

		return API.post(apiPath, 'fire/get-payment-request-payments', {
			body: data
		})
	}

	getAllMedia() {
		return API.post(apiPath, 'config/all-media', {})
	}

	getOrder(buyerId, orderId) {
		const data = {
			buyerId: buyerId,
			orderId: orderId
		}
		console.log(data)

		return API.post(apiPath, 'orders/get', {
			body: data
		})
	}

	markOrderPaid(paymentId, companyId) {
		const data = {
			paymentId: paymentId,
			companyId: companyId
		}
		console.log(data)

		return API.post(apiPath, 'orders/mark-paid', {
			body: data
		})
	}

	getBuyerCopy(buyerId) {
		const data = {
			buyerId: buyerId
		}
		console.log(data)

		return API.post(apiPath, 'config/buyer-copy', {
			body: data
		})
	}
} 

export default AdmaticClient