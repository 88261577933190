import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { categories } from 'config/constants/Constants'
import ReactDropzoneFile from 'components/general/ReactDropzoneFile'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'
import { Storage } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'
import { NotificationManager } from 'react-notifications'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function UploadCopyModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	const theme = useTheme()
    const api = new buyerClient()

    const details = props.details

  	const [name, setName] = useState('')
    const [category, setCategory] = useState('Radio')
    const [file, setFile] = useState(null)

  	async function uploadCopy() {
        let copyId = uuidv4()
        try {
            // upload to s3
            let path = 'buyers/' + details.buyerId + '/copy/'  + copyId
            console.log('path: ', path)
            let uploaded = await Storage.put(path, file, {
                contentType: file.type,
                bucket: 'admatic-user-upload',
                // customPrefix: {
                //    public: ''
                // }
            })
            console.log('uploaded: ', uploaded)
            // add to dynamo
            let result = await api.addBuyerCopy(details.buyerId, copyId, name, category)
            console.log('result: ', result)

            NotificationManager.success('File uploaded')
            props.refreshMedia()
            props.close()
        } catch(e) {
            onError(e)
        }
  	}

  	function checkFields() {
  		if (name.length > 0 && category && file) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
			maxWidth='sm'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Upload Copy
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				<FormGroup>
                  	<FormLabel>Name</FormLabel>
                  	<OutlinedInput
                    	fullWidth
                    	type='text'
                    	placeholder='TV Ad - Winter 2022'
                    	value={name}
                    	onChange={(e) => setName(e.target.value)}
                  	/>
                </FormGroup>
                <FormGroup>
                    <FormLabel>Category</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <Select
                            multiple={false}
                            defaultValue={'Radio'}
                            IconComponent={KeyboardArrowDown}
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            {categories.map((option, i) => {
                                return (<MenuItem value={option}>{option}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                </FormGroup>
                <FormGroup>
                    <FormLabel>File</FormLabel>
                    <FormControl variant='outlined' fullWidth>
                        <ReactDropzoneFile
                            updateFile={(file) => setFile(file)}
                        />
                    </FormControl>
                </FormGroup>

				<Box
					fontStyle='italic'
					fontSize='1rem'
					color={theme.palette.gray[600]}
					marginBottom='1rem'
				>
					<Box component='small' fontSize='80%'>
						Please enter a name, a category, and select a file for upload
					</Box>
				</Box>
			</DialogContent>
			
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			
				<Button
					component={Box}
					onClick={() => uploadCopy()}
					color='primary'
					marginLeft='auto!important'
					variant='contained'
					disabled={checkFields()}
				>
					Add
				</Button>
			</DialogActions>
		</Dialog>
	)
}