import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
//import Add from '@material-ui/icons/Add'
//import Button from '@material-ui/core/Button'
import Loader from 'components/general/Loader'
//import buyerClient from 'config/api/BuyerClient'
//import { onError } from 'config/lib/errorLib'
//import { NotificationManager } from 'react-notifications'
//import ContentCard from 'components/buyer/ContentCard'
//import UploadCopyModal from 'components/buyer/UploadCopyModal'
import BookAndBuyForm from 'components/guest/BookAndBuyForm'
import AdmaticLogo from 'resources/images/admatic-logo-updated.png'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function CopyUpload() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    //const api = new buyerClient()

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >   
                <Grid 
                    container
                    spacing={0}
                    direction='row'
                    alignItems='center'
                    justify='center'
                    style={{ marginBottom: '30px' }}
                >
                    <Grid item xs={10}>
                        <Box 
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            style={{paddingTop: '2rem'}}
                        >
                            <img className='login-logo' src={AdmaticLogo} alt='guaranteed-irish-logo' />
                        </Box>
                    </Grid>

                    <Grid item xs={8}>
                        <Box 
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            style={{paddingTop: '2rem'}}
                        >
                            <Typography style={{color: '#fff', marginBottom: '1rem', fontWeight: '700'}} align='center' marginBottom='1rem'>Welcome to the new Book & Buy section!</Typography>
                            <Typography style={{color: '#fff', marginBottom: '1rem'}}  align='center' marginBottom='1rem'>Where our members can avail of special media discounted rates a for notices in paper publications.</Typography>
                        </Box>
                    </Grid>
                </Grid>
                
                {!loading ? (
                    <Grid 
                        container
                        spacing={0}
                        direction='row'
                        alignItems='center'
                        justify='center'
                    >
                        <Grid item xs={12} xl={10}>
                            <BookAndBuyForm />
                        </Grid>
                    </Grid>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader />
                    </Box>
                )}
            </Container>
        </>
    );
}

export default CopyUpload