import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import DatePicker from 'components/general/DatePicker'
import ImageModal from 'components/general/ImageModal'
import Logo from 'components/general/Logo'
import VariantRow from 'components/general/VariantRow'
import GuestDetailsModal from 'components/guest/GuestDetailsModal'
import moment from 'moment'
import { Storage } from 'aws-amplify'
import { getTotalPrice, getSite } from 'config/helpers/Helpers'
import Parser from 'html-react-parser'

import CompanyContext from 'config/context/CompanyContext'

// global user details
import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ProductModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()

	const company = useContext(CompanyContext)
	//console.log('company: ', company)

	const product = props.product
	const publisher = props.publisher
	const details = useContext(DetailsContext)

	// dont allow booking sooner than 3 days from now
  	const minStartDate = moment().add(3, 'days')
  	const minEndDate = moment().add(4, 'days')

	const [enquiryMessage, setEnquiryMessage] = useState('')

	const [selectedVariant, setSelectedVariant] = useState(null)
	const [stage, setStage] = useState('product')
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [campaignName, setCampaignName] = useState('')
	const [note, setNote] = useState('')
	const [images, setImages] = useState([])

	const [viewImage, setViewImage] = useState(null)
	const [showImageModal, setShowImageModal] = useState(false)

	const [showGuestDetailsModal, setShowGuestDetailsModal] = useState(false)

	useEffect(() => {
        getImages()
    }, [])

    async function getImages() {
        if (product.images.length > 0) {
        	let images = []
        	for (var key of product.images) {
        		let image = await Storage.get('publishers/' + product.publisherId + '/products/' + product.productId + '/' + key)
        		console.log(image)
        		let img = {
		  			id: key,
		  			image: image
		  		}
		  		console.log(img)
		  		images.push(image)
        	}
        	setImages(images)
        	console.log(images)
        }
    }

	function updateStartDate(startDate) {
		console.log('start date: ', startDate)
		setStartDate(startDate)
	}

	function updateEndDate(endDate) {
		console.log('end date: ', endDate)
		setEndDate(endDate)
	}

	async function addToCart() {
		console.log('add to cart...')

		let frequency = product.frequency
		let days = product.days
		let price = product.price
		let agencyPrice = product.agencyPrice

		if (selectedVariant) {
			frequency = selectedVariant.frequency
			days = selectedVariant.days
			price = selectedVariant.price
			agencyPrice = selectedVariant.agencyPrice
		}

		let totals = getTotalPrice(startDate, endDate, frequency, days, price, agencyPrice)

		let subtotal = parseFloat(totals.total.toFixed(2))
		console.log(subtotal)
		let vat = parseFloat((subtotal * 0.23).toFixed(2))
		console.log(vat)
		let total = subtotal + vat

		// TODO: calculate quantity based on dates selected and duration of package/product
		let item = {
			product: product,
			variant: selectedVariant,
			publisher: publisher,
			quantity: 1,
			startDate: startDate,
			endDate: endDate,
			subtotal: subtotal,
			vat: vat,
			total: total,
			campaignName: campaignName,
			note: note
		}

		props.addToCart(item)
	}

	function getTotalPriceString() {
		let frequency = product.frequency
		let days = product.days
		let price = product.price
		let agencyPrice = product.agencyPrice

		if (selectedVariant) {
			frequency = selectedVariant.frequency
			days = selectedVariant.days
			price = selectedVariant.price
			agencyPrice = selectedVariant.agencyPrice
		}

		let totals = getTotalPrice(startDate, endDate, frequency, days, price, agencyPrice)
		console.log(totals)

		return 'Total Price: €' + totals.total.toFixed(2) + ' (Saving of €' + (totals.agencyTotal - totals.total).toFixed(2) + ')'
	}

	function clickSubmitEnquiry() {
		if (props.type === 'guest') {
			setShowGuestDetailsModal(true)
		}
		else {
			submitEnquiry()
		}
	}

	function submitEnquiry(details) {
		if (props.type === 'guest') {
			props.submitEnquiry(details, campaignName, enquiryMessage, selectedVariant)
		}
		else {
			props.submitEnquiry(campaignName, enquiryMessage, selectedVariant)
		}
	}

	function checkProductFields() {
  		if (product.variants.length === 0) {
  			return false
  		}
  		else {
  			if (selectedVariant) {
  				return false
  			}
  			else {
  				return true
  			}
  		}
  	}

	function checkBookingFields() {
  		if (startDate && endDate && campaignName.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

  	function checkEnquiryFields() {
  		if (campaignName.length > 0 && enquiryMessage.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

  	function openImageModal() {
  		console.log(images[0])
  		setViewImage(images[0])
  		setShowImageModal(true)
  	}

  	function closeImageModal() {
  		setShowImageModal(false)
  		setViewImage(null)
  	}

	function goToSubmitEnquiry() {
		setStage('enquiry')
		// if (props.type === 'guest') {
		// 	props.openGuestDetailsModal()
		// }
	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{stage === 'product' &&
						<>Product Details</>
					}
					{stage === 'enquiry' &&
						<>Submit Enquiry</>
					}
					{stage === 'booking' &&
						<>Booking Details</>
					}
					{stage === 'summary' &&
						<>Booking Summary</>
					}
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			{stage === 'product' &&
				<DialogContent>
					<Grid 
	                    container
	                    spacing={1}
	                    direction='row'
	                    alignItems='start'
	                    justify='start'
	                    style={{marginBottom: '1rem'}}
	                >
	                	<Grid item xs={6}>
							<Grid 
			                    container
			                    spacing={2}
			                    direction='column'
			                    alignItems='center'
			                    justify='center'
			                >
			                    <Grid item>
			                        <Logo 
			                            logo={publisher.logo}
			                            name={publisher.name}
			                            size='70px'
			                            marginRight={'0'}
			                        />
			                    </Grid>
			                    <Grid item>
			                        <Box
			                            component={Typography}
			                            variant='h2'
			                        >
			                            <Box component='span'>{publisher.name}</Box>
			                        </Box>
			                    </Grid>
			                </Grid>
		                </Grid>

		                <Grid item xs={6}>
		                	<Grid 
			                    container
			                    spacing={1}
			                    direction='column'
			                    alignItems='start'
			                    justify='center'
			                >
			                	<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='0.5rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Category
				                    </Box>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        {product.category}
				                    </Box>
			                	</Grid>

			                	<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='0.5rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Name
				                    </Box>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        {product.name}
				                    </Box>
			                	</Grid>
			                </Grid>
		                </Grid>
		          	</Grid>

	                <Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                    alignItems='flex-start'
	                    justify='flex-start'
	                >
	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='600'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Description
		                    </Box>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        {Parser(product.description)}
		                    </Box>
	                	</Grid>

						{product.ageGroup &&
							<Grid item>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='600'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									Age Group
								</Box>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='300'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									{product.ageGroup}
								</Box>
							</Grid>
						}

						{product.socialGroup &&
							<Grid item>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='600'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									Social Group
								</Box>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='300'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									{product.socialGroup}
								</Box>
							</Grid>
						}

						{product.county &&
							<Grid item>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='600'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									Location
								</Box>
								<Box
									component='p'
									marginBottom='1rem'
									fontWeight='300'
									lineHeight='1.2'
									fontSize='1rem'
									marginTop='0'
								>
									{product.county}
								</Box>
							</Grid>
						}

	                	{images.length > 0 &&
	                		<Grid item xs={12}>
			                    <Button
									component={Box}
									color='primary'
									variant='contained'
									marginLeft='auto!important'
									onClick={() => openImageModal()}
								>
									View Image
								</Button>
		                	</Grid>
	                	}

	                	{product.variants && product.variants.length > 0 ? (
	                		<Grid item style={{width: '100%', marginTop: '1rem'}}>
	                			<Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='600'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                    >
			                        Options
			                    </Box>
		                        {
		                        	product.variants.map((variant, i) => {
		                        		return (
		                        			<VariantRow
		                        				key={i}
		                        				variant={variant}
		                        				selectedVariant={selectedVariant}
		                        				setVariant={() => setSelectedVariant(variant)}
		                        				buyer
		                        			/>
		                        		)
		                        	})
		                        }
	                		</Grid>
	                	) : (
	                		<>
		                		<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Frequency
				                    </Box>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        {product.frequency}
				                    </Box>
			                	</Grid>
			                	<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Days ads run
				                    </Box>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        {product.days.join(', ')}
				                    </Box>
			                	</Grid>
		                		<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Price
				                    </Box>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        <>
			                                {product.price > 0 ? (
			                                    <>
			                                        <>{getSite(company.id)} price €{product.price}</><br/>
			                                        {(product.agencyPrice && product.agencyPrice > 0) &&
			                                        	<>Competitor price €{product.agencyPrice}</>
			                                        }
                                    			</>
			                                ) : (
			                                    <>€POA</>
			                                )}
			                            </>
				                    </Box>

			                	</Grid>
		                	</>
	                	)}
	                </Grid>
				</DialogContent>
			}

			{stage === 'enquiry' &&
				<DialogContent>
					<FormGroup>
		                <FormLabel>Campaign Name</FormLabel>
		                <OutlinedInput
		                    fullWidth
		                    type='text'
		                    placeholder=''
		                    value={campaignName}
		                    onChange={(e) => setCampaignName(e.target.value)}
		                />
		            </FormGroup>
		            <FormGroup>
		                <FormLabel>Message</FormLabel>
		                <OutlinedInput
		                    fullWidth
		                    multiline
		                    rows='5'
		                    type='text'
		                    placeholder='I am looking for...'
		                    value={enquiryMessage}
		                    onChange={(e) => setEnquiryMessage(e.target.value)}
		                />
		            </FormGroup>
				</DialogContent>
			}

			{stage === 'booking' &&
				<DialogContent>
					<FormGroup>
	                  	<FormLabel>Start Date</FormLabel>
	                  	<DatePicker
	                  		placeholder='Campaign Start'
	                  		value={startDate}
	                  		onChange={(e) => updateStartDate(e)}
	                  		minDate={minStartDate}
	                  		frequency={product.frequency ? product.frequency : selectedVariant.frequency}
	                  		startPicker
	                  	/>
	                </FormGroup>

	                <FormGroup>
	                  	<FormLabel>End Date</FormLabel>
	                  	<DatePicker
	                  		placeholder='Campaign End'
	                  		value={endDate}
	                  		onChange={(e) => updateEndDate(e)}
	                  		minDate={minEndDate}
	                  		frequency={product.frequency ? product.frequency : selectedVariant.frequency}
	                  		endPicker
	                  		startDate={startDate}
	                  	/>
	                </FormGroup>

	                <FormGroup>
	                  	<FormLabel>Campaign Name</FormLabel>
	                  	<OutlinedInput
	                    	fullWidth
	                    	type='text'
	                    	placeholder='Q1 Ads - Guiness'
	                    	value={campaignName}
	                    	onChange={(e) => setCampaignName(e.target.value)}
	                  	/>
	                </FormGroup>

	                <FormGroup>
	                  	<FormLabel>Note</FormLabel>
	                  	<OutlinedInput
	                    	fullWidth
	                    	multiline
	                    	rows='5'
	                    	type='text'
	                    	placeholder='Please confirm if x is possible'
	                    	value={note}
	                    	onChange={(e) => setNote(e.target.value)}
	                  	/>
	                </FormGroup>
				</DialogContent>
			}

			{stage === 'summary' &&
				<DialogContent>
					<Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                    alignItems='flex-start'
	                    justify='flex-start'
	                >
	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Advertiser: {details.buyerName}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Booked By: {details.name}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Publisher: {publisher.name}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Category: {product.category}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Product: {product.name}
		                    </Box>
	                	</Grid>

	                	{selectedVariant && 
	                		<>
			                	<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Variant: {selectedVariant.name}
				                    </Box>
			                	</Grid>
			                	<Grid item>
			                		<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='300'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Frequency: {selectedVariant.frequency} ({selectedVariant.days.join(', ')})
				                    </Box>
			                	</Grid>
		                	</>
		                }

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Start Date: {startDate.format('DD/MM/YY')}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        End Date: {endDate.format('DD/MM/YY')}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Campaign Name: {campaignName}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        
		                       	Note: {note}
		                    </Box>
	                	</Grid>

	                	<Grid item>
	                		<Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='600'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                    	{selectedVariant ? (
		                    		<>{getTotalPriceString()}</>
		                    	) : (
		                        	<>{getTotalPriceString()}</>
		                    	)}
		                    </Box>
	                	</Grid>
	                </Grid>
				</DialogContent>
			}
			
			<DialogActions style={{justifyContent: 'space-between'}}>
				{stage === 'product' &&
					<Button
						onClick={() => props.close()}
						color='primary'
					>
						Close
					</Button>
				}
				{(stage === 'booking' || stage === 'enquiry') &&
					<Button
						onClick={() => setStage('product')}
						color='primary'
					>
						Back
					</Button>
				}
				{stage === 'summary' &&
					<Button
						onClick={() => setStage('booking')}
						color='primary'
					>
						Back
					</Button>
				}
				{stage === 'product' &&
					<Box>
						{product.submitEnquiry &&
							<Button
								component={Box}
								color='primary'
								variant='contained'
								marginLeft='auto!important'
								style={{ marginRight: '0.5rem'}}
								onClick={() => goToSubmitEnquiry()}
								//disabled={checkProductFields()}
								className='step--enquiry'
							>
								Submit Enquiry
							</Button>
						}
						{product.bookNow &&
							<Button
								component={Box}
								color='primary'
								variant='contained'
								marginLeft='auto!important'
								onClick={() => setStage('booking')}
								disabled={checkProductFields()}
								className='step--book'
							>
								Book Now
							</Button>
						}
					</Box>
				}
				{stage === 'enquiry' &&
					<Button
						component={Box}
						color='primary'
						variant='contained'
						marginLeft='auto!important'
						onClick={() => clickSubmitEnquiry()}
						disabled={checkEnquiryFields()}
						className='step--enquiry--submit'
					>
						Submit
					</Button>
				}
				{stage === 'booking' &&
					<Button
						component={Box}
						color='primary'
						variant='contained'
						marginLeft='auto!important'
						onClick={() => setStage('summary')}
						disabled={checkBookingFields()}
						className='step--book--details'
					>
						Continue
					</Button>
				}
				{stage === 'summary' &&
					<Button
						component={Box}
						color='primary'
						variant='contained'
						marginLeft='auto!important'
						onClick={() => addToCart()}
						className='step--book--add-to-cart'
					>
						Add to Cart
					</Button>
				}
			</DialogActions>

			{showImageModal &&
				<ImageModal
					isOpen={showImageModal}
	                close={() => closeImageModal()}
	                src={viewImage}
				/>
			}

			{showGuestDetailsModal &&
                <GuestDetailsModal 
                    isOpen={showGuestDetailsModal}
                    close={() => setShowGuestDetailsModal(false)}
                    placeOrder={(details) => submitEnquiry(details)}
                />
            }
		</Dialog>
	)
}