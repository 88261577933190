import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import AddTeamMemberModal from 'components/general/AddTeamMemberModal'
import TeamMemberRow from 'components/general/TeamMemberRow'
import Loader from 'components/general/Loader'
import buyerClient from 'config/api/BuyerClient'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/components/cards/widgets/card-team-members-search.js'

const useStyles = makeStyles(componentStyles)

function TeamMembersCard(props) {
    const classes = useStyles()
    const theme = useTheme()
    const api = props.admatic ? new admaticClient() : new buyerClient()

    const details = props.details
    const buyerDetails = props.buyerDetails

    const [teamMembers, setTeamMembers] = useState([])
    const [showAddTeamMember, setShowAddTeamMember] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(null)

    useEffect(() => {
        getTeamMembers()
    }, [])

    async function getTeamMembers() {
        var members = []
        if (props.admatic) {
            try {
                members = await api.getTeamMembers()
                console.log('members: ', members)
            } catch(e) {
                onError(e)
            }
        }
        else {
            try {
                members = await api.getTeamMembers(buyerDetails.buyerId)
                console.log('members: ', members)
            } catch(e) {
                onError(e)
            }
        }

        setTeamMembers(members)
        setLoading(false)
    }

    async function deactivateUser(user) {
        console.log(user)
        try {
            if (props.admatic) {
                let updated = await api.deactivateUser(user.userId)
                console.log('user: ', updated)
            }
            else {
                let updated = await api.deactivateUser(user.buyerId, user.userId)
                console.log('user: ', updated)
            }

            openSuccessAlert('Team member deactivated! They can no longer access their account or place orders.')
            getTeamMembers()
        } catch(e) {
            onError(e)
        }
    }

    async function activateUser(user) {
        try {
            if (props.admatic) {
                let updated = await api.activateUser(user.userId)
                console.log('user: ', updated)
            }
            else {
                let updated = await api.activateUser(user.buyerId, user.userId)
                console.log('user: ', updated)
            }

            openSuccessAlert('Team member activated! They can now access their account and place orders.')
            getTeamMembers()
        } catch(e) {
            onError(e)
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }
    
    return (
        <>
            {alert}
            <Card
                classes={{
                    root: classes.cardRoot,
                }}
            >
                <CardHeader
                    subheader={
                        <Grid
                            container
                            component={Box}
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Grid item xs='auto'>
                                <Box
                                    component={Typography}
                                    variant='h3'
                                    marginBottom='0!important'
                                >
                                    Team members
                                </Box>
                            </Grid>
                            
                            {(buyerDetails || (props.admatic && !details.permissions)) &&
                                <Grid item xs='auto'>
                                    <Box
                                        justifyContent='flex-end'
                                        display='flex'
                                        flexWrap='wrap'
                                    >
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            onClick={() => setShowAddTeamMember(true)}
                                        >
                                            Add
                                        </Button>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                >
                </CardHeader>
                
                <CardContent>
                    {!loading ? (
                        <>
                            {teamMembers.length > 0 ? (
                                <List disablePadding>
                                    {teamMembers.map((user, i) => {
                                        if (props.admatic) {
                                            return (
                                                <ListItem key={i} className={classes.listItemRoot}>
                                                    <TeamMemberRow 
                                                        user={user}
                                                        details={details}
                                                        deactivateUser={() => deactivateUser(user)}
                                                        activateUser={() => activateUser(user)}
                                                        admatic
                                                    />                                                        
                                                </ListItem>
                                            )
                                        }
                                        else {
                                            if (user.userId !== details.userId) {
                                                return (
                                                    <ListItem key={i} className={classes.listItemRoot}>
                                                        <TeamMemberRow 
                                                            user={user}
                                                            details={details}
                                                            buyerDetails={buyerDetails}
                                                            deactivateUser={() => deactivateUser(user)}
                                                            activateUser={() => activateUser(user)}
                                                        />                                                        
                                                    </ListItem>
                                                )
                                            }
                                        }
                                    })}
                                </List>
                            ) : (
                                <Box
                                    component='p'
                                    marginBottom='1rem'
                                    fontWeight='300'
                                    lineHeight='1.7'
                                    fontSize='1rem'
                                    //marginTop="1.5rem"
                                >
                                    No team members
                                </Box>
                            )}
                        </>
                    ) : (
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    )}
                </CardContent>
            </Card>

            {showAddTeamMember &&
                <AddTeamMemberModal
                    isOpen={showAddTeamMember}
                    close={() => setShowAddTeamMember(false)}
                    buyerDetails={buyerDetails}
                    openSuccessAlert={(text) => openSuccessAlert(text)}
                    admatic={props.admatic}
                    //refreshTeamMembers={() => getTeamMembers()}
                />
            }
        </>
    );
}

export default TeamMembersCard