import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import ContentCard from 'components/buyer/ContentCard'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Welcome() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }

    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12} xl={12}>
                        <ContentCard 
                            title={'Welcome to Admatic'}
                            body={
                                <>
                                    <h2>Welcome</h2>
                                    <p><br/></p>
                                    <p>On the left hand side you'll see various links. Please browse what we have on offer and use the 'back' button to navigate to the previous section in the media catalog.</p>
                                    <p>If you're a large Advertiser with multiple Ad buyers, we can set up your own Admatic branded internal platform too.&nbsp;For Design/PR/Promotions/Marketing/Creative Agencies too, we can provide Admatic as&nbsp;your own&nbsp;internal branded system providing bespoke information and your potential to substantially earn from media buying.&nbsp;</p>
                                    <h2><br/></h2>
                                    <h2>Low Price Comparison Guarantee</h2>
                                    <p><br/></p>
                                    <p>We have total confidence in our low pricing. If however, you're offered lower media costs, make sure that you're comparing 'like with like' (notably broadcast time bands in radio for example) and do please tell us and we'll match it. Volume loyalty discounts (further reductions off our published prices) will also apply and be rebated to loyal Admatic advertisers as appreciation for your business. They range from 1% to as high as a further 20% rebate and start once an initial threshold of a 10,000 euro spend is reached. If you're intending high levels of media buying.</p>
                                    <h2><br/></h2>
                                    <h2>Need any help now?&nbsp;</h2>
                                    <p><br/></p>
                                    <p>We want you to ask us! 247 365 so please just do it.&nbsp;Stuart@admatic.ie. We're very experienced in helping Advertisers achieve better Advertising. If you're stuck, don't give up, call/email us or see our&nbsp;zoom help.</p>
                                </>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} xl={12}>
                        
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Welcome