import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
//import Parser from 'html-react-parser'
import { getSite } from 'config/helpers/Helpers'

import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'
import componentStylesBadge from "assets/theme/components/badge.js";

const useStyles = makeStyles(componentStyles)
const useStylesBadge = makeStyles(componentStylesBadge);

function ProductCard(props) {
    const classes = { ...useStyles(), ...useStylesBadge() }
    const product = props.product

    const company = useContext(CompanyContext)
	//console.log('company: ', company)

    function getBadgeString() {
        let string = ''

        if (product.variants && product.variants.length > 0) {
            string = 'Save €' + (product.fromVariant.agencyPrice - product.fromVariant.price)
        }
        else {
            string = 'Save €' + (product.agencyPrice - product.price)
        }

        return string
    }
    
    return (
        <>
            <Card className={classes.cardRoot}>
                <CardContent>
                    <Box
                        component={Typography}
                        variant='h2'
                        marginBottom='1rem!important'
                    >
                        {product.name}
                    </Box>

                    {/*(product.tagline && product.tagline.trim().length > 0) &&
                        <Box
                            component={Typography}
                            variant='h4'
                            marginBottom='1rem!important'
                        >
                            {Parser(product.tagline)}
                        </Box>
                    */}

                    <Box
                        component='p'
                        marginBottom='1.5rem'
                        fontWeight='300'
                        lineHeight='1.7'
                        fontSize='1rem'
                        marginTop='0'
                    >
                        {(product.variants && product.variants.length > 0) ? (
                            <>From €{product.fromVariant.price}</>
                        ) : (
                            <>
                                {product.price > 0 ? (
                                    <>
                                        <>{getSite(company.id)} price €{product.price}</><br/>
                                        {(product.agencyPrice && product.agencyPrice > 0) &&
                                            <>Competitor price €{product.agencyPrice}</>
                                        }
                                    </>
                                ) : (
                                    <>€POA</>
                                )}
                            </>
                        )}
                    </Box>

                    {/*(product.agencyPrice - product.price > 0) &&
                        <Box
                            component='p'
                            marginBottom='1rem'
                            fontWeight='300'
                            lineHeight='1.7'
                            fontSize='1rem'
                            marginTop='0'
                            color='#2dce89'
                        >
                            {(product.variants && product.variants.length > 0) ? (
                                <>You save €{product.fromVariant.agencyPrice - product.fromVariant.price} with Admatic</>
                            ) : (
                                <>You save €{product.agencyPrice - product.price} with Admatic</>
                            )}
                        </Box>
                    */}
                    

                    <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-between'
                        alignItems='flex-end'
                    >
                        <Button variant='contained' color='primary' size='medium' onClick={() => props.onClick()} className='step--details'>
                            Details
                        </Button>

                        {(product.agencyPrice - product.price > 0) &&
                            <Badge
                                badgeContent={getBadgeString()}
                                classes={{
                                    badge:
                                    classes.badgePositionRelative +
                                    " " +
                                    classes.badgeSuccess +
                                    " " +
                                    classes.badgeSizeLg,
                                }}
                                style={{fontSize: '1.2rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                            ></Badge>
                        }
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}

export default ProductCard