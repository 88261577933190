import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import OrdersTable from 'components/admatic/OrdersTable'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'
import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function Orders() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new admaticClient()

    const company = useContext(CompanyContext)
    //console.log('company: ', company)

    const [orders, setOrders] = useState([])
    const [showOrders, setShowOrders] = useState([])
    const [loading, setLoading] = useState(true)

    // empty array argument prevents endless loop
    useEffect(() => {
        getOrders()
    }, [])

    async function getOrders() {
        try {
            const result = await api.getAllOrders(company.id)

            if (result) {
                result.sort((a, b) => {
                    return b.createdAt - a.createdAt
                })
            }
            
            setOrders(result)
            setShowOrders(result)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function filterOrders(filter) {
        if (filter === 'all') {
            setShowOrders(orders)
        }
        else {
            let show = []
            for (var order of orders) {
                if (order.status === filter) {
                    show.push(order)
                }
            }
            setShowOrders(show)
        }
    }
    
    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <OrdersTable 
                            orders={showOrders}
                            refreshOrders={() => getOrders()}
                            filterOrders={(filter) => filterOrders(filter)}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Orders