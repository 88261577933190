import React, { useEffect, useState, useContext } from 'react'
import {
    PaymentElement,
    useStripe,
    useElements
} from '@stripe/react-stripe-js'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Loader from 'components/general/Loader'
import CompanyContext from 'config/context/CompanyContext'

const isLocal = process.env.NODE_ENV === 'development'

export default function CheckoutForm(props) {
    const stripe = useStripe()
    const elements = useElements()

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!stripe) {
            return;
        }

        //const clientSecret = props.clientSecret

        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        )

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
                }
        });
    }, [stripe]);

    function getCompletedUrl() {
        if (company.id === 'ad') {
            return 'https://app.admatic.ie/payment-completed'
        }
        if (company.id === 'gi') {
            return 'https://app.guaranteedirish.ie/payment-completed'
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: isLocal ? 'http://localhost:3000/payment-completed' : getCompletedUrl(),
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occured.');
        }

        setIsLoading(false)
    }

    return (
        <form id='payment-form'>
            <PaymentElement id='payment-element' />
            <Box display='flex' justifyContent='center' alignItems='center' style={{marginTop: '1.5em', marginBottom: '1.5em'}}>
                <Button
                    onClick={handleSubmit}
                    component={Box}
                    color='primary'
                    variant='contained'
                    disabled={isLoading || !stripe || !elements}
                >
                    <span id='button-text'>
                        {isLoading ? <Loader height={10} /> : 'Pay now'}
                    </span>
                </Button>
            </Box>
            
            {/* Show any error or success messages */}
            {message && <div id='payment-message'>{message}</div>}
        </form>
    );
}