import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import admaticClient from 'config/api/AdmaticClient'
import { onError } from 'config/lib/errorLib'
import { Storage } from 'aws-amplify'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ViewCopyModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
    const api = new admaticClient()

	console.log('props: ', props)
	const buyerDetails = props.buyer

    const [media, setMedia] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getMedia()
    }, [])

    async function getMedia() {
        try {
            const media = await api.getBuyerCopy(buyerDetails.buyerId)
            console.log('media: ', media)

            // for (let item of media.files) {
            //     console.log(item.key.split('/').slice(-1)[0])
            // }

            setMedia(media.files || [])
            setLoading(false)
        } catch(e) {
            onError(e)
        }
        //setLoading(false)
    }

    async function downloadFile(file) {
        console.log(file.key)
        let url = await Storage.get(file.key, {
            customPrefix: {
                public: ''
            }
        })
        console.log('url: ', url)
        window.open(url)
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					{buyerDetails.name} Copy
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
                <Grid container spacing={1}>
                {(media && media.length > 0) &&
                    media.map((file, i) => {
                        let copyId = file.key.split('/').slice(-1)[0]
                        if (buyerDetails.copy && buyerDetails.copy[copyId]) {
                            return (
                                <Grid item key={i} xs={6}>
                                    {/*<img src={image.url} onClick={() => copyImageUrl(image.url)} style={{cursor: 'pointer', maxWidth: '100%'}} />*/}
                                    <Box
                                        component='p'
                                        marginBottom='1rem'
                                        fontWeight='700'
                                        lineHeight='1.2'
                                        fontSize='1rem'
                                        marginTop='1rem'
                                        textAlign='center'
                                        //color='#f5365c'
                                        className='hover-text'
                                        onClick={() => downloadFile(file)}
                                    >
                                        {buyerDetails.copy[copyId].name}
                                    </Box>
                                    <Box
                                        component='p'
                                        marginBottom='1rem'
                                        fontWeight='300'
                                        lineHeight='1.2'
                                        fontSize='1rem'
                                        marginTop='1rem'
                                        textAlign='center'
                                        //color='#f5365c'
                                        //className='hover-text'
                                        //onClick={() => downloadFile(file)}
                                    >
                                        {buyerDetails.copy[copyId].category}
                                    </Box>
                                </Grid>
                            )
                        }
                    })
                }
                </Grid>
			</DialogContent>
			
			<DialogActions>
                <Button
					component={Box}
					onClick={() => props.close()}
					color='primary'
					marginLeft='auto!important'
				>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	)
}