import React, { useState, useCallback, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import { useDropzone } from 'react-dropzone'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='down' ref={ref} {...props} />
})

function UploadImage(props) {
    const classes = {...useStyles(), ...useStylesDialog()}
    const cropperRef = useRef(null)

    const [image, setImage] = useState(props.logo ? props.logo : null)
    const [showCropModal, setShowCropModal] = useState(false)
    const [uncroppedImage, setUncroppedImage] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const onDrop = useCallback(acceptedFiles => {
        console.log(acceptedFiles)
        setShowCropModal(true)
        let preview = URL.createObjectURL(acceptedFiles[0])
        console.log('preview: ', preview)
        setUncroppedImage(preview)
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: 'image/jpeg, image/png'})

    const onCrop = () => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        fetch(cropper.getCroppedCanvas().toDataURL())
            .then(res => res.blob())
            .then(blob => {
                if (cropper.getCroppedCanvas()) {
                    setCroppedImage(blob)
                    setImage(cropper.getCroppedCanvas().toDataURL())
                }
            })
    }

    function submitImage() {
        props.updateLogo(croppedImage)
        setShowCropModal(false)
    }

    function brokenImage() {
        console.log('broken image...')
        console.log(image)
        setImage(null)
    }

    return (
        <>
            {(image && !props.noPreview) ? (
                <div {...getRootProps()} className='dropzone'>
                    <input {...getInputProps()} />
                    <img onError={brokenImage} src={image} alt='dropzone' />
                </div>
            ) : (
                <div {...getRootProps()} className='dropzone'>
                    <input {...getInputProps()} />
                    {props.button ? (
                        <Button  variant='contained' size='medium' color='primary'>
                            Upload Image
                        </Button>
                    ) : (
                        <p>Click to select an image</p>
                    )}
                </div>
            )}
            
            {showCropModal &&
                <Dialog
                    open={showCropModal}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setShowCropModal(false)}
                    aria-labelledby='alert-dialog-slide-title'
                    aria-describedby='alert-dialog-slide-description'
                >
                    <div className={classes.dialogHeader}>
                        <Typography
                            variant='h5'
                            component='h5'
                            className={classes.dialogTitle}
                        >
                            Crop Image
                        </Typography>
                        <IconButton onClick={() => setShowCropModal(false)}>
                            <Clear />
                        </IconButton>
                    </div>
                
                    <DialogContent>
                        <Cropper
                            src={uncroppedImage}
                            style={{ height: 400, width: "100%" }}
                            // Cropper.js options
                            aspectRatio={props.flexible ? null : 1/1}
                            guides={true}
                            crop={onCrop}
                            ref={cropperRef}
                        />
                    </DialogContent>
                
                    <DialogActions>
                        <Button
                            onClick={() => setShowCropModal(false)}
                            color='primary'
                        >
                            Close
                        </Button>
                
                        <Button
                            component={Box}
                            onClick={() => submitImage()}
                            color='primary'
                            variant='contained'
                            marginLeft='auto!important'
                        >
                            Submit Image
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default UploadImage