import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Add from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Loader from 'components/general/Loader'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'
//import { NotificationManager } from 'react-notifications'
import ContentCard from 'components/buyer/ContentCard'
import UploadCopyModal from 'components/buyer/UploadCopyModal'
import { Storage } from 'aws-amplify'

import DetailsContext from 'config/context/DetailsContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function CopyUpload() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const api = new buyerClient()

    const details = useContext(DetailsContext)
    console.log(details)

    const [media, setMedia] = useState([])
    const [buyerDetails, setBuyerDetails] = useState(null)
    const [showUploadCopyModal, setShowUploadCopyModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)

    // empty array argument prevents endless loop
    useEffect(() => {
        getMedia()
    }, [])

    async function getMedia() {
        try {
            const buyerDetails = await api.getBuyerDetails(details.buyerId)
            console.log('buyerDetails: ', buyerDetails)
            const media = await api.getBuyerCopy(details.buyerId)
            console.log('media: ', media)

            // for (let item of media.files) {
            //     console.log(item.key.split('/').slice(-1)[0])
            // }

            setMedia(media.files || [])
            setBuyerDetails(buyerDetails)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
        //setLoading(false)
    }

    // async function copyImageUrl(url) {
    //     console.log('copy url: ', url)
    //     // try {
    //     //     const file = await api.getMedia(url)
    //     // } catch(e) {
    //     //     onError(e)
    //     // }

    //     //let image = document.getElementById(url)
    //     navigator.clipboard.writeText(url)
    //     NotificationManager.success('Image URL copied')
    // }

    async function downloadFile(file) {
        console.log(file.key)
        let url = await Storage.get(file.key, {
            customPrefix: {
                public: ''
            }
        })
        console.log('url: ', url)
        window.open(url)
    }

    async function deleteFile(file) {
        console.log('delete: ', file.key)
        //let path = 'buyers/' + details.buyerId + '/copy/' + file.key
        try {
            await Storage.remove(file.key, {
                bucket: 'admatic-user-upload',
                customPrefix: {
                   public: ''
                }
            })

            //TODO; remove from dynamo

            openSuccessAlert('File Deleted')
            getMedia()
        } catch(e) {
            onError(e)
        }
    }

    function showDeleteWarning(file) {
        setAlert(
            <ReactBSAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title='Warning'
                onConfirm={() => deleteFile(file)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle='warning'
                confirmBtnText='Delete File'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                If you delete this file it will no longer be availble to the our team.
            </ReactBSAlert>
        )
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(null)}
                onCancel={() => setAlert(null)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                marginTop='2rem'
                classes={{ root: classes.containerRoot }}
            >   
                <Grid container>
                    <Grid item xs={12} xl={12}>
                        <ContentCard 
                            title={'Copy Upload'}
                            body={'This is an area you can upload you ad copy. When you upload a file, our team is notified and can access it immediately.'}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} xl={12}>
                        <Box display='flex' justifyContent='center' style={{margin: '2em'}}>
                            <Button variant='contained' size='medium' color='primary' onClick={() => setShowUploadCopyModal(true)}>
                                <Box component={Add} position='relative' top='-2px' />{' '}
                                Upload Copy
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                
                {!loading ? (
                    <Grid container spacing={1}>
                        {(media && media.length > 0) &&
                            media.map((file, i) => {
                                let copyId = file.key.split('/').slice(-1)[0]
                                if (buyerDetails.copy && buyerDetails.copy[copyId]) {
                                    return (
                                        <Grid item key={i} xs={3}>
                                            {/*<img src={image.url} onClick={() => copyImageUrl(image.url)} style={{cursor: 'pointer', maxWidth: '100%'}} />*/}
                                            <Box
                                                component='p'
                                                marginBottom='1rem'
                                                fontWeight='700'
                                                lineHeight='1.2'
                                                fontSize='1rem'
                                                marginTop='1rem'
                                                textAlign='center'
                                                //color='#f5365c'
                                                className='hover-text'
                                                onClick={() => downloadFile(file)}
                                            >
                                                {buyerDetails.copy[copyId].name}
                                            </Box>
                                            <Box
                                                component='p'
                                                marginBottom='1rem'
                                                fontWeight='300'
                                                lineHeight='1.2'
                                                fontSize='1rem'
                                                marginTop='1rem'
                                                textAlign='center'
                                                //color='#f5365c'
                                                //className='hover-text'
                                                //onClick={() => downloadFile(file)}
                                            >
                                                {buyerDetails.copy[copyId].category}
                                            </Box>
                                            <Box
                                                component='p'
                                                marginBottom='1rem'
                                                fontWeight='300'
                                                lineHeight='1.2'
                                                fontSize='1rem'
                                                marginTop='1rem'
                                                textAlign='center'
                                                color='#f5365c'
                                                className='hover-text'
                                                onClick={() => showDeleteWarning(file)}
                                            >
                                                Remove
                                            </Box>
                                        </Grid>
                                    )
                                }
                            })
                        }
                    </Grid>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader />
                    </Box>
                )}

                {showUploadCopyModal && 
                    <UploadCopyModal 
                        isOpen={showUploadCopyModal}
                        close={() => setShowUploadCopyModal(false)}
                        details={details}
                        refreshMedia={() => getMedia()}
                    />
                }
            </Container>
        </>
    );
}

export default CopyUpload