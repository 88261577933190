import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import MoreVert from '@material-ui/icons/MoreVert'
// core components
import componentStyles from 'assets/theme/components/cards/alternative/card-page-visits-alternative.js'
//import WarningModal from 'components/general/WarningModal'
import OrderModal from 'components/buyer/OrderModal'
//import Logo from 'components/general/Logo'
import Loader from 'components/general/Loader'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'
import moment from 'moment'

const useStyles = makeStyles(componentStyles)

export default function OrdersTable(props) {
    const classes = useStyles()
    const api = new buyerClient()

    const orders = props.orders || []

    const [ordersFilter, setOrdersFilter] = useState('all')
    //const [showWarningModal, setShowWarningModal] = useState(false)
    const [showOrderModal, setShowOrderModal] = useState(false)
    const [order, setOrder] = useState(null)
    const [alert, setAlert] = useState(false)

    function closeSuccessAlert() {
        setAlert(false)
    }

    function openOrderModal(order) {
        setOrder(order)
        setShowOrderModal(true)
    }

    function closeOrderModal() {
        setShowOrderModal(false)
        setOrder(null)
    }

    function filterOrders(filter) {
        setOrdersFilter(filter)
        props.filterOrders(filter)
    }

    async function dismissRejectedOrder() {
        try {   
            const result = await api.dismissRejectedOrder(order.buyerId, order.orderId)
            console.log('order deleted: ', result)

            closeOrderModal()
            openSuccessAlert('This order has been dismissed.')
            props.refreshOrders()
        } catch(e) {
            onError(e)
        }
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => closeSuccessAlert()}
                onCancel={() => closeSuccessAlert()}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    function getStatusColor(status) {
        if (status === 'pending') {
            return 'bgInfo'
        }
        if (status === 'approved') {
            return 'bgSuccess'
        }
        if (status === 'rejected') {
            return 'bgWarning'
        }
     }

    const tableHead = ['Product', 'Publisher', 'Campaign Name', 'Start Date', 'Status', ''];
    
    return (
        <>
            {alert}
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    subheader={
                        <Grid
                            container
                            component={Box}
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Grid item xl={10}>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    marginBottom='0!important'
                                >
                                    <Box component='span'>All Orders</Box>
                                </Box>
                            </Grid>
                            <Grid item xl={2}>
                                <FormGroup style={{marginBottom: '0'}}>
                                    <FormControl variant='outlined' fullWidth>
                                        <Select
                                            multiple={false}
                                            defaultValue={'all'}
                                            IconComponent={KeyboardArrowDown}
                                            value={ordersFilter}
                                            onChange={(e) => filterOrders(e.target.value)}
                                        >
                                            <MenuItem value={'all'}>All</MenuItem>
                                            <MenuItem value={'pending'}>Pending</MenuItem>
                                            <MenuItem value={'approved'}>Approved</MenuItem>
                                            <MenuItem value={'rejected'}>Rejected</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                >
                </CardHeader>
                
                <TableContainer>
                    {!props.loading ? (
                        <Box component={Table} alignItems='center' marginBottom='0!important'>
                            {orders.length === 0 &&
                                <caption>Place an order from the Media Catalog page</caption>
                            }
                            <TableHead>
                                <TableRow>
                                    {tableHead.map((prop, key) => (
                                        <TableCell
                                            key={key}
                                            classes={{
                                                root: classes.tableCellRoot + ' ' + classes.tableCellRootHead,
                                            }}
                                        >
                                            {prop}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            
                            <TableBody>
                                {orders.map((order, key) => (
                                    <TableRow key={key}>
                                        <TableCell classes={{ root: classes.tableCellRoot }}>
                                            {order.product.name}
                                        </TableCell>
                    
                                        <TableCell classes={{ root: classes.tableCellRoot }}>
                                            {order.publisher.name}
                                        </TableCell>

                                        <TableCell classes={{ root: classes.tableCellRoot }}>
                                            {order.campaignName}
                                        </TableCell>

                                        <TableCell classes={{ root: classes.tableCellRoot }}>
                                            {moment.unix(order.startDate).format('DD/MM/YY')}
                                        </TableCell>
                                        
                                        <TableCell classes={{ root: classes.tableCellRoot }}>
                                            <Box paddingTop='.35rem' paddingBottom='.35rem'>
                                                <Box
                                                    marginRight='10px'
                                                    component='i'
                                                    width='.375rem'
                                                    height='.375rem'
                                                    borderRadius='50%'
                                                    display='inline-block'
                                                    className={
                                                        classes.verticalAlignMiddle +
                                                        ' ' +
                                                        classes[getStatusColor(order.status)]
                                                    }
                                                ></Box>
                                                {order.status}
                                            </Box>
                                        </TableCell>
                                        
                                        <TableCell
                                            classes={{ root: classes.tableCellRoot }}
                                            align='right'
                                        >
                                            <DropdownComponent 
                                                id={key} 
                                                openOrder={() => openOrderModal(order)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Box>
                    ) : (
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                            <Loader />
                        </Box>
                    )}
                </TableContainer>

                {showOrderModal &&
                    <OrderModal
                        isOpen={showOrderModal}
                        close={() => closeOrderModal()}
                        order={order}
                        dismissRejectedOrder={() => dismissRejectedOrder()}
                    />
                }
            </Card>
        </>
    )
}

const DropdownComponent = ({ id, openOrder }) => {
    const theme = useTheme()
    const [anchorEl, setAnchorEl] = useState(null)
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    
    const handleClose = () => {
        setAnchorEl(null)
    }

    function openOrderModal() {
        setAnchorEl(null)
        openOrder()
    }
    
    return (
        <>
            <Box
                aria-controls={'simple-menu-' + id}
                aria-haspopup='true'
                onClick={handleClick}
                size='small'
                component={Button}
                width='2rem!important'
                height='2rem!important'
                minWidth='2rem!important'
                minHeight='2rem!important'
            >
                <Box
                    component={MoreVert}
                    width='1.25rem!important'
                    height='1.25rem!important'
                    position='relative'
                    top='2px'
                    color={theme.palette.gray[500]}
                />
            </Box>
            <Menu
                id={'simple-menu-' + id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => openOrderModal()}>View Details</MenuItem>
            </Menu>
        </>
    )
}
