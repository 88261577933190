import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import Info from '@material-ui/icons/Info'
import CategoryCard from 'components/buyer/CategoryCard'
import ProductCard from 'components/buyer/ProductCard'
import ProductModal from 'components/buyer/ProductModal'
import CartModal from 'components/buyer/CartModal'
import AboutModal from 'components/buyer/AboutModal'
import StripeModal from 'components/buyer/StripeModal'
import guestClient from 'config/api/GuestClient'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import { categories } from 'config/constants/Constants'
import { useHistory, useParams } from 'react-router-dom'
import RequestCallbackModal from 'components/guest/RequestCallbackModal'

import CompanyContext from 'config/context/CompanyContext'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function MediaCatalog() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const history = useHistory()
    const api = new guestClient()

    const company = useContext(CompanyContext)
    console.log('company: ', company)

    let params = useParams()
    console.log('media category: ', params.mediaCategory)

    const [stage, setStage] = useState('category')
    const [category, setCategory] = useState(params.mediaCategory ? params.mediaCategory.charAt(0).toUpperCase() + params.mediaCategory.slice(1) : null)
    
    const [publisher, setPublisher] = useState(null)
    const [publishers, setPublishers] = useState([])
    const [showPublishers, setShowPublishers] = useState([])
    const [showAboutModal, setShowAboutModal] = useState(false)
    
    const [products, setProducts] = useState([])
    const [showProducts, setShowProducts] = useState([])

    const [product, setProduct] = useState(null)
    const [showProductModal, setShowProductModal] = useState(false)

    const [cart, setCart] = useState([])
    const [showCartModal, setShowCartModal] = useState(false) 

    const [showStripeModal, setShowStripeModal] = useState(false)
    const [details, setDetails] = useState(null)
    const [payment, setPayment] = useState(null)

    const [showRequestCallback, setShowRequestCallback] = useState(false)
    
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(true)

    // empty array argument prevents endless loop
    useEffect(() => {
        getAll()
    }, [])

    function goBack() {
        history.goBack()
        if (stage === 'products') {
            setStage('publisher')
            setPublisher(null)
        }
        if (stage === 'publisher') {
            setStage('category')
            setCategory(null)
        }
    }

    async function getAll() {
        try {
            const pubResult = await api.getAllPublishers(company.id)
            console.log('publishers: ', pubResult)

            const prodResult = await api.getAllProducts(company.id)
            console.log('products: ', prodResult)

            // list alphabetically
            if (pubResult) {
                pubResult.sort((a, b) => {
                    let nameA = a.name.toLowerCase()
                    let nameB = b.name.toLowerCase()
                    if(nameA < nameB) { return -1 }
                    if(nameA > nameB) { return 1 }
                    return 0
                })
            }

            if (params.mediaCategory) {
                // get publisherIds with products in category
                let pIds = []
                for (var product of prodResult) {
                    if (product.category.toLowerCase() === params.mediaCategory) {
                        if (pIds.indexOf(product.publisherId) === -1) {
                            pIds.push(product.publisherId)
                        }
                    }
                }

                // get publishers whose IDs had products in category
                let catPublishers = pubResult.filter((publisher) => {
                    return (pIds.indexOf(publisher.publisherId) > -1)
                })

                setShowPublishers(catPublishers)
                setCategory(category)

                if (params.publisherId) {
                    let publisherIndex = pubResult.findIndex(pub => pub.publisherId === params.publisherId)
                    console.log('publisher index: ', publisherIndex)
                    let publisher = pubResult[publisherIndex]
                    // show products that selected publisher has in selected category
                    let pubProducts = prodResult.filter((product) => {
                        return (product.publisherId === publisher.publisherId)
                    })
                    console.log('pulisher products: ', pubProducts)

                    let pubCatProducts = pubProducts.filter((product) => {
                        return (product.category === category)
                    })

                    setShowProducts(pubCatProducts)
                    setPublisher(publisher)
                    setStage('products')
                }
                else {
                    setStage('publisher')
                }
            }
            else {
                setStage('category')
            }

            setPublishers(pubResult)
            setProducts(prodResult)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function selectCategory(category) {
        history.push('/catalog/' + category.toLowerCase())
        console.log('select category: ', category)
        // show providers that have ad products in selected category

        // get publisherIds with products in category
        let pIds = []
        for (var product of products) {
            if (product.category === category) {
                if (pIds.indexOf(product.publisherId) === -1) {
                    pIds.push(product.publisherId)
                }
            }
        }

        // get publishers whose IDs had products in category
        let catPublishers = publishers.filter((publisher) => {
            return (pIds.indexOf(publisher.publisherId) > -1)
        })

        setShowPublishers(catPublishers)
        setCategory(category)
        setStage('publisher')
    }

    function selectPublisher(publisher) {
        history.push('/catalog/' + category.toLowerCase() + '/' + publisher.publisherId)
        console.log('select publisher: ', publisher)
        // show products that selected publisher has in selected category
        let pubProducts = products.filter((product) => {
            return (product.publisherId === publisher.publisherId)
        })
        console.log('pulisher products: ', pubProducts)

        let pubCatProducts = pubProducts.filter((product) => {
            return (product.category === category)
        })

        setShowProducts(pubCatProducts)
        setPublisher(publisher)
        setStage('products')
    }

    function openProductModal(vProduct) {
        // open product details modal that allows user to add to cart
        console.log('view product: ', vProduct)
        console.log('publisher: ', publisher)
        setProduct(vProduct)
        setShowProductModal(true)
    }

    function closeProductModal() {
        setShowProductModal(false)
        setProduct(null)
    }

    function addToCart(item) {
        console.log('add item: ', item)

        setCart(cart => [...cart, item])
        setShowProductModal(false)
        openSuccessAlert('Added to cart')
    }

    function removeFromCart(removeItem) {
        console.log('remove item: ', removeItem)

        const index = cart.findIndex(item => item.product.productId === removeItem.product.productId)
        console.log('index in cart: ', index)

        let newCart = [...cart]

        newCart.splice(index, 1)
        setCart(newCart)

        if (newCart.length === 0) {
            setShowCartModal(false)
        }
    }

    function showStripe(payment, details) {
        console.log('stripe')
        setPayment(payment)
        setDetails(details)
        setShowStripeModal(true)
    }

    async function submitEnquiry(details, campaignName, message, variant) {
        try {
            const result = await api.submitProductEnquiry(company.id, campaignName, message, details, publisher, product, variant)
            console.log('result: ', result)

            setShowProductModal(false)
            openSuccessAlert('Your enquiry has been submitted. A member of our team will contact you shortly.')
        } catch(e) {
            onError(e)
        }
    }

    async function sendInvoice(paymentId) {
        try {
            const result = await api.sendUnpaidInvoice(details.email, paymentId, details.buyerId, company.id)
            console.log('result: ', result)

            //setShowCartModal(false)
            //setCart([])
            openSuccessAlert('Your invoice has been sent to you via email. Please pay this promptly via EFT.')
        } catch(e) {
            onError(e)
        }
    }

    function showEft(paymentId) {
        setAlert(
            <ReactBSAlert
                info
                style={{ display: 'block', marginTop: '-100px' }}
                title='Important'
                onConfirm={() => sendInvoice(paymentId)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Send Invoice'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
                customClass='step--cart--pay-eft--confirm'
            >
                After you click 'Send Invoice' you will be sent a VAT invoice via email. This can be paid manually via Electronic Fund Transfer. 
                <br />
                <br />
                Once payment has been received the payment status of your order will be updated and you will be notified via email.
            </ReactBSAlert>
        )
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='2rem'
                paddingTop='5rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid 
                    container
                    spacing={0}
                    direction='row'
                    alignItems='center'
                    justify='center'
                    style={{ marginBottom: '30px' }}
                >
                    <Grid item xs={10}>
                        <Grid container>
                            <Grid item xs={4}>
                                {stage !== 'category' &&
                                    <Box display='flex' flexWrap='wrap' justifyContent='flex-start'>
                                        <Button variant='contained' size='small' color='secondary' onClick={() => goBack()}>
                                            <Box component={ArrowBack} position='relative' top='-2px' />{' '}
                                            Back
                                        </Button>
                                    </Box>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    justifyContent='center'
                                    textAlign='center'
                                >   
                                    {stage === 'category' &&
                                        <Box component='span' style={{color: '#fff'}}>Select a category...</Box>
                                    }
                                    {stage === 'publisher' &&
                                        <Box component='span' style={{color: '#fff'}}>Select a publisher...</Box>
                                    }
                                    {stage === 'products' &&
                                        <Box component='span' style={{color: '#fff'}}>Select products...</Box>
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                {cart.length > 0 &&
                                    <Box display='flex' flexWrap='wrap' justifyContent='flex-end'>
                                        <Button variant='contained' size='small' color='primary' onClick={() => setShowCartModal(true)} className='step--cart'>
                                            <Box component={ShoppingCart} position='relative' top='-2px' />{' '}
                                            View Cart ({cart.length})
                                        </Button>
                                    </Box>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {stage === 'products' &&
                    <Grid
                        container
                        alignItems='center'
                        justify='center'
                        style={{ marginBottom: '30px' }}
                    >
                        <Grid item>
                            <Button variant='contained' size='medium' color='secondary' onClick={() => setShowAboutModal(true)} className='step--about-publisher'>
                                <Box component={Info} position='relative' top='-2px' />{' '}
                                About {publisher.name}
                            </Button>
                        </Grid>
                    </Grid>
                }

                {!loading ? (
                    <Grid 
                        container
                        spacing={0}
                        direction='row'
                        alignItems='center'
                        justify='center'
                    >
                        <Grid item xs={10}>
                            <Grid 
                                container 
                                direction='row'
                                alignItems='center'
                                justify='center'
                            >
                                {stage === 'category' &&
                                    <>
                                        {categories.map((category, i) => {
                                            return (
                                                <Grid item xs={12} md={company.id === 'gi' ? 7 : 4} key={i}>
                                                    <CategoryCard category={category} onClick={() => selectCategory(category)} icon className='step--main'/>
                                                </Grid>
                                            )
                                        })}
                                    </>
                                }
                                {stage === 'publisher' &&
                                    <>
                                        {showPublishers.map((publisher, i) => {
                                            return (
                                                <Grid item xs={12} md={4} key={i}>
                                                    <CategoryCard category={publisher.name} onClick={() => selectPublisher(publisher)} logo={publisher.logo} className='step--publisher'/>
                                                </Grid>
                                            )
                                        })}
                                    </>
                                }
                                {stage === 'products' &&
                                    <>
                                        {showProducts.map((mapProduct, i) => {
                                            return (
                                                <Grid item xs={12} md={4} key={i}>
                                                    <ProductCard product={mapProduct} onClick={() => openProductModal(mapProduct)} />
                                                </Grid>
                                            )
                                        })}
                                    </>
                                }
                            </Grid>
                        </Grid>
                        
                        <Box style={{display: 'flex', flexDirection: 'column'}}>
                            <Button style={{margin: '0.5rem'}} variant='contained' size='medium' color='secondary' onClick={() => setShowRequestCallback(true)} className='step--contact'>
                                {company.id === 'gi' ? 'Speak With A Guaranteed Irish Advertising Expert' : 'Speak With An Advertising Expert'}
                            </Button>
                            <Button style={{margin: '0.5rem'}} variant='contained' size='medium' color='secondary' onClick={() => history.push('/login')} className='step--login'>
                                Have an Account? Log In
                            </Button>
                        </Box>
                    </Grid>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader 
                            color={company.id === 'gi' ? 'white' : null}
                        />
                    </Box>
                )}
                {company.id === 'gi' &&
                    <Grid container style={{paddingBottom: '20px', paddingTop: '20px'}}>
                        <Grid item xs={12} component={Box} textAlign='center'>
                            <a
                                href='https://admatic.ie'
                                //onClick={(e) => e.preventDefault()}
                                className={classes.footerLinks + ' powered-by'}
                                style={{color: 'white'}}
                            >
                                Powered by admatic.ie
                            </a>
                        </Grid>
                    </Grid>
                }
            </Container>

            {showRequestCallback &&
                <RequestCallbackModal 
                    isOpen={showRequestCallback}
                    openSuccessAlert={(text) => openSuccessAlert(text)}
                    close={() => setShowRequestCallback(false)}
                />
            }

            {showProductModal &&
                <ProductModal
                    isOpen={showProductModal}
                    close={() => closeProductModal()}
                    product={product}
                    publisher={publisher} 
                    addToCart={(item) => addToCart(item)}
                    register={() => history.push('/register')}
                    submitEnquiry={(details, campaignName, message, variant) => submitEnquiry(details, campaignName, message, variant)}
                    type='guest'
                />
            }

            {showAboutModal &&
                <AboutModal
                    isOpen={showAboutModal}
                    close={() => setShowAboutModal(false)}
                    publisher={publisher}
                    category={category}
                />
            }

            {showCartModal &&
                <CartModal 
                    isOpen={showCartModal}
                    close={() => setShowCartModal(false)}
                    cart={cart}
                    details={details}
                    removeFromCart={(item) => removeFromCart(item)}
                    setCart={(cart) => setCart(cart)}
                    showStripe={(payment, details) => showStripe(payment, details)}
                    showEft={(paymentId) => showEft(paymentId)}
                    type='guest'
                    updateDetails={(details) => setDetails(details)}
                />
            }

            {showStripeModal &&
                <StripeModal 
                    isOpen={showStripeModal}
                    close={() => setShowStripeModal(false)}
                    details={details}
                    payment={payment}
                    type={'guest'}
                />
            }
        </>
    );
}

export default MediaCatalog