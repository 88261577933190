import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Loader from 'components/general/Loader'
import buyerClient from 'config/api/BuyerClient'
//import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function PaymentCompleted() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const history = useHistory()
    const theme = useTheme()
    const api = new buyerClient()
    const search = useLocation().search

    const [payment, setPayment] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        //getPaymentUuid()
    }, [])

    // could we do a get payments in api and match paymentId to update so not relying on redirect?
    // payment request payment received webhook?
    // async function getPaymentUuid() {
    //     try {
    //         const paymentUuid = new URLSearchParams(search).get('paymentUuid')
    //         console.log('payment uuid: ', paymentUuid)

    //         const result = await api.getPaymentDetails(paymentUuid)
    //         console.log('result: ', result)

    //         const updateResult = await api.updatePaymentUuid(result.payment.myRef, paymentUuid)
    //         console.log('update result: ', updateResult)
            
    //         setPayment(result.payment)
    //         setLoading(false)
    //     } catch(e) {
    //         onError(e)
    //     }
    // }


    return (
        <>
            <Container
                maxWidth={false}
                component={Box}
                marginTop='0rem'
                classes={{ root: classes.containerRoot }}
            >
                {!loading ? (
                    <Box component={Grid} container justifyContent='center' style={{paddingTop: '2rem'}}>
                        <Grid item xs={6} xl={6}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardContent classes={{ root: classes.cardContent }}>
                                    <Box textAlign='center'>
                                        <CheckCircle className='register-icon' />
                                    </Box>

                                    <Typography
                                        component='h2'
                                        variant='h2'
                                        className={classes.typographyH2}
                                        style={{textAlign: 'center', marginTop: '1.5rem'}}
                                    >
                                        Payment Completed
                                    </Typography>

                                    <Box
                                        color={theme.palette.gray[600]}
                                        textAlign='center'
                                        marginBottom='1rem'
                                        marginTop='1.5rem'
                                        fontSize='1rem'
                                    >
                                        <Box fontSize='100%' fontWeight='400' component='small'>
                                            You will be sent a VAT invoice via email in the next few minutes.
                                        </Box>
                                    </Box>
                                    <Box
                                        color={theme.palette.gray[600]}
                                        textAlign='center'
                                        marginBottom='1rem'
                                        marginTop='1.5rem'
                                        fontSize='1rem'
                                    >
                                        <Box fontSize='100%' fontWeight='400' component='small'>
                                            You should now create an account below in order to upload copy and track future orders.
                                        </Box>
                                    </Box>
                                    
                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            onClick={() => history.push('/register')}
                                        >
                                            Create Account
                                        </Button>
                                    </Box>

                                    <Box
                                        color={theme.palette.gray[600]}
                                        textAlign='center'
                                        marginBottom='1rem'
                                        marginTop='1.5rem'
                                        fontSize='1rem'
                                    >
                                        <Box fontSize='100%' fontWeight='400' component='small'>
                                            Or contact Stuart: <a href='mailto:stuart@admatic.ie'>stuart@admatic.ie</a> | <a href='tel:353857100458'>085 710 0458</a>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Box>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '20vh'}}>
                        <Loader />
                    </Box>
                )}
            </Container>
        </>
    );
}

export default PaymentCompleted