import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

function TeamMemberRow(props) {
    const user = props.user
    const details = props.details
    const buyerDetails = props.buyerDetails || {}
    
    return (
        <>
            <Grid 
                container
                spacing={1}
                justify='center'
                alignItems='center'
            >
                <Grid item xs={8}>
                    <Grid 
                        container
                        spacing={0}
                        direction='column'
                        justify='center'
                        alignItems='flex-start'
                    >
                        <Grid item>
                            <Box
                                component='p'
                                marginBottom='1rem'
                                fontWeight='500'
                                lineHeight='1.2'
                                fontSize='1rem'
                                marginTop='0'
                            >
                                {user.name} ({user.permissions})
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box
                                component='p'
                                marginBottom='1rem'
                                fontWeight='300'
                                lineHeight='1.2'
                                fontSize='1rem'
                                marginTop='0'
                            >
                                {user.email}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}> 
                    {(Object.keys(buyerDetails).length > 0 || (props.admatic && !details.permissions)) &&
                        <Grid 
                            container
                            spacing={0}
                            direction='row'
                            justify='flex-end'
                            alignItems='center'
                        >
                            {((user.userId !== buyerDetails.rootUserId) && (user.userId !== details.userId)) &&
                                <Grid item>
                                    {user.confirmed ? (
                                        <Box
                                            component='p'
                                            fontWeight='300'
                                            lineHeight='1.2'
                                            fontSize='1rem'
                                            marginTop='0'
                                            textAlign='right'
                                            color='#f5365c'
                                            className='hover-text'
                                            onClick={() => props.deactivateUser()}
                                        >
                                            Deactivate
                                        </Box>
                                    ) : (
                                        <Box
                                            component='p'
                                            fontWeight='300'
                                            lineHeight='1.2'
                                            fontSize='1rem'
                                            marginTop='0'
                                            textAlign='right'
                                            color='#2dce89'
                                            className='hover-text'
                                            onClick={() => props.activateUser()}
                                        >
                                            Activate
                                        </Box>
                                    )}
                                </Grid>
                            }
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default TeamMemberRow