import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
//import FormGroup from '@material-ui/core/FormGroup'
//import FormLabel from '@material-ui/core/FormLabel'
//import OutlinedInput from '@material-ui/core/OutlinedInput'
import Typography from '@material-ui/core/Typography'
import Parser from 'html-react-parser'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function ContentCard(props) {
    const classes = useStyles()

    const content = props.content
    //console.log('content: ', content)
    //console.log(typeof(props.body))

    const [showBody, setShowBody] = useState(props.hideBody ? false : true)
    const [showAll, setShowAll] = useState(false)
    
    return (
        <>
            <Card classes={{ root: classes.cardRoot }}>
                <CardHeader
                    subheader={
                        <Grid
                            container
                            component={Box}
                            alignItems='center'
                            justifyContent='space-between'
                        >
                            <Grid item xs='auto'>
                                <Box
                                    component={Typography}
                                    variant='h2'
                                    marginBottom='0!important'
                                >
                                    {props.title}
                                </Box>
                            </Grid>
                            
                            {props.collapsable &&
                                <Grid item xs='auto'>
                                    <Box
                                        justifyContent='flex-end'
                                        display='flex'
                                        flexWrap='wrap'
                                    >
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='small'
                                            onClick={() => setShowBody(!showBody)}
                                        >
                                            {showBody ? 'Close' : 'Open'}
                                        </Button>
                                    </Box>
                                </Grid>
                            }
                        </Grid>
                    }
                    classes={{ root: classes.cardHeaderRoot }}
                >
                </CardHeader>

                {showBody &&
                    <CardContent>
                        <Grid 
                            container
                            direction='column'
                        >
                            <Grid item>
                                {props.readMore ? (
                                    <Box
                                        marginBottom='1rem'
                                        fontWeight='300'
                                        lineHeight='1.7'
                                        fontSize='1rem'
                                        //marginTop="1.5rem"
                                    >
                                        {showAll ? (
                                            <>{typeof(props.body) === 'string' ? Parser(props.body) : props.body}</>
                                        ) : (
                                            <>{typeof(props.body) === 'string' ? Parser(props.body.substring(0, 250) + '...') : props.body}</>
                                        )}
                                    </Box>
                                ) : (
                                    <Box
                                        marginBottom='1rem'
                                        fontWeight='300'
                                        lineHeight='1.7'
                                        fontSize='1rem'
                                        //marginTop="1.5rem"
                                    >
                                        {typeof(props.body) === 'string' ? Parser(props.body) : props.body}
                                    </Box>
                                )}
                            </Grid>
                        </Grid>
                        {props.readMore &&
                            <Box display='flex' justifyContent='center'>
                                <Grid item>
                                    <Button
                                        component={Box}
                                        onClick={() => setShowAll(!showAll)}
                                        color='primary'
                                        //variant='contained'
                                    >
                                        {showAll ? (
                                            <>Read Less</>
                                        ) : (
                                            <>Read More</>
                                        )}
                                    </Button>
                                </Grid>
                            </Box>
                        }
                    </CardContent>
                }
            </Card>
        </>
    );
}

export default ContentCard