import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { Auth } from 'aws-amplify'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/components/cards/cards/card-button.js'

const useStyles = makeStyles(componentStyles)

function InactiveCard(props) {
    const classes = useStyles()

    function logout() {
        Auth.signOut().then((user) => {
            console.log(user)
        }).catch(err => 
            onError(err)
        )
    }
    
    return (
        <>
            <Card className={classes.cardRoot} style={{width: '400px'}}>
                <CardContent>
                    <Box display='flex' flexDirection='column' justify='center' alignItems='center'>
                        <Box
                            component={Typography}
                            variant='h3'
                            marginBottom='1rem!important'
                            textAlign='center'
                        >
                            Account Inactive
                        </Box>

                        <Box
                            component='p'
                            marginBottom='1rem'
                            fontWeight='300'
                            lineHeight='1.7'
                            fontSize='1rem'
                            marginTop='0'
                            textAlign='center'
                        >
                            Your account is not active, if you just registered please check back after you have recieved a verification call from a member of our team.
                        </Box>
                        

                        <Button variant='contained' color='primary' onClick={() => logout()}>
                            Logout
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}

export default InactiveCard