import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
//import Grid from '@material-ui/core/Grid'
import { onError } from 'config/lib/errorLib'
import { Auth } from 'aws-amplify'
import { NotificationManager } from 'react-notifications'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function ForgotPasswordModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()

  	const [email, setEmail] = useState('')
  	const [code, setCode] = useState('')
  	const [password, setPassword] = useState('')
  	const [confirmPassword, setConfirmPassword] = useState('')
  	const [stage, setStage] = useState('email')

  	async function forgotPasswordRequest() {
  		try {
  			await Auth.forgotPassword(email)
  			setStage('code')
  		} catch(e) {
  			onError(e)
  	// 		if (e.code === 'UserNotFoundException') {
			// 	NotificationManager.error('An account with this email address has not yet been registered.')
			// }
			// if (e.code === 'LimitExceededException') {
			// 	NotificationManager.error('Attempt limit exceeded, please try again later.')
			// }
  		}
  	}

  	async function forgotPasswordConfirmation() {
  		try {
  			await Auth.forgotPasswordSubmit(email, code, password)

  			NotificationManager.success('Password reset')
  		} catch(e) {
  			onError(e)
  	// 		if (e.code === 'ExpiredCodeException') {
			// 	NotificationManager.error('Invalid code provided, please request a code again.')
			// }
			// else {
			// 	NotificationManager.error('There was an error resetting your password.')
			// }
  		}
  	}

  	function checkEmail() {
  		if (email.length > 0) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

  	function checkCodeAndPassword() {
  		if (code.length > 0 && password.length > 0 && (password === confirmPassword)) {
  			return false
  		}
  		else {
  			return true
  		}
  	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Forgot Password
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				{stage === 'email' &&
					<>
		                <FormGroup>
		                  	<FormLabel>Email</FormLabel>
		                  	<OutlinedInput
		                    	fullWidth
		                    	type='email'
		                    	placeholder='john@example.com'
		                    	value={email}
		                    	onChange={(e) => setEmail(e.target.value)}
		                  	/>
		                </FormGroup>
			            <Box
		                    component='p'
		                    marginBottom='1rem'
		                    fontWeight='300'
		                    lineHeight='1.7'
		                    fontSize='1rem'
		                >
		                    A confirmation code will be sent to your verified email/phone number.
		                </Box>
	                </>
            	}
            	{stage === 'code' &&
            		<>
	            		<FormGroup>
		                  	<FormLabel>Confirmation Code</FormLabel>
		                  	<OutlinedInput
		                    	fullWidth
		                    	type='text'
		                    	placeholder='123456'
		                    	value={code}
		                    	onChange={(e) => setCode(e.target.value)}
		                  	/>
		            	</FormGroup>
		            	<FormGroup>
		                  	<FormLabel>New Password</FormLabel>
		                  	<OutlinedInput
		                    	fullWidth
		                    	type='password'
		                    	placeholder='csgw@2a44'
		                    	value={password}
		                    	onChange={(e) => setPassword(e.target.value)}
		                  	/>
		            	</FormGroup>
		            	<FormGroup>
		                  	<FormLabel>Confirm New Password</FormLabel>
		                  	<OutlinedInput
		                    	fullWidth
		                    	type='password'
		                    	placeholder='csgw@2a44'
		                    	value={confirmPassword}
		                    	onChange={(e) => setConfirmPassword(e.target.value)}
		                  	/>
		            	</FormGroup>
	            	</>
            	}
			</DialogContent>
			
			<DialogActions>
				{stage === 'email' &&
					<Button
						onClick={() => props.close()}
						color='primary'
					>
						Cancel
					</Button>
				}
				{stage === 'code' &&
					<Button
						onClick={() => setStage('email')}
						color='primary'
					>
						Back
					</Button>
				}
				
				{stage === 'email' &&
					<Button
						component={Box}
						onClick={() => forgotPasswordRequest()}
						color='primary'
						marginLeft='auto!important'
						variant='contained'
						disabled={checkEmail()}
					>
						Submit
					</Button>
				}
				{stage === 'code' &&
					<Button
						component={Box}
						onClick={() => forgotPasswordConfirmation()}
						color='primary'
						marginLeft='auto!important'
						variant='contained'
						disabled={checkCodeAndPassword()}
					>
						Submit
					</Button>
				}
			</DialogActions>
		</Dialog>
	)
}