import React from "react";
//import PropTypes from "prop-types";
//import clsx from "clsx";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
//import Button from "@material-ui/core/Button";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
//import Link from "@material-ui/core/Link";
//import Typography from "@material-ui/core/Typography";
// @material-ui/icons components
//import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import EmojiEvents from "@material-ui/icons/EmojiEvents";
//import GroupAdd from "@material-ui/icons/GroupAdd";
//import Home from "@material-ui/icons/Home";
//import InsertChartOutlined from "@material-ui/icons/InsertChartOutlined";
//import PublishersIcon from '@material-ui/icons/LiveTv'
import BuyersIcon from '@material-ui/icons/Business'
import OrdersIcon from '@material-ui/icons/ListAlt'
import SalesIcon from '@material-ui/icons/EuroSymbol'
//import TeamIcon from '@material-ui/icons/Group'
//import PieChart from "@material-ui/icons/PieChart";

// core components
import CardStats from "components/argon/Cards/Dashboard/CardStats.js";

import componentStyles from "assets/theme/components/headers/stats-header.js";

const useStyles = makeStyles(componentStyles);

const StatsHeader = ( props ) => {
    const classes = useStyles();
    const theme = useTheme();
    
    return (
        <>
            <div className={classes.header}>
                <Container
                  maxWidth={false}
                  component={Box}
                  classes={{ root: classes.containerRoot }}
                >
                    {props.admatic &&
                        <Grid container>
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Buyers"
                                    title="148"
                                    icon={BuyersIcon}
                                    color="bgWarning"
                                    footer={
                                        <>
                                            <Box
                                                component="span"
                                                fontSize=".875rem"
                                                color={theme.palette.success.main}
                                                marginRight=".5rem"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    component={ArrowUpward}
                                                    width="1.25rem!important"
                                                    height="1.25rem!important"
                                                    marginLeft="-.25rem"
                                                />{" "}
                                                10%
                                            </Box>
                                            <Box component="span" whiteSpace="nowrap">
                                                Since last month
                                            </Box>
                                        </>
                                    }
                                />
                            </Grid>
                            
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Orders"
                                    title="742"
                                    icon={OrdersIcon}
                                    color="bgSuccess"
                                    footer={
                                        <>
                                            <Box
                                                component="span"
                                                fontSize=".875rem"
                                                color={theme.palette.success.main}
                                                marginRight=".5rem"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    component={ArrowUpward}
                                                    width="1.25rem!important"
                                                    height="1.25rem!important"
                                                    marginLeft="-.25rem"
                                                />{" "}
                                                20%
                                            </Box>
                                            <Box component="span" whiteSpace="nowrap">
                                                Since last month
                                            </Box>
                                        </>
                                    }
                                />
                            </Grid>
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Sales"
                                    title="€641,000"
                                    icon={SalesIcon}
                                    color="bgPrimary"
                                    footer={
                                        <>
                                            <Box
                                                component="span"
                                                fontSize=".875rem"
                                                color={theme.palette.success.main}
                                                marginRight=".5rem"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    component={ArrowUpward}
                                                    width="1.25rem!important"
                                                    height="1.25rem!important"
                                                    marginLeft="-.25rem"
                                                />{" "}
                                                10%
                                            </Box>
                                            <Box component="span" whiteSpace="nowrap">
                                                Since last month
                                            </Box>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    }
                    {props.buyer &&
                        <Grid container>
                            <Grid item xl={6} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Orders"
                                    title={props.stats.noOrders}
                                    icon={OrdersIcon}
                                    color="bgSuccess"
                                    footer={
                                        <>
                                            {/*
                                            <Box
                                                component="span"
                                                fontSize=".875rem"
                                                color={theme.palette.success.main}
                                                marginRight=".5rem"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    component={ArrowUpward}
                                                    width="1.25rem!important"
                                                    height="1.25rem!important"
                                                    marginLeft="-.25rem"
                                                />{" "}
                                                20%
                                            </Box>
                                            */}
                                            <Box component="span" whiteSpace="nowrap">
                                                Approved this month
                                            </Box>
                                        </>
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Spend"
                                    title={"€" + props.stats.valueOrders}
                                    icon={SalesIcon}
                                    color="bgPrimary"
                                    footer={
                                        <>
                                            {/*
                                            <Box
                                                component="span"
                                                fontSize=".875rem"
                                                color={theme.palette.success.main}
                                                marginRight=".5rem"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    component={ArrowUpward}
                                                    width="1.25rem!important"
                                                    height="1.25rem!important"
                                                    marginLeft="-.25rem"
                                                />{" "}
                                                10%
                                            </Box>
                                            */}
                                            <Box component="span" whiteSpace="nowrap">
                                                Approved this month
                                            </Box>
                                        </>
                                    }
                                />
                            </Grid>
                            {/*
                            <Grid item xl={4} lg={6} xs={12}>
                                <CardStats
                                    subtitle="Team Members"
                                    title={props.stats.noTeam}
                                    icon={TeamIcon}
                                    color="bgWarning"
                                    footer={
                                        <>
                                            <Box
                                                component="span"
                                                fontSize=".875rem"
                                                color={theme.palette.success.main}
                                                marginRight=".5rem"
                                                display="flex"
                                                alignItems="center"
                                            >
                                                <Box
                                                    //component={ArrowUpward}
                                                    width="1.25rem!important"
                                                    height="1.25rem!important"
                                                    marginLeft="-.25rem"
                                                />{" "}
                                                
                                            </Box>
                                            <Box component="span" whiteSpace="nowrap">
                                                
                                            </Box>
                                        </>
                                    }
                                />
                            </Grid>
                            */}
                        </Grid>
                    }
                </Container>
            </div>
        </>
    );
};

export default StatsHeader
