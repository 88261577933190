import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import Button from '@material-ui/core/Button'

class FileUpload extends Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}

	onFileSelect(files) {
		console.log(files[0])
		this.setState({
			file: files[0],
		})
		this.submitFile()
	}

	submitFile() {
		console.log('submit file: ', this.state.file)

		this.props.updateFile(this.state.file)

		/*
		Auth.currentUserInfo().then((info) => {
			console.log(info)

			let path = 'catering/weekly-menus/' + Date.now()
			console.log('Date path: ', path)
            
            Storage.put(path, this.state.file, {
	    		contentType: this.state.file.type
	  		}).then((data) => {
	  			console.log(data)
	  			this.props.updateCurrentMenu(path)
	  		});
        }).catch((err) => {
            console.log(err);
    	})
    	*/
	}

	render() {
		//let dropzoneRef: any
		return (
    		<Dropzone
              	multiple={false}
              	//disableClick={this.props.disableClick}
              	accept={'video/*, audio/*, image/*'}
              	onDrop={this.onFileSelect.bind(this)}
            >
            	{({ getRootProps, getInputProps }) => (
			      	<div {...getRootProps()}>
				        <input {...getInputProps()} />
				        
				        {!this.state.file &&
					        <Button
	                    		color='info'
	                    		size='md'
	                    		block
	                    		round
	                    		type='submit'
	                    		className='upload-menu-button'
	                  		>
	                    		Upload
	                  		</Button>
	                  	}
	                  	{this.state.file &&
	                  		<p className='file-added-name'>{this.state.file.name}</p>
	                  	}
			      	</div>
				)}
           	</Dropzone> 
	    )
	}
}

export default FileUpload