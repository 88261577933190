import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
//import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CategoryCard from 'components/buyer/CategoryCard'
import DatePicker from 'components/general/DatePicker'
import StripeModal from 'components/buyer/StripeModal'
import GuestDetailsModal from 'components/guest/GuestDetailsModal'
import Loader from 'components/general/Loader'
import guestClient from 'config/api/GuestClient'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'
import { getTotalPrice } from 'config/helpers/Helpers'
import RegisterForm from 'components/guest/RegisterForm'
import moment from 'moment'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function BookAndBuyForm(props) {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const history = useHistory()
    const api = new guestClient()
    const buyerApi = new buyerClient()

    const minStartDate = moment().add(2, 'days')
  	const minEndDate = moment().add(2, 'days')

    const [stage, setStage] = useState('publisher')
    const [publishers, setPublishers] = useState([])
    const [products, setProducts] = useState([])
    const [showProducts, setShowProducts] = useState([])
    const [publisher, setPublisher] = useState(null)
    const [section, setSection] = useState(null)
    const [product, setProduct] = useState(null)
    const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [subtotal, setSubtotal] = useState(0)
	const [vat, setVat] = useState(0)
	const [total, setTotal] = useState(0)
    const [payment, setPayment] = useState(null)
    const [showStripeModal, setShowStripeModal] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)
    const [order, setOrder] = useState(null)
    const [details, setDetails] = useState(null)
    const [showGuestDetailsModal, setShowGuestDetailsModal] = useState(false)

    useEffect(() => {
        getAll()
    }, [])

    async function getAll() {
        try {
            const pubResult = await api.getAllBbPublishers()
            console.log('publishers: ', pubResult)

            const prodResult = await api.getAllBbProducts()
            console.log('products: ', prodResult)

            setPublishers(pubResult)
            setProducts(prodResult)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function placeGuestOrder(details) {
        setDetails(details)
        placeOrder(details)
    }

    async function placeOrder(details) {
        getTotals()
        let order = {
            total: total,
            product: product,
            publisher: publisher,
            variant: null,
            campaignName: 'Book & Buy',
            startDate: startDate,
            endDate: endDate,
            subtotal: subtotal,
            vat: vat,
            copy: {
                title: title,
                text: text
            }
        }
        console.log('order: ', order)
        // create as normal like a regular order but with 1 item
        let cart = [order]
        try {
            const result = await api.addOrder(details, cart)
            console.log('new order: ', result)
            //goToLoggedIn(result.orders[0])
            setPayment(result.payment)
            setShowStripeModal(true)
            setStage('order-placed')
        } catch(e) {
            onError(e)
        }
    }

    function getTotals() {
		let frequency = product.frequency
		let days = product.days
		let price = product.price
		let agencyPrice = product.agencyPrice

        // price is price per word
        // count words in ad body
        let wordCount = text.split(' ').length
        console.log('number of words: ', wordCount)

        price = price * parseInt(wordCount)

        let totals = getTotalPrice(startDate, endDate, frequency, days, price, agencyPrice)

		let subtotal = parseFloat(totals.total.toFixed(2))
		console.log(subtotal)
		let vat = parseFloat((subtotal * 0.23).toFixed(2))
		console.log(vat)
		let total = subtotal + vat
		
		setSubtotal(subtotal)
		setVat(vat)
		setTotal(total)
	}

    function selectPublisher(publisher) {
        setPublisher(publisher)
        setStage('section')
    }

    function selectSection(section) {
        console.log('select section: ', section)

        let pubProducts = products.filter((product) => {
            return (product.publisherId === publisher.publisherId)
        })
        console.log('pulisher products: ', pubProducts)

        let pubCatProducts = pubProducts.filter((product) => {
            return (product.section === section)
        })

        setShowProducts(pubCatProducts)
        setPublisher(publisher)
        setSection(section)
        setStage('product')
    }

    function selectProduct(product) {
        setProduct(product)
        setStage('date')
    }

    function updateStartDate(startDate) {
		console.log('start date: ', startDate)
		setStartDate(startDate)
	}

	function updateEndDate(endDate) {
		console.log('end date: ', endDate)
		setEndDate(endDate)
	}

    function updateText(text) {
        setText(text)
        if (text.length > 0) {
            getTotals()
        }
        else {
            setSubtotal(0)
		    setVat(0)
		    setTotal(0)
        }
    }

    function goToCopy() {
        // update totals
        //getTotals()
        setStage('copy')
    }

    function goToOption() {
        setStage('option')
    }

    function goToRegister() {
        // update totals
        getTotals()
        setStage('register')
    }

    function goToPayment() {
        // update totals
        getTotals()
        setStage('payment')
    }

    function goBack() {
        console.log(stage)
        if (stage === 'section') {
            setStage('publisher')
            setPublisher(null)
        }
        if (stage === 'product') {
            setStage('section')
            setSection(null)
        }
        if (stage === 'date') {
            setStage('product')
            setProduct(null)
        }
        if (stage === 'copy') {
            setStage('date')
        }
        if (stage === 'option') {
            setStage('copy')
        }
        if (stage === 'register') {
            setStage('option')
        }
        if (stage === 'payment') {
            setStage('option')
        }
    }

    async function goToLoggedIn(order) {
        // add order
        // go to complete payment page on logged in B&B
        let stage = 'complete-payment'
        let buyerId = order.buyerId
        let orderId = order.orderId
        window.location.replace('/book-and-buy?stage=' + stage + '&orderId=' + orderId + '&buyerId=' + buyerId)
        // history.push({
        //     pathname: '/book-and-buy',
        //     state: {email: email}
        // })
    }
    
    return (
        <>
            {alert}
            <Grid 
                container
                spacing={0}
                direction='row'
                alignItems='center'
                justify='center'
                style={{ marginBottom: '30px' }}
            >
                <Grid item xs={4}>
                    {(stage !== 'publisher' && stage !== 'order-placed') &&
                        <Box display='flex' flexWrap='wrap' justifyContent='flex-start'>
                            <Button variant='contained' size='small' color='secondary' onClick={() => goBack()}>
                                <Box component={ArrowBack} position='relative' top='-2px' />{' '}
                                Back
                            </Button>
                        </Box>
                    }
                </Grid>
                <Grid item xs={4}>
                    <Box
                        component={Typography}
                        variant='h2'
                        justifyContent='center'
                        textAlign='center'
                    >   
                        {stage === 'publisher' &&
                            <Box component='span' style={{color: '#fff'}}>Select a publisher...</Box>
                        }
                        {stage === 'section' &&
                            <Box component='span' style={{color: '#fff'}}>Select a section...</Box>
                        }
                        {stage === 'product' &&
                            <Box component='span' style={{color: '#fff'}}>Select a product...</Box>
                        }
                        {stage === 'date' &&
                            <Box component='span' style={{color: '#fff'}}>Select start & end date...</Box>
                        }
                        {stage === 'copy' &&
                            <Box component='span' style={{color: '#fff'}}>Create your ad...</Box>
                        }
                        {stage === 'register' &&
                            <Box component='span' style={{color: '#fff'}}>Your details...</Box>
                        }
                        {stage === 'payment' &&
                            <Box component='span' style={{color: '#fff'}}>Review & payment...</Box>
                        }
                        {stage === 'order-placed' &&
                            <Box component='span' style={{color: '#fff'}}>Almost done!</Box>
                        }
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    
                </Grid>
            </Grid>
            
            {!loading ? (
                <Grid 
                    container
                    spacing={0}
                    direction='row'
                    alignItems='center'
                    justify={stage === 'date' || stage === 'copy' || stage === 'option' || stage === 'payment' || stage === 'order-placed' || stage === 'register' ? 'center' : 'flex-start'}
                >
                    {stage === 'publisher' &&
                        <>
                            {publishers.map((publisher, i) => {
                                return (
                                    <Grid item xs={12} md={4} key={i}>
                                        <CategoryCard category={publisher.name} onClick={() => selectPublisher(publisher)} logo={null} />
                                    </Grid>
                                )
                            })}
                        </>
                    }
                    {stage === 'section' &&
                        <>
                            {publisher.sections.map((section, i) => {
                                return (
                                    <Grid item xs={12} md={4} key={i}>
                                        <CategoryCard category={section} onClick={() => selectSection(section)} logo={null} />
                                    </Grid>
                                )
                            })}
                        </>
                    }
                    {stage === 'product' &&
                        <>
                            {showProducts.map((product, i) => {
                                return (
                                    <Grid item xs={12} md={4} key={i}>
                                        <CategoryCard category={product.name} onClick={() => selectProduct(product)} logo={null} />
                                    </Grid>
                                )
                            })}
                        </>
                    }
                    {stage === 'date' &&
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <FormGroup>
                                        <FormLabel>Start Date</FormLabel>
                                        <DatePicker
                                            placeholder='Ad Start Date'
                                            value={startDate}
                                            onChange={(e) => updateStartDate(e)}
                                            minDate={minStartDate}
                                            frequency={'Daily'}
                                            startPicker
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <FormLabel>End Date</FormLabel>
                                        <DatePicker
                                            placeholder='Ad End Date'
                                            value={endDate}
                                            onChange={(e) => updateEndDate(e)}
                                            minDate={minEndDate}
                                            frequency={'Daily'}
                                            endPicker
                                            startDate={startDate}
                                        />
                                    </FormGroup>

                                    <Button
                                        component={Box}
                                        color='primary'
                                        variant='contained'
                                        //marginLeft='auto!important'
                                        onClick={() => goToCopy()}
                                        disabled={!(startDate && endDate)}
                                    >
                                        Next
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {stage === 'copy' &&
                        <Grid item xs={12} md={10}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Card>
                                        <CardContent>
                                            <FormGroup>
                                                <FormLabel>Title</FormLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    type='text'
                                                    placeholder='Bike for Sale'
                                                    value={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>Text (Word Count: {text.split(' ').length})</FormLabel>
                                                <OutlinedInput
                                                    fullWidth
                                                    multiline
                                                    rows='5'
                                                    type='text'
                                                    placeholder='I selling a bike for...'
                                                    value={text}
                                                    onChange={(e) => updateText(e.target.value)}
                                                    className='buyAndBookTa'
                                                />
                                            </FormGroup>
                                            <Button
                                                component={Box}
                                                color='primary'
                                                variant='contained'
                                                //marginLeft='auto!important'
                                                onClick={() => goToPayment()}
                                                disabled={!(title.length > 0 && text.length > 0)}
                                            >
                                                Next
                                            </Button>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card>
                                        <CardContent>
                                            <Grid
                                                container
                                                spacing={1}
                                                direction='column'
                                                alignItems='center'
                                                justify='center'
                                            >
                                                <Grid item xs={12}>
                                                    <Box
                                                        component='h2'
                                                        marginBottom='1rem'
                                                        fontWeight='300'
                                                        lineHeight='1.2'
                                                        fontSize='2rem'
                                                        marginTop='0'
                                                    >
                                                        {publisher.name}
                                                    </Box>
                                                </Grid>

                                                <Grid item>
                                                    <Box
                                                        component='p'
                                                        marginBottom='1rem'
                                                        fontWeight='300'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                    >
                                                        {product.section} - {product.name}
                                                    </Box>
                                                </Grid>

                                                <Grid item>
                                                    <Box
                                                        component='p'
                                                        marginBottom='1rem'
                                                        fontWeight='300'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                    >
                                                        Start: {startDate.format('DD/MM/YY')}
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box
                                                        component='p'
                                                        marginBottom='1rem'
                                                        fontWeight='300'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                    >
                                                        End: {endDate.format('DD/MM/YY')}
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                container
                                                spacing={1}
                                                direction='column'
                                                //alignItems='flex-start'
                                                justify='flex-start'
                                            >
                                                <Grid item xs={12}>
                                                    <Grid 
                                                        container
                                                        spacing={1}
                                                        direction='row'
                                                        alignItems='center'
                                                        justify='space-between'
                                                    >
                                                        <Grid item xs={8}>
                                                            <Box
                                                                component='p'
                                                                //marginBottom='1rem'
                                                                fontWeight='600'
                                                                lineHeight='1.2'
                                                                fontSize='1rem'
                                                                marginTop='0'
                                                            >
                                                                Subtotal
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Box
                                                                component='p'
                                                                //marginBottom='1rem'
                                                                fontWeight='600'
                                                                lineHeight='1.2'
                                                                fontSize='1rem'
                                                                marginTop='0'
                                                                textAlign='right'
                                                            >
                                                                €{subtotal.toFixed(2)}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid 
                                                        container
                                                        spacing={1}
                                                        direction='row'
                                                        alignItems='center'
                                                        justify='space-between'
                                                    >
                                                        <Grid item xs={8}>
                                                            <Box
                                                                component='p'
                                                                //marginBottom='1rem'
                                                                fontWeight='600'
                                                                lineHeight='1.2'
                                                                fontSize='1rem'
                                                                marginTop='0'
                                                            >
                                                                VAT (23%)
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Box
                                                                component='p'
                                                                //marginBottom='1rem'
                                                                fontWeight='600'
                                                                lineHeight='1.2'
                                                                fontSize='1rem'
                                                                marginTop='0'
                                                                textAlign='right'
                                                            >
                                                                €{vat.toFixed(2)}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid 
                                                        container
                                                        spacing={1}
                                                        direction='row'
                                                        alignItems='center'
                                                        justify='space-between'
                                                    >
                                                        <Grid item xs={8}>
                                                            <Box
                                                                component='p'
                                                                //marginBottom='1rem'
                                                                fontWeight='600'
                                                                lineHeight='1.2'
                                                                fontSize='1rem'
                                                                marginTop='0'
                                                            >
                                                                Total
                                                            </Box>
                                                        </Grid>

                                                        <Grid item xs={4}>
                                                            <Box
                                                                component='p'
                                                                //marginBottom='1rem'
                                                                fontWeight='600'
                                                                lineHeight='1.2'
                                                                fontSize='1rem'
                                                                marginTop='0'
                                                                textAlign='right'
                                                            >
                                                                €{total.toFixed(2)}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {stage === 'option' &&
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Grid container direction='column' alignItems='center' spacing={2}>
                                        <Grid item>
                                            <Button
                                                component={Box}
                                                color='primary'
                                                variant='contained'
                                                //marginLeft='auto!important'
                                                onClick={() => goToRegister()}
                                                //disabled={!(title.length > 0 && text.length > 0)}
                                            >
                                                Login/Register
                                            </Button>
                                        </Grid>
                                        <Grid>
                                            <Button
                                                component={Box}
                                                color='primary'
                                                variant='contained'
                                                //marginLeft='auto!important'
                                                onClick={() => goToPayment()}
                                                //disabled={!(title.length > 0 && text.length > 0)}
                                            >
                                                Checkout as Guest
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {stage === 'register' && // step only required if logged out
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <RegisterForm 
                                        placeOrder={(details) => placeOrder(details)}
                                        checkoutAsGuest={() => setStage('payment')}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {stage === 'payment' &&
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction='column'
                                        alignItems='center'
                                        justify='center'
                                    >
                                        <Grid item xs={12}>
                                            <Box
                                                component='h2'
                                                marginBottom='1rem'
                                                fontWeight='300'
                                                lineHeight='1.2'
                                                fontSize='2rem'
                                                marginTop='0'
                                            >
                                                {publisher.name}
                                            </Box>
                                        </Grid>

                                        <Grid item>
                                            <Box
                                                component='p'
                                                marginBottom='1rem'
                                                fontWeight='300'
                                                lineHeight='1.2'
                                                fontSize='1rem'
                                                marginTop='0'
                                            >
                                                {product.section} - {product.name}
                                            </Box>
                                        </Grid>

                                        <Grid item>
                                            <Box
                                                component='p'
                                                marginBottom='1rem'
                                                fontWeight='300'
                                                lineHeight='1.2'
                                                fontSize='1rem'
                                                marginTop='0'
                                            >
                                                Start: {startDate.format('DD/MM/YY')}
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box
                                                component='p'
                                                marginBottom='1rem'
                                                fontWeight='300'
                                                lineHeight='1.2'
                                                fontSize='1rem'
                                                marginTop='0'
                                            >
                                                End: {endDate.format('DD/MM/YY')}
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={1}
                                        direction='column'
                                        //alignItems='flex-start'
                                        justify='flex-start'
                                    >
                                        <Grid item xs={12}>
                                            <Grid 
                                                container
                                                spacing={1}
                                                direction='row'
                                                alignItems='center'
                                                justify='space-between'
                                            >
                                                <Grid item xs={8}>
                                                    <Box
                                                        component='p'
                                                        //marginBottom='1rem'
                                                        fontWeight='600'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                    >
                                                        Subtotal
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Box
                                                        component='p'
                                                        //marginBottom='1rem'
                                                        fontWeight='600'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                        textAlign='right'
                                                    >
                                                        €{subtotal.toFixed(2)}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid 
                                                container
                                                spacing={1}
                                                direction='row'
                                                alignItems='center'
                                                justify='space-between'
                                            >
                                                <Grid item xs={8}>
                                                    <Box
                                                        component='p'
                                                        //marginBottom='1rem'
                                                        fontWeight='600'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                    >
                                                        VAT (23%)
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Box
                                                        component='p'
                                                        //marginBottom='1rem'
                                                        fontWeight='600'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                        textAlign='right'
                                                    >
                                                        €{vat.toFixed(2)}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid 
                                                container
                                                spacing={1}
                                                direction='row'
                                                alignItems='center'
                                                justify='space-between'
                                            >
                                                <Grid item xs={8}>
                                                    <Box
                                                        component='p'
                                                        //marginBottom='1rem'
                                                        fontWeight='600'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                    >
                                                        Total
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <Box
                                                        component='p'
                                                        //marginBottom='1rem'
                                                        fontWeight='600'
                                                        lineHeight='1.2'
                                                        fontSize='1rem'
                                                        marginTop='0'
                                                        textAlign='right'
                                                    >
                                                        €{total.toFixed(2)}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container alignItems='center' justify='center'>
                                        <Grid item>
                                            <Button
                                                component={Box}
                                                color='primary'
                                                variant='contained'
                                                //marginLeft='auto!important'
                                                onClick={() => setShowGuestDetailsModal(true)}
                                            >
                                                Place Order & Pay
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {stage === 'order-placed' &&
                        <Grid item xs={12} md={4}>
                            <Card>
                                <CardContent>
                                    <Typography style={{textAlign: 'center'}}>You order has been placed successfully. Please complete payment to ensure your ad is placed.</Typography>
                                    
                                    {order &&
                                        <Grid
                                            container
                                            spacing={1}
                                            direction='column'
                                            //alignItems='flex-start'
                                            justify='flex-start'
                                            style={{marginBottom: '1rem', marginTop: '1rem'}}
                                        >
                                            <Grid item>
                                                <Grid 
                                                    container
                                                    spacing={1}
                                                    direction='row'
                                                    alignItems='center'
                                                    justify='space-between'
                                                >
                                                    <Grid item xs={8}>
                                                        <Box
                                                            component='p'
                                                            //marginBottom='1rem'
                                                            fontWeight='600'
                                                            lineHeight='1.2'
                                                            fontSize='1rem'
                                                            marginTop='0'
                                                        >
                                                            Total: €{order.total.toFixed(2)}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }

                                    <Box
                                        textAlign='center'
                                        marginTop='1.5rem'
                                        marginBottom='1.5rem'
                                    >
                                        <Button
                                            color='primary'
                                            variant='contained'
                                            classes={{ root: classes.buttonContainedInfo }}
                                            //onClick={() => history.push('/dashboard')}
                                            onClick={() => setShowStripeModal(true)}
                                        >
                                            Complete Payment
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {showGuestDetailsModal &&
                        <GuestDetailsModal 
                            isOpen={showGuestDetailsModal}
                            close={() => setShowGuestDetailsModal(false)}
                            placeOrder={(details) => placeGuestOrder(details)}
                        />
                    }
                    {showStripeModal &&
                        <StripeModal 
                            isOpen={showStripeModal}
                            close={() => setShowStripeModal(false)}
                            details={details}
                            payment={payment}
                            type={'guest'}
                        />
                    }
                </Grid>
            ) : (
                <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                    <Loader />
                </Box>
            )}
        </>
    );
}

export default BookAndBuyForm