import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Info from '@material-ui/icons/Info'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import ProductModal from 'components/buyer/ProductModal'
import ProductCard from 'components/buyer/ProductCard'
import AboutModal from 'components/buyer/AboutModal'
import CartModal from 'components/buyer/CartModal'
import StripeModal from 'components/buyer/StripeModal'
import guestClient from 'config/api/GuestClient'
import { onError } from 'config/lib/errorLib'
import Loader from 'components/general/Loader'
import { useHistory } from 'react-router-dom'
import GiLogo from 'resources/images/gi-logo-full-bg.png'

import componentStyles from 'assets/theme/views/admin/alternative-dashboard.js'
import componentStylesCardDeck from 'assets/theme/components/cards/card-deck.js'

const useStyles = makeStyles(componentStyles)
const useStylesCardDeck = makeStyles(componentStylesCardDeck)

function MediaCatalog() {
    const classes = { ...useStyles(), ...useStylesCardDeck() }
    const history = useHistory()
    const api = new guestClient()
    
    const [publisher, setPublisher] = useState(null)
    const [showAboutModal, setShowAboutModal] = useState(false)
    
    const [products, setProducts] = useState([])

    const [product, setProduct] = useState(null)
    const [showProductModal, setShowProductModal] = useState(false)

    const [cart, setCart] = useState([])
    const [showCartModal, setShowCartModal] = useState(false) 

    const [showStripeModal, setShowStripeModal] = useState(false)
    const [details, setDetails] = useState(null)
    const [payment, setPayment] = useState(null)
    
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(true)

    // empty array argument prevents endless loop
    useEffect(() => {
        getAll()
    }, [])

    async function getAll() {
        let publisherId = 'cce8f5e4-9d9e-4bfd-b3cc-bc2de2faab5b'
        try {
            const pubResult = await api.getAllPublishers()
            console.log('publishers: ', pubResult)

            const prodResult = await api.getAllProducts()
            console.log('products: ', prodResult)

            let publisherIndex = pubResult.findIndex(pub => pub.publisherId === publisherId)
            console.log('publisher index: ', publisherIndex)
            let publisher = pubResult[publisherIndex]
            // show products that selected publisher has in selected category
            let pubProducts = prodResult.filter((product) => {
                return (product.publisherId === publisher.publisherId)
            })
            console.log('pulisher products: ', pubProducts)


            setProducts(pubProducts)
            setPublisher(publisher)
            setLoading(false)
        } catch(e) {
            onError(e)
        }
    }

    function openProductModal(vProduct) {
        // open product details modal that allows user to add to cart
        console.log('view product: ', vProduct)
        console.log('publisher: ', publisher)
        setProduct(vProduct)
        setShowProductModal(true)
    }

    function closeProductModal() {
        setShowProductModal(false)
        setProduct(null)
    }

    function addToCart(item) {
        console.log('add item: ', item)

        setCart(cart => [...cart, item])
        setShowProductModal(false)
        openSuccessAlert('Added to cart')
    }

    function removeFromCart(removeItem) {
        console.log('remove item: ', removeItem)

        const index = cart.findIndex(item => item.product.productId === removeItem.product.productId)
        console.log('index in cart: ', index)

        let newCart = [...cart]

        newCart.splice(index, 1)
        setCart(newCart)

        if (newCart.length === 0) {
            setShowCartModal(false)
        }
    }

    function showStripe(payment, details) {
        console.log('stripe')
        setPayment(payment)
        setDetails(details)
        setShowStripeModal(true)
    }

    async function submitEnquiry(details, campaignName, message, variant) {
        try {
            const result = await api.submitProductEnquiry(campaignName, message, details, publisher, product, variant)
            console.log('result: ', result)

            setShowProductModal(false)
            openSuccessAlert('Your enquiry has been submitted. A member of our team will contact you shortly.')
        } catch(e) {
            onError(e)
        }
    }

    async function sendInvoice(paymentId) {
        try {
            const result = await api.sendUnpaidInvoice(details.email, paymentId)
            console.log('result: ', result)

            //setShowCartModal(false)
            //setCart([])
            openSuccessAlert('Your invoice has been sent to you via email. Please pay this promptly via EFT.')
        } catch(e) {
            onError(e)
        }
    }

    function showEft(paymentId) {
        setAlert(
            <ReactBSAlert
                info
                style={{ display: 'block', marginTop: '-100px' }}
                title='Important'
                onConfirm={() => sendInvoice(paymentId)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Send Invoice'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                After you click 'Send Invoice' you will be sent a VAT invoice via email. This can be paid manually via Electronic Fund Transfer. 
                <br />
                <br />
                Once payment has been received the payment status of your order will be updated and you will be notified via email.
            </ReactBSAlert>
        )
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

    return (
        <>
            {alert}
            <Container
                maxWidth={false}
                component={Box}
                //marginTop='2rem'
                paddingTop='3rem'
                classes={{ root: classes.containerRoot }}
            >
                <Grid 
                    container
                    spacing={0}
                    direction='row'
                    alignItems='center'
                    justify='center'
                    style={{ marginBottom: '30px' }}
                >
                    <Grid item xs={12}>
                        {cart.length > 0 &&
                            <Box display='flex' flexWrap='wrap' justifyContent='flex-end'>
                                <Button variant='contained' size='small' color='primary' onClick={() => setShowCartModal(true)}>
                                    <Box component={ShoppingCart} position='relative' top='-2px' />{' '}
                                    View Cart ({cart.length})
                                </Button>
                            </Box>
                        }
                    </Grid>

                    <Grid item xs={10}>
                        <Box 
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            style={{paddingTop: '0rem'}}
                        >
                            <img className='gi-logo' src={GiLogo} alt='guaranteed-irish-logo' />
                        </Box>
                    </Grid>

                    <Grid item xs={8}>
                        <Box 
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            style={{paddingTop: '0rem'}}
                        >
                            <Typography style={{color: '#fff', marginBottom: '1rem', fontWeight: '700'}} align='center' marginBottom='1rem'>Welcome to The new Guaranteed Irish Media Hub!</Typography>
                            <Typography style={{color: '#fff', marginBottom: '1rem'}}  align='center' marginBottom='1rem'>Where our members can avail of special media discounted rates and buy online. You’ll save money as part of your GI Membership! As well as promoting your business as being, Guaranteed Irish!</Typography>
                            <Typography style={{color: '#fff', marginBottom: '1rem'}}  align='center' marginBottom='1rem'>4 Media Packages to choose from, at least 50% off Rate Card prices and we do it all for you! Click and have a look now. </Typography>
                        </Box>
                    </Grid>
                </Grid>

                {!loading &&
                    <Grid
                        container
                        alignItems='center'
                        justify='center'
                        style={{ marginBottom: '30px' }}
                    >
                        <Grid item>
                            <Button variant='contained' size='medium' color='secondary' onClick={() => setShowAboutModal(true)}>
                                <Box component={Info} position='relative' top='-2px' />{' '}
                                More About {publisher.name}
                            </Button>
                        </Grid>
                    </Grid>
                }

                {!loading ? (
                    <Grid 
                        container
                        spacing={0}
                        direction='row'
                        alignItems='center'
                        justify='center'
                    >
                        <Grid item xs={10}>
                            <Grid container>
                                {products.map((mapProduct, i) => {
                                    return (
                                        <Grid item xs={12} md={6} key={i}>
                                            <ProductCard product={mapProduct} onClick={() => openProductModal(mapProduct)} />
                                        </Grid>
                                    )
                                })}
                            </Grid>

                            <Grid item xs={12}>
                                <Box
                                    component='p'
                                    marginBottom='2rem'
                                    fontWeight='300'
                                    lineHeight='1.2'
                                    fontSize='1rem'
                                    marginTop='1rem'
                                    textAlign='center'
                                    color='#fff'
                                    className='hover-text'
                                    onClick={() => history.push('catalog')}
                                >
                                    Browse full admatic.ie catalog
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader />
                    </Box>
                )}
            </Container>

            {showProductModal &&
                <ProductModal
                    isOpen={showProductModal}
                    close={() => closeProductModal()}
                    product={product}
                    publisher={publisher} 
                    addToCart={(item) => addToCart(item)}
                    register={() => history.push('/register')}
                    submitEnquiry={(details, campaignName, message, variant) => submitEnquiry(details, campaignName, message, variant)}
                    type='guest'
                />
            }

            {showAboutModal &&
                <AboutModal
                    isOpen={showAboutModal}
                    close={() => setShowAboutModal(false)}
                    publisher={publisher}
                    category={'Radio'}
                />
            }

            {showCartModal &&
                <CartModal 
                    isOpen={showCartModal}
                    close={() => setShowCartModal(false)}
                    cart={cart}
                    details={details}
                    removeFromCart={(item) => removeFromCart(item)}
                    setCart={(cart) => setCart(cart)}
                    showStripe={(payment, details) => showStripe(payment, details)}
                    showEft={(paymentId) => showEft(paymentId)}
                    type='guest'
                    updateDetails={(details) => setDetails(details)}
                />
            }

            {showStripeModal &&
                <StripeModal 
                    isOpen={showStripeModal}
                    close={() => setShowStripeModal(false)}
                    details={details}
                    payment={payment}
                    type={'guest'}
                />
            }
        </>
    );
}

export default MediaCatalog