import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
//import FormGroup from '@material-ui/core/FormGroup'
//import FormLabel from '@material-ui/core/FormLabel'
//import OutlinedInput from '@material-ui/core/OutlinedInput'
import Logo from 'components/general/Logo'
//import DatePicker from 'components/general/DatePicker'
import CompletePaymentModal from 'components/buyer/CompletePaymentModal'
import moment from 'moment'
import buyerClient from 'config/api/BuyerClient'
import { onError } from 'config/lib/errorLib'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function OrderModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
	const api = new buyerClient()

	const order = props.order
	console.log(order)

	const [paymentDetails, setPaymentDetails] = useState(null)
	const [showCompletePayment, setShowCompletePayment] = useState(false)
	const [loading, setLoading] = useState(true)
	const [pendingFirePayment, setPendingFirePayment] = useState(false)

	useEffect(() => {
        getPaymentDetails()
    }, [])

    async function getPaymentDetails() {
    	// if payment code but no payment uuid then show complete payment button
    	if (order.paymentId) {
	    	try {
	    		const result = await api.getPayment(order.paymentId)
	    		console.log('payment details: ', result)

	    		setPaymentDetails(result.payment)
	    		setLoading(false)
	    	} catch(e) {
	    		onError(e)
	    	}
    	}
    }

	function dismissRejectedOrder() {
		console.log('dismiss rejected order...')
		props.dismissRejectedOrder()
	}

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Order Details
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>
			
			<DialogContent>
				<Grid 
                    container
                    spacing={2}
                    direction='row'
                    alignItems='center'
                    justify='space-evenly'
                >
                    <Grid item>
                    	<Grid 
		                    container
		                    spacing={1}
		                    direction='column'
		                    alignItems='center'
		                    justify='center'
		                >
		                	<Grid item>
		                        <Logo 
		                            logo={order.user.buyerLogo}
		                            name={order.user.buyerName}
		                            size='70px'
		                            marginRight={'0'}
		                        />
	                        </Grid>
	                        <Grid item>
		                        <Box
		                            component={Typography}
		                            variant='h2'
		                        >
		                            <Box component='span'>{order.user.buyerName}</Box>
		                        </Box>
	                        </Grid>
	                        <Grid item>
		                        <Box
		                            component={Typography}
		                            variant='body2'
		                        >
		                            <Box component='span'>{order.user.email}</Box>
		                        </Box>
	                        </Grid>
	                        <Grid item>
		                        <Box
		                            component={Typography}
		                            variant='body2'
		                        >
		                            <Box component='span'>{order.user.phone}</Box>
		                        </Box>
	                        </Grid>
	                    </Grid>
                    </Grid>
                    <Grid item>
                    	<Grid 
		                    container
		                    spacing={1}
		                    direction='column'
		                    alignItems='center'
		                    justify='center'
		                >
		                	<Grid item>
		                        <Logo 
		                            logo={order.publisher.logo}
		                            name={order.publisher.name}
		                            size='70px'
		                            marginRight={'0'}
		                        />
	                        </Grid>
	                        <Grid item>
		                        <Box
		                            component={Typography}
		                            variant='h2'
		                        >
		                            <Box component='span'>{order.publisher.name}</Box>
		                        </Box>
		                    </Grid>
		                    <Grid item>
		                        <Box
		                            component={Typography}
		                            variant='body2'
		                        >
		                            <Box component='span'>{order.publisher.email}</Box>
		                        </Box>
	                        </Grid>
	                        <Grid item>
		                        <Box
		                            component={Typography}
		                            variant='body2'
		                        >
		                            <Box component='span'>{order.publisher.phone}</Box>
		                        </Box>
	                        </Grid>
	                    </Grid>
                    </Grid>
                </Grid>

                <Grid 
                    container
                    spacing={1}
                    direction='column'
                    alignItems='flex-start'
                    justify='flex-start'
                    style={{marginTop: '30px'}}
                >
                	<Grid item>
                		<Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                        Booked By: {order.user.name}
	                    </Box>
                	</Grid>
                	
                	<Grid item>
	                    <Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                        Category/Section: {order.product.category ? order.product.category : order.product.section}
	                    </Box>
                	</Grid>

                	<Grid item>
	                    <Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                        Product: {order.product.name}
	                    </Box>
                	</Grid>

					{order.copy &&
						<Grid item>
							<Box
								component='p'
								marginBottom='1rem'
								fontWeight='300'
								lineHeight='1.2'
								fontSize='1rem'
								marginTop='0'
							>
								Title: {order.copy.title}
							</Box>
							<Box
								component='p'
								marginBottom='1rem'
								fontWeight='300'
								lineHeight='1.2'
								fontSize='1rem'
								marginTop='0'
							>
								Body: {order.copy.text}
							</Box>
						</Grid>
					}

                	{order.variant &&
                		<>
		                	<Grid item>
			                    <Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='300'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                    >
			                        Variant: {order.variant.name}
			                    </Box>
		                	</Grid>
	                	</>
                	}

                	{/*
                	<Grid item>
	                    <Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                        Description: {order.product.description}
	                    </Box>
                	</Grid>
                	*/}
					
					{order.product.days &&
						<Grid item>
							<Box
								component='p'
								marginBottom='1rem'
								fontWeight='300'
								lineHeight='1.2'
								fontSize='1rem'
								marginTop='0'
							>
								{order.variant ? (
									<>Frequency: {order.variant.frequency} ({order.variant.days.join(', ')})</>
								) : (
									<>Frequency: {order.product.frequency} ({order.product.days.join(', ')})</>
								)}
							</Box>
						</Grid>
					}

                	<Grid item>
	                    <Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                        Dates: {moment.unix(order.startDate).format('DD/MM/YY')} - {moment.unix(order.endDate).format('DD/MM/YY')}
	                    </Box>
                	</Grid>

                	{order.note &&
	                	<Grid item>
		                    <Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                    >
		                        Note: {order.note}
		                    </Box>
	                	</Grid>
	                }

                	{order.subtotal &&
	                	<>
		                	<Grid item>
			                    <Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='300'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                    >
			                        Subtotal: €{order.subtotal.toFixed(2)}
			                    </Box>
		                	</Grid>

		                	<Grid item>
			                    <Box
			                        component='p'
			                        marginBottom='1rem'
			                        fontWeight='300'
			                        lineHeight='1.2'
			                        fontSize='1rem'
			                        marginTop='0'
			                    >
			                        VAT (23%): €{order.vat.toFixed(2)}
			                    </Box>
		                	</Grid>
	                	</>
                	}

                	<Grid item>
	                    <Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                        Total: €{order.total.toFixed(2)}
	                    </Box>
                	</Grid>

                	<Grid item>
	                    <Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                    	{!loading &&
	                        	<Box className='payment-status hover-text'>Payment Status: {paymentDetails.status}</Box>
	                    	}
	                    </Box>
                	</Grid>

                	<Grid item>
                		<Box
	                        component='p'
	                        marginBottom='1rem'
	                        fontWeight='300'
	                        lineHeight='1.2'
	                        fontSize='1rem'
	                        marginTop='0'
	                    >
	                        Order Status: {order.status}
	                    </Box>
                	</Grid>

                	{order.rejectNote &&
	                	<Grid item>
		                    <Box
		                        component='p'
		                        marginBottom='1rem'
		                        fontWeight='300'
		                        lineHeight='1.2'
		                        fontSize='1rem'
		                        marginTop='0'
		                        color='#f5365c'
		                    >
		                        Rejection Reason: {order.rejectNote}
		                    </Box>
	                	</Grid>
	                }
                </Grid>
			</DialogContent>

			
			<DialogActions style={{justifyContent: 'space-between'}}>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Close
				</Button>

				{(!loading && paymentDetails.status === 'pending') &&
					<Button
						component={Box}
						color='primary'
						variant='contained'
						onClick={() => setShowCompletePayment(true)}
						//marginLeft='auto!important'
					>
						Complete Payment
					</Button>
				}

				{/*order.status === 'rejected' &&
					<Button
						component={Box}
						color='primary'
						variant='contained'
						onClick={() => dismissRejectedOrder()}
						//marginLeft='auto!important'
					>
						Dismiss Rejected Order
					</Button>
				*/}
			</DialogActions>

			{showCompletePayment &&
                <CompletePaymentModal 
                    isOpen={showCompletePayment}
                    close={() => setShowCompletePayment(false)}
                    payment={paymentDetails}
                />
            }
		</Dialog>
	)
}