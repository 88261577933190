import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Notifications from '@material-ui/icons/Notifications'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function WarningModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	const theme = useTheme()

	return (
		<Dialog
		  	open={props.isOpen}
		  	TransitionComponent={Transition}
		  	keepMounted
		  	onClose={() => props.close()}
		  	aria-labelledby='alert-dialog-slide-title'
		  	aria-describedby='alert-dialog-slide-description'
		  	classes={{
		    	paper: classes.dialogNotification,
		  	}}
		>
		  	<div className={classes.dialogHeader}>
			    <Typography
			      	variant='h5'
			      	component='h5'
			      	className={classes.dialogTitle}
			    >
			      	Attention
			    </Typography>
			    <IconButton onClick={() => props.close()}>
			      	<Box
			        	component={Clear}
			        	color={theme.palette.white.main}
			      	/>
			    </IconButton>
		  	</div>
		  	
		  	<DialogContent>
			    <Box
			      	textAlign='center'
			      	paddingTop='1rem'
			      	paddingBottom='1rem'
			    >
			      	<Box
			        	component={Notifications}
			        	width='3em!important'
			        	height='3em!important'
			      	></Box>
			      	
			      	<Typography
			        	variant='h4'
			        	component='h4'
			        	className={classes.dialogHeading}
			      	>
			        	Are you sure you wish to continue?
			      	</Typography>
			      
			      	<Typography variant='body2' component='p'>
				        {props.primaryText}
			      	</Typography>
			    </Box>
		  	</DialogContent>
		  
		  	<DialogActions>
			    <Button
			      	onClick={() => props.close()}
			      	color='secondary'
			    >
			      	Close
			    </Button>
			    <Button
			      	component={Box}
			      	onClick={() => props.onContinue()}
			      	color='secondary'
			      	variant='contained'
			      	marginLeft='auto!important'
			    >
			      	Continue
			    </Button>
		  	</DialogActions>
		</Dialog>
	)
}