import React, { useState, useEffect, useContext } from 'react'
import { useLocation, Switch, Route, Redirect } from 'react-router-dom'
import AdminNavbarAlternative from 'components/argon/Navbars/AdminNavbarAlternative.js'
import Sidebar from 'components/argon/Sidebar/Sidebar.js'
import Box from '@material-ui/core/Box'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShopIcon from '@material-ui/icons/Shop'
import FileIcon from '@material-ui/icons/FileCopy'
import HelpIcon from '@material-ui/icons/Help'
import MailIcon from '@material-ui/icons/Mail'
import NewspaperIcon from '@material-ui/icons/CreateNewFolder'

import { makeStyles } from '@material-ui/core/styles'
import componentStyles from 'assets/theme/layouts/admin.js'

import Profile from 'scenes/buyer/Profile'
import Dashboard from 'scenes/buyer/Dashboard'
import MediaCatalog from 'scenes/buyer/MediaCatalog'
import BookAndBuy from 'scenes/buyer/BookAndBuy'
import CopyUpload from 'scenes/buyer/CopyUpload'
//import CopyDispatch from 'scenes/buyer/CopyDispatch'
//import CreateAdvertising from 'scenes/buyer/CreateAdvertising'
//import MediaPlanning from 'scenes/buyer/MediaPlanning'
import Help from 'scenes/buyer/Help'
//import Offers from 'scenes/buyer/Offers'
import PaymentCompleted from 'scenes/buyer/PaymentCompleted'
import PaymentPending from 'scenes/buyer/PaymentPending'
import Welcome from 'scenes/buyer/Welcome'
import GuaranteedIrish from 'scenes/buyer/GuaranteedIrish'
//import HowItWorks from 'scenes/buyer/HowItWorks'
//import AudienceSelection from 'scenes/buyer/AudienceSelection'

import CompanyContext from 'config/context/CompanyContext'

const useStyles = makeStyles(componentStyles)

const Main = () => {
    const classes = useStyles()
    const location = useLocation()

    const company = useContext(CompanyContext)
    console.log('company: ', company)
    const isAdmatic = company.id === 'ad'
    console.log('is admatic: ', isAdmatic)

    const [sidebarOpenResponsive, setSidebarOpenResponsive] = useState(false)

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        // mainContent.current.scrollTop = 0
    }, [location])

    function getImage() {
        let image = require('../../resources/images/logo-black-800.png').default
        if (company.id === 'gi') {
            image = require('../../resources/images/gi-small.png').default
        }
        return image
    }

    const routes = isAdmatic ? [
        {
            path: '/catalog',
            name: 'Browse Media',
            icon: ShopIcon,
            iconColor: 'Success',
            component: MediaCatalog,
        },
        {
            path: '/book-and-buy',
            name: 'Book & Buy',
            icon: NewspaperIcon,
            iconColor: 'Success',
            component: BookAndBuy,
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: DashboardIcon,
            iconColor: 'Success',
            component: Dashboard,
        },
        {
            path: '/copy-upload',
            name: 'Copy Upload',
            icon: FileIcon,
            iconColor: 'Success',
            component: CopyUpload,
        },
        {
            path: '/help',
            name: 'Help',
            icon: MailIcon,
            iconColor: 'Success',
            component: Help,
        },
        {
            //path: '/how-it-works', // should link to outer wordpress page
            name: 'How Admatic Works',
            icon: HelpIcon,
            iconColor: 'Success',
            href: 'https://admatic.ie/how-admatic-works'
            //component: HowItWorks,
        }
    ] : [
        {
            path: '/catalog',
            name: 'Browse Media',
            icon: ShopIcon,
            iconColor: 'Success',
            component: MediaCatalog,
        },
        {
            path: '/dashboard',
            name: 'Dashboard',
            icon: DashboardIcon,
            iconColor: 'Success',
            component: Dashboard,
        },
        {
            path: '/copy-upload',
            name: 'Copy Upload',
            icon: FileIcon,
            iconColor: 'Success',
            component: CopyUpload,
        },
        {
            path: '/help',
            name: 'Help',
            icon: MailIcon,
            iconColor: 'Success',
            component: Help,
        }
    ]

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views)
            } 
            else {
                return (
                    <Route
                        path={prop.path}
                        component={prop.component}
                    />
                )
            }
        });
        // console.log(test)
        // let redirect = (<Redirect from="*" to="/" />)
        // console.log(redirect)
        // test.push(redirect)
        // console.log(test)
        // return test
    }

    return (
        <>
            <Box display='flex'>
                <Sidebar
                    routes={routes}
                    openResponsive={sidebarOpenResponsive}
                    closeSidebarResponsive={() => setSidebarOpenResponsive(false)}
                    logo={{
                        innerLink: '/',
                        imgSrc: getImage(),
                        imgAlt: '...',
                        className: 'sidebar-logo',
                    }}
                />
                <Box position='relative' flex='1' className={classes.mainContent}>
                    <AdminNavbarAlternative
                        openSidebarResponsive={() => setSidebarOpenResponsive(true)}
                    />
                    
                    <Switch>
                        <Route exact path='/' component={isAdmatic ? Welcome : MediaCatalog} />
                        <Route path='/profile' component={Profile} />
                        <Route path='/payment-completed' component={PaymentCompleted} />
                        <Route path='/payment-pending' component={PaymentPending} />
                        <Route path='/guaranteed-irish' component={GuaranteedIrish} />
                        {getRoutes(routes)}
                        <Redirect from='*' to='/' />
                    </Switch>
                </Box>
            </Box>
        </>
    )
}

export default Main