import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
//import { useTheme } from '@material-ui/core/styles'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Clear from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import buyerClient from 'config/api/BuyerClient'
import Loader from 'components/general/Loader'
import { onError } from 'config/lib/errorLib'
import moment from 'moment'
import DetailsContext from 'config/context/DetailsContext'
import CompanyContext from 'config/context/CompanyContext'
import StripeModal from 'components/buyer/StripeModal'

import componentStyles from 'assets/theme/views/admin/notifications.js'
import componentStylesDialog from 'assets/theme/components/dialog.js'

const useStyles = makeStyles(componentStyles)
const useStylesDialog = makeStyles(componentStylesDialog)

const Transition = React.forwardRef(function Transition(props, ref) {
  	return <Slide direction='down' ref={ref} {...props} />
})

export default function CompletePaymentModal(props) {
	const classes = {...useStyles(), ...useStylesDialog()}
  	//const theme = useTheme()
  	const api = new buyerClient()

	const company = useContext(CompanyContext)
	console.log('company: ', company)

	const payment = props.payment
	const details = useContext(DetailsContext)

	const [loading, setLoading] = useState(true)
	const [stage, setStage] = useState('order')
	const [orders, setOrders] = useState([])
	const [showStripeModal, setShowStripeModal] = useState(false)
	const [alert, setAlert] = useState(false)

	useEffect(() => {
        getOrders()
    }, [])

	async function getOrders() {
		let orders = [];
        try {   
        	for (var orderId of payment.orderIds) {
        		let order = await api.getOrder(payment.buyerId, orderId)
        		console.log('order: ', order)
        		orders.push(order)
        	}
        } catch(e) {
            onError(e)
        }
        console.log('orders: ', orders)
        setOrders(orders)
        setLoading(false)
    }

    function showStripe() {
        console.log('stripe')
        setShowStripeModal(true)
    }

    async function sendInvoice() {
        try {
            const result = await api.sendUnpaidInvoice(details.email, payment.paymentId, company.id)
            console.log('result: ', result)

            //setShowCartModal(false)
            //setCart([])
            openSuccessAlert('Your invoice has been sent to you via email. Please pay this promptly via EFT.')
        } catch(e) {
            onError(e)
        }
    }

    function showEft() {
        setAlert(
            <ReactBSAlert
                info
                style={{ display: 'block', marginTop: '-100px' }}
                title='Important'
                onConfirm={() => sendInvoice()}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Send Invoice'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                After you click 'Send Invoice' you will be sent a VAT invoice via email. This can be paid manually via Electronic Fund Transfer. 
                <br />
                <br />
                Once payment has been received the payment status of your order will be updated and you will be notified via email.
            </ReactBSAlert>
        )
    }

    function openSuccessAlert(text) {
        setAlert(
            <ReactBSAlert
                success
                style={{ display: 'block', marginTop: '-100px' }}
                title='Success'
                onConfirm={() => setAlert(false)}
                onCancel={() => setAlert(false)}
                confirmBtnBsStyle='success'
                confirmBtnText='Ok'
                btnSize=''
                confirmBtnStyle={{
                    marginRight: undefined,
                    borderColor: undefined,
                    boxShadow: undefined,
                }}
            >
                {text}
            </ReactBSAlert>
        )
    }

	return (
		<Dialog
			open={props.isOpen}
			TransitionComponent={Transition}
			keepMounted
			onClose={() => props.close()}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			{alert}
			<div className={classes.dialogHeader}>
				<Typography
					variant='h5'
					component='h5'
					className={classes.dialogTitle}
				>
					Complete Payment
				</Typography>
				<IconButton onClick={() => props.close()}>
					<Clear />
				</IconButton>
			</div>


			<DialogContent>
				{!loading ? (
					<Grid 
	                    container
	                    spacing={1}
	                    direction='column'
	                >
	                	<Grid item>
	                		<Typography
                                component='h2'
                                variant='h2'
                                className={classes.typographyH2}
                                style={{textAlign: 'center', marginBottom: '2rem'}}
                            >
                                Complete payment for {orders.length} orders
                            </Typography>
	                	</Grid>
	                	{orders.map((order, i) => {
	                		return (
			                	<Grid item key={i}>
			                		<Grid 
					                    container
					                    spacing={1}
					                    direction='row'
					                    alignItems='center'
					                    justify='space-between'
					                >
					                	<Grid item xs={8}>
				                			<Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='600'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                    >
						                    	{order.variant ? (
						                    		<>{order.product.name} ({order.variant.name})</>
						                    	) : (
						                    		<>{order.product.name}</>
						                    	)}
						                    </Box>
						                    <Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='300'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                    >
						                        {moment(order.startDate).format('DD/MM/YY')} - {moment(order.endDate).format('DD/MM/YY')}
						                    </Box>
			                			</Grid>

				                		<Grid item xs={4}>
				                			<Box
						                        component='p'
						                        marginBottom='1rem'
						                        fontWeight='600'
						                        lineHeight='1.2'
						                        fontSize='1rem'
						                        marginTop='0'
						                        textAlign='right'
						                    >
						                        <>€{order.total.toFixed(2)}</>
						                    </Box>
				                		</Grid>
				                	</Grid>
			                	</Grid>
			                )
	                	})}

	                	{/*
	                	<Grid item>
	                		<Grid 
			                    container
			                    spacing={1}
			                    direction='row'
			                    alignItems='center'
			                    justify='space-between'
			                >
			                	<Grid item xs={8}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Subtotal
				                    </Box>
	                			</Grid>

		                		<Grid item xs={4}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                        textAlign='right'
				                    >
				                        €{subtotal.toFixed(2)}
				                    </Box>
		                		</Grid>
		                	</Grid>
	                	</Grid>

	                	<Grid item>
	                		<Grid 
			                    container
			                    spacing={1}
			                    direction='row'
			                    alignItems='center'
			                    justify='space-between'
			                >
			                	<Grid item xs={8}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        VAT (23%)
				                    </Box>
	                			</Grid>

		                		<Grid item xs={4}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                        textAlign='right'
				                    >
				                        €{vat.toFixed(2)}
				                    </Box>
		                		</Grid>
		                	</Grid>
	                	</Grid>
	                	*/}
	                	<Grid item>
	                		<Grid 
			                    container
			                    spacing={1}
			                    direction='row'
			                    alignItems='center'
			                    justify='space-between'
			                >
			                	<Grid item xs={8}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                    >
				                        Total
				                    </Box>
	                			</Grid>

		                		<Grid item xs={4}>
		                			<Box
				                        component='p'
				                        marginBottom='1rem'
				                        fontWeight='600'
				                        lineHeight='1.2'
				                        fontSize='1rem'
				                        marginTop='0'
				                        textAlign='right'
				                    >
				                        €{payment.total.toFixed(2)}
				                    </Box>
		                		</Grid>
		                	</Grid>
	                	</Grid>
	                </Grid>
                ) : (
                	<Box display='flex' justifyContent='center' alignItems='center' style={{height: '25vh'}}>
                        <Loader />
                    </Box>
                )}

        		<Grid 
                    container
                    spacing={1}
                    direction='column'
                >
					{payment.total < 5000 &&
						<Button
							component={Box}
							onClick={() => showStripe()}
							color='primary'
							variant='contained'
							//marginLeft='auto!important'
							style={{marginBottom: '0.5em'}}
						>
							Pay with card (Stripe)
						</Button>
					}
					{/*
					<Button
						component={Box}
						onClick={() => showFire()}
						color='primary'
						variant='contained'
						//marginLeft='auto!important'
						style={{marginBottom: '0.5em'}}
					>
						Pay with open payments (Fire)
					</Button>
					*/}
					<Button
						component={Box}
						onClick={() => showEft()}
						color='primary'
						variant='contained'
						//marginLeft='auto!important'
						style={{marginBottom: '0.5em'}}
					>
						Pay by EFT (Offline)
					</Button>
                </Grid>
			</DialogContent>
		
			<DialogActions>
				<Button
					onClick={() => props.close()}
					color='primary'
				>
					Cancel
				</Button>
			</DialogActions>

			{showStripeModal &&
                <StripeModal 
                    isOpen={showStripeModal}
                    close={() => setShowStripeModal(false)}
                    details={details}
                    payment={payment}
                />
            }
		</Dialog>
	)
}